export namespace ModulesActions {
    export class SetModules {
        static readonly type = '[MODULES] Set Modules';
        constructor(public modules: any[]) {}
    }

    export class SetModule {
        static readonly type = '[MODULES] Set Module';
        constructor(public module: any[], public storeName: string) {}
    }
}
