import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModalComponent } from './carousel-modal.component';
import { CarouselSliderModule } from '../carousel-slider/carousel-slider.module';

@NgModule({
    declarations: [CarouselModalComponent],
    imports: [CommonModule, CarouselSliderModule],
    exports: [CarouselModalComponent],
})
export class CarouselModalModule {}
