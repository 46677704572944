import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'serviceos-ng-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
    private fileServer = 'files.';

    @Input()
    set logoUrl(value) {
        this._logoUrl=value

        this.isLogoFromFileServer = this._logoUrl
            ? this._logoUrl.indexOf(this.fileServer) === -1
                ? false
                : true
            : false;
        this.isLogoInSvgFormat = this._logoUrl
            ? this._logoUrl.indexOf('.svg') === -1
                ? false
                : true
            : false;

        // if (this.isLogoFromFileServer && !this.isLogoInSvgFormat)
        //     this.srcSetValue = `${this._logoUrl}/135 1x, ${this._logoUrl}/258 2x`;
    }

    @Input()
    brandName = '';

    public _logoUrl = '';
    public srcSetValue = '';
    public isLogoFromFileServer: boolean = false;
    public isLogoInSvgFormat: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
