<div class="notes-holder">
    <ng-container>
        <p class="heading-text" *ngIf="readonly">
            <ng-container *ngIf="moduleBookingsTexts$ | async as moduleBookingsTexts">
                {{ notesData?.length ? moduleBookingsTexts.notes_component?.readonly_heading : moduleBookingsTexts.notes_component?.readonly_heading_no_data}}
            </ng-container>
        </p>
        <p class="heading-text" *ngIf="!readonly">
            <ng-container *ngIf="moduleBookingsTexts$ | async as moduleBookingsTexts">
                {{ moduleBookingsTexts.notes_component?.heading }}
            </ng-container>
        </p>
        <div class="form-holder" *ngIf="!readonly">
            <ng-container *ngIf="moduleBookingsTexts$ | async as moduleBookingsTexts">
                <serviceos-ng-form-field>
                    <input serviceosNgInput autocomplete="off" id="note" type="text" placeholder="{{ moduleBookingsTexts.notes_component?.note_field_placeholder }}" [formControl]="note"
                    (keypress)="onKeyPress($event)">
                </serviceos-ng-form-field>
            </ng-container>

            <div class="submit-text" (click)="addNote(note.value)">
                <serviceos-ng-system-icon class="sos-icon" [icon]="'plus'"></serviceos-ng-system-icon>
                <span *ngIf="moduleBookingsTexts$ | async as moduleBookingsTexts">
                    {{ moduleBookingsTexts.notes_component?.submit_button_title }}
                </span>
            </div>
        </div>
    </ng-container>
    <ul class="notes-list">
        <li *ngFor="let note of notesData; let i = index">
            <span class="note-info" *ngIf="i === 0 || note.createdAtFormatted != notesData[i-1].createdAtFormatted">
                <span class="date">{{ note.createdAtFormatted }}</span>
            </span>
            <span class="note-row">
                <span class="note-item">
                    <span>{{ note.text }} </span>
                </span>
            </span>
        </li>
    </ul>
    <serviceos-ng-local-loader [showLoader]="showLocalLoader"></serviceos-ng-local-loader>
</div>