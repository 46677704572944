import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShouldDisplayDeepLinkPipe } from './should-display-deep-link.pipe';



@NgModule({
  declarations: [
    ShouldDisplayDeepLinkPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ShouldDisplayDeepLinkPipe
  ]
})
export class ShouldDisplayDeepLinkModule { }
