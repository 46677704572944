import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices/invoices.component';
import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';



@NgModule({
  declarations: [
    InvoicesComponent
  ],
  imports: [
    CommonModule,
    SystemIconModule
  ],
  exports: [
    InvoicesComponent
  ]
})
export class InvoicesModule { }
