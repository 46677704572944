export namespace DeepLinksActions {
  export class EmitDeepLink$ {
    static readonly type = `[DEEP_LINKS] Emit Deep Link`;
    constructor(public deepLink: any) {}
  }

  export class EmitDeepLinkFromSearch$ {
    static readonly type = `[DEEP_LINKS] Emit Deep Link Search`;
    constructor(public search: string) {}
  }

  /**
   * This Action need to be use when deeplink have endpoint were need to send the data.
   */
  export class SubmitDeepLinkResult$ {
    static readonly type = `[DEEP_LINKS] Send data to Endpoint`;
    constructor(public deepLink, public data: any) {}
  }

  export class Reset {
    static readonly type = `[DEEP_LINKS] Reset`;
    constructor() {}
  }
}
