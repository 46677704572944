
class CreateElement {
    constructor() {}

    /**
     * Generate Element
     * @param {HTMLElement} appendTo
     * @param {string} elementType
     * @param {CreateElementOptions} options
     */
    public generate(appendTo: HTMLElement, elementType: string, options: CreateElementOptions = {}) {
        const createdEl: HTMLElement = document.createElement(elementType);

        createdEl.classList.add(options.class ? options.class : '');
        createdEl.id = createdEl.id + (options.id ? options.id : '');

        if (options.insertBefore) {
            appendTo.insertBefore(createdEl, options.insertBefore);
        } else {
            appendTo.appendChild(createdEl);
        }

        return createdEl;
    }
}

interface CreateElementOptions {
    class?: string;
    id?: string;
    insertBefore?: HTMLElement;
}

export const createElement = new CreateElement();
