import { ThemeState, ThemeStateModel } from './theme.state';
import { Selector } from '@ngxs/store';

export class ThemeSelectors {

    @Selector([ThemeState])
    static getTheme(state: ThemeStateModel) {
        return state.theme;
    }

}
