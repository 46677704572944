const Common = {
    /**
     * Server error (Possible missconfiguration)
     */
    Server_error_configuration: {
        subject: 'Server_error_configuration',
        code: 5105,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid namespace
     */
    Invalid_namespace: {
        subject: 'Invalid_namespace',
        code: 5110,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid JSON format
     */
    Invalid_JSON: {
        subject: 'Invalid_JSON',
        code: 5115,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Missing requests wrapper or invalid request
     */
    Missing_requests_wrapper_or_invalid_request: {
        subject: 'Missing_requests_wrapper_or_invalid_request',
        code: 5116,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid request method
     */
    Invalid_request_method: {
        subject: 'Invalid_request_method',
        code: 5117,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid request path
     */
    Invalid_request_path: {
        subject: 'Invalid_request_path',
        code: 5118,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid application
     */
    Invalid_application: {
        subject: 'Invalid_application',
        code: 5140,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid profile
     */
    Invalid_profile: {
        subject: 'Invalid_profile',
        code: 5141,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Unauthorized
     */
    Unauthorized: {
        subject: 'Unauthorized',
        code: 5142,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid access token
     */
    Invalid_access_token: {
        subject: 'Invalid_access_token',
        code: 5144,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Server error (could be anything)
     */
    Server_error_general: {
        subject: 'Server_error_general',
        code: 5500,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const Login = {
    /**
     * Invalid login request: social data or username and password required
     */
    Invalid_login_request: {
        subject: 'Invalid_login_request',
        code: 5507,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Password required
     */
    Password_required: {
        subject: 'Password_required',
        code: 5508,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Invalid social_provider_id
     */
    Invalid_social_provider_id: {
        subject: 'Invalid_social_provider_id',
        code: 5509,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid client credentials
     */
    Invalid_client_credentials: {
        subject: 'Invalid_client_credentials',
        code: 5510,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Social account not verified
     */
    Social_account_not_verified: {
        subject: 'Social_account_not_verified',
        code: 5511,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Account doesn't exist
     */
    Account_dont_exist: {
        subject: 'Account_dont_exist',
        code: 5525,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const Register = {
    /**
     * Registration type_id is required
     */
    Required_type_id: {
        subject: 'Required_type_id',
        code: 5501,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid email
     */
    Invalid_email: {
        subject: 'Invalid_email',
        code: 5502,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid brand_id
     */
    Invalid_brand_id: {
        subject: 'Invalid_brand_id',
        code: 5503,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid phone
     */
    Invalid_phone: {
        subject: 'Invalid_phone',
        code: 5504,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Email already taken
     */
    Email_already_taken: {
        subject: 'Email_already_taken',
        code: 5505,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid social data
     */
    Invalid_social_data: {
        subject: 'Invalid_social_data',
        code: 5506,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid social_provider_id
     */
    Invalid_social_provider_id: {
        subject: 'Invalid_social_provider_id',
        code: 5509,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Social account not verified
     */
    Social_account_not_verified: {
        subject: 'Social_account_not_verified',
        code: 5511,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Social account already registered
     */
    Social_already_registered: {
        subject: 'Social_already_registered',
        code: 5512,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid referral code
     */
    Invalid_referral: {
        subject: 'Invalid_referral',
        code: 5513,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * First name is required
     */
    First_name_is_required: {
        subject: 'First_name_is_required',
        code: 5514,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Last name is required
     */
    Last_name_is_required: {
        subject: 'Last_name_is_required',
        code: 5515,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * At least 5-character password is required
     */
    Password_length: {
        subject: 'Password_length',
        code: 5516,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid registration token
     */
    Invalid_registration_token: {
        subject: 'Invalid_registration_token',
        code: 5610,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const ResetPassword = {
    /**
     * Invalid email
     */
    Invalid_email: {
        subject: 'Invalid_email',
        code: 5502,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Email not found
     */
    Email_not_found: {
        subject: 'Email_not_found',
        code: 5530,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid reset password token
     */
    Invalid_password_token: {
        subject: 'Invalid_password_token',
        code: 5531,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * At least 5-character password is required
     */
    Password_length: {
        subject: 'Password_length',
        code: 5516,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Password and password confirmation do not match - # optional if password_confirm passsed
     */
    Passwords_dont_match: {
        subject: 'Passwords_dont_match',
        code: 5532,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Invalid old password
     */
     Invalid_old_password: {
        subject: 'Invalid old password',
        code: 5533,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Invalid password
     */
     Invalid_password: {
        subject: 'Inavlid password',
        code: 5518,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Invalid password
     */
     Phone_number_already_exist: {
        subject: 'Phone number already exist',
        code: 5519,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
};

const BookingProcess = {
    /**
     * You can delete your credit/debit card once all of your services made with it are paid. Please try again later.
     */
     Delete_credit_card_before_paid: {
        subject: 'Credit card delete',
        code: 5611,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * You can delete your credit/debit card 24 hours after the last booking made with it. Please try again later.
     */
     Delete_credit_card_early: {
        subject: 'Credit card delete',
        code: 5612,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Time slot is unavailable
     */
    Time_slot_is_unavailable: {
        subject: 'Time_slot_is_unavailable',
        code: 6500,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Time slots for this service tend to get booked very quickly and this one is no longer available. Don\’t worry, just pick another one and try again.
     */
    Time_slot_is_taken: {
        subject: 'Time_slot_is_taken',
        code: 6501,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Timeslot or timeslot_formatted are required
     */
    Required_timeslot_formatted: {
        subject: 'Required_timeslot_formatted',
        code: 6502,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Unit is not available anymore, pick another one.
     */
    Unit_not_available: {
        subject: 'Unit_not_available',
        code: 6503,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Unit is not available anymore
     */
    Unit_is_not_available_anymore: {
        subject: 'Unit_is_not_available_anymore',
        code: 6550,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Slot is no longer available
     */
    Slot_is_no_longer_available: {
        subject: 'Slot_is_no_longer_available',
        code: 6745,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Time slot is for members only
     */
    Time_slot_is_for_members_only: {
        subject: 'Time_slot_is_for_members_only',
        code: 7025,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid transaction token
     */
    Invalid_transaction_token: {
        subject: 'Invalid_transaction_token',
        code: 6710,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid service
     */
    Invalid_service: {
        subject: 'Invalid_service',
        code: 6711,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid address
     */
    Invalid_address: {
        subject: 'Invalid_address',
        code: 6712,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid postcode
     */
    Invalid_postcode: {
        subject: 'Invalid_postcode',
        code: 6713,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Service %s in %s is not covered
     */
    Service_is_not_covered: {
        subject: 'Service_is_not_covered',
        code: 6714,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	address_line_one is required
     */
    Required_address_line_one: {
        subject: 'Required_address_line_one',
        code: 6715,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	addresses are required
     */
    Required_addresses: {
        subject: 'Required_addresses',
        code: 6716,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid payment_method
     */
    Invalid_payment_method: {
        subject: 'Invalid_payment_method',
        code: 6717,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid paymethod
     */
    Invalid_paymethod: {
        subject: 'Invalid_paymethod',
        code: 6718,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Service mismatch
     */
    Service_mismatch: {
        subject: 'Service_mismatch',
        code: 6719,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Client mismatch
     */
    Client_mismatch: {
        subject: 'Client_mismatch',
        code: 6720,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Address mismatch
     */
    Address_mismatch: {
        subject: 'Address_mismatch',
        code: 6721,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	choice_items are required
     */
    Required_choice_items: {
        subject: 'Required_choice_items',
        code: 6722,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Choice \'%s\' items are required
     */
    Choice_items_are_required: {
        subject: 'Choice_items_are_required',
        code: 6723,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Choice item object format is: id and value
     */
    Choice_item_object_format: {
        subject: 'Choice_item_object_format',
        code: 6724,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Selected choice item does not belong to the service
     */
    Choice_item_does_belong_to_service: {
        subject: 'Choice_item_does_belong_to_service',
        code: 6725,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Choice item \'%s\' is required
     */
    Choice_item_is_required: {
        subject: 'Choice_item_is_required',
        code: 6726,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * No phone to call
     */
     No_phone_to_call: {
        subject: 'There is not phone to call',
        code: 10002,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Call time is not in the available time
     */
     Call_time_is_not_available: {
        subject: 'Call time is not in the available time',
        code: 10007,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    /**
     * Invalid phone
     */
    Invalid_phone: {
        subject: 'Invalid_phone',
        code: 6727,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid email
     */
    Invalid_email: {
        subject: 'Invalid_email',
        code: 6728,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid file token
     */
    Invalid_file_token: {
        subject: 'Invalid_file_token',
        code: 6729,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid reschedule reason
     */
    Invalid_reschedule_reason: {
        subject: 'Invalid_reschedule_reason',
        code: 6730,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 6731	Invalid online status - no transaction state
     */
    Invalid_online_status: {
        subject: 'Invalid_online_status',
        code: 6731,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Authorization is required to confirm the booking transaction
     */
    Authorization_required_to_confirm_booking_transaction: {
        subject: 'Authorization_required_to_confirm_booking_transaction',
        code: 6732,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * 	Invalid cross token
     */
    Invalid_cross_token: {
        subject: 'Invalid_cross_token',
        code: 6733,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * You cannot access this booking
     */
    Access_denied: {
        subject: 'Access_denied',
        code: 6734,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Postcode is required
     */
    Postcode_required: {
        subject: 'Postcode_required',
        code: 6735,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Postcode \'%s\' not covered
     */
    Postcode_not_covered: {
        subject: 'Postcode_not_covered',
        code: 6736,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Booking is already performed
     */
    Booking_already_performed: {
        subject: 'Booking_already_performed',
        code: 6737,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid cancel reason
     */
    Invalid_cancel_reason: {
        subject: 'Invalid_cancel_reason',
        code: 6738,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Invalid booking status
     */
    Invalid_booking_status: {
        subject: 'Invalid_booking_status',
        code: 6739,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Booking cannot be canceled due to short notice
     */
    Booking_cannot_canceled: {
        subject: 'Booking_cannot_canceled',
        code: 6740,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Booking cannot be rescheduled due to short notice
     */
    Booking_cannot_rescheduled: {
        subject: 'Booking_cannot_rescheduled',
        code: 6741,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Init choice items are required
     */
    Init_choice_items_required: {
        subject: 'Init_choice_items_required',
        code: 6742,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Something went wrong
     */
    Something_went_wrong: {
        subject: 'Something_went_wrong',
        code: 6800,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Something went wrong
     */
     Unit_status_not_available: {
        subject: 'Where is my pro is unavailable',
        code: 10013,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    }
};

const Payment = {
    /**
     * 3D Security required
     */
    Required_3d_security: {
        subject: 'Required_3d_security',
        code: 4570,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Amex not supported
     */
    Amex_not_supported: {
        subject: 'Amex_not_supported',
        code: 7020,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const Voucher = {
    /**
     * 	This voucher is already registered to another user
     */
    Voucher_already_registered: {
        subject: 'Voucher_already_registered',
        code: 6018,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * This voucher is not available to be registered to a user
     */
    Voucher_not_available: {
        subject: 'Voucher_not_available',
        code: 6019,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Missing voucher code
     */
    Missing_voucher_code: {
        subject: 'Missing_voucher_code',
        code: 6026,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const Tracked = {
    /**
     * Latitude is required
     */
    Latitude_required: {
        subject: 'Latitude_required',
        code: 5721,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Longitude is required
     */
    Longitude_required: {
        subject: 'Longitude_required',
        code: 5722,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    /**
     * Event time is required
     */
    Event_time_required: {
        subject: 'Event_time_required',
        code: 5723,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const Bookings = {
    Invalid_regular: {
        subject: 'Invalid_regular',
        code: 9040,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

/**
 * =================================================================================
 * CLIENT SIDE ERRORS.
 * =================================================================================
 */

// FREE FOR 100000 - 100099
const Javascript = {
    /**
     * Fail to load lazy load chunk
     */
    Lazy_load_chunk: {
        subject: 'JS Error: Chunk Fail',
        code: 100001,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },

    /**
     * Chat error
     */
    Chat: {
        subject: 'JS Error: chat was blocked',
        code: 100003,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },

    /**
     * Unknown error
     */
    Unknown: {
        subject: 'JS Error: Unknown',
        code: 100002,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const HTTP = {
    continue: {
        code: 100100,
        subject: 'HTTP | Continue',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    switching_protocols: {
        code: 100101,
        subject: 'HTTP | Switching Protocols',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    processing: {
        code: 100102,
        subject: 'HTTP | Processing (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    early_hints: {
        code: 100103,
        subject: 'HTTP | Early Hints',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    ok: {
        code: 100200,
        subject: 'HTTP | OK',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    created: {
        code: 100201,
        subject: 'HTTP | Created',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    accepted: {
        code: 100202,
        subject: 'HTTP | Accepted',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    non_authoritative_information: {
        code: 100203,
        subject: 'HTTP | Non-Authoritative Information',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    no_content: {
        code: 100204,
        subject: 'HTTP | No Content',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    reset_content: {
        code: 100205,
        subject: 'HTTP | Reset Content',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    partial_content: {
        code: 100206,
        subject: 'HTTP | Partial Content',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    multi_status_webdav: {
        code: 100207,
        subject: 'HTTP | Multi-Status (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    already_reported_webdav: {
        code: 100208,
        subject: 'HTTP | Already Reported (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    im_used_http_delta_encoding: {
        code: 100226,
        subject: 'HTTP | IM Used (HTTP Delta encoding)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    multiple_choices: {
        code: 100300,
        subject: 'HTTP | Multiple Choices',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    moved_permanently: {
        code: 100301,
        subject: 'HTTP | Moved Permanently',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    found: {
        code: 100302,
        subject: 'HTTP | Found',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    see_other: {
        code: 100303,
        subject: 'HTTP | See Other',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    not_modified: {
        code: 100304,
        subject: 'HTTP | Not Modified',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    use_proxy_deprecated: {
        code: 100305,
        subject: 'HTTP | Use Proxy Deprecated',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    unused: {
        code: 100306,
        subject: 'HTTP | unused',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    temporary_redirect: {
        code: 100307,
        subject: 'HTTP | Temporary Redirect',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    permanent_redirect: {
        code: 100308,
        subject: 'HTTP | Permanent Redirect',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    bad_request: {
        code: 100400,
        subject: 'HTTP | Bad Request',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    unauthorized: {
        code: 100401,
        subject: 'HTTP | Unauthorized',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    payment_required_experimental: {
        code: 100402,
        subject: 'HTTP | Payment Required Experimental',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    forbidden: {
        code: 100403,
        subject: 'HTTP | Forbidden',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    not_found: {
        code: 100404,
        subject: 'HTTP | Not Found',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    method_not_allowed: {
        code: 100405,
        subject: 'HTTP | Method Not Allowed',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    not_acceptable: {
        code: 100406,
        subject: 'HTTP | Not Acceptable',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    proxy_authentication_required: {
        code: 100407,
        subject: 'HTTP | Proxy Authentication Required',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    request_timeout: {
        code: 100408,
        subject: 'HTTP | Request Timeout',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    conflict: {
        code: 100409,
        subject: 'HTTP | Conflict',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    gone: {
        code: 100410,
        subject: 'HTTP | Gone',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    length_required: {
        code: 100411,
        subject: 'HTTP | Length Required',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    precondition_failed: {
        code: 100412,
        subject: 'HTTP | Precondition Failed',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    payload_too_large: {
        code: 100413,
        subject: 'HTTP | Payload Too Large',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    uri_too_long: {
        code: 100414,
        subject: 'HTTP | URI Too Long',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    unsupported_media_type: {
        code: 100415,
        subject: 'HTTP | Unsupported Media Type',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    range_not_satisfiable: {
        code: 100416,
        subject: 'HTTP | Range Not Satisfiable',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    expectation_failed: {
        code: 100417,
        subject: 'HTTP | Expectation Failed',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    i_am_a_teapot: {
        code: 100418,
        subject: 'HTTP | I am a teapot',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    misdirected_request: {
        code: 100421,
        subject: 'HTTP | Misdirected Request',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    unprocessable_entity_webdav: {
        code: 100422,
        subject: 'HTTP | Unprocessable Entity (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    locked_webdav: {
        code: 100423,
        subject: 'HTTP | Locked (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    failed_dependency_webdav: {
        code: 100424,
        subject: 'HTTP | Failed Dependency (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    too_early_experimental: {
        code: 100425,
        subject: 'HTTP | Too Early Experimental',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    upgrade_required: {
        code: 100426,
        subject: 'HTTP | Upgrade Required',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    precondition_required: {
        code: 100428,
        subject: 'HTTP | Precondition Required',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    too_many_requests: {
        code: 100429,
        subject: 'HTTP | Too Many Requests',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    request_header_fields_too_large: {
        code: 100431,
        subject: 'HTTP | Request Header Fields Too Large',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    unavailable_for_legal_reasons: {
        code: 100451,
        subject: 'HTTP | Unavailable For Legal Reasons',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    internal_server_error: {
        code: 100500,
        subject: 'HTTP | Internal Server Error',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    not_implemented: {
        code: 100501,
        subject: 'HTTP | Not Implemented',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    bad_gateway: {
        code: 100502,
        subject: 'HTTP | Bad Gateway',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    service_unavailable: {
        code: 100503,
        subject: 'HTTP | Service Unavailable',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    gateway_timeout: {
        code: 100504,
        subject: 'HTTP | Gateway Timeout',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    http_version_not_supported: {
        code: 100505,
        subject: 'HTTP | HTTP Version Not Supported',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    variant_also_negotiates: {
        code: 100506,
        subject: 'HTTP | Variant Also Negotiates',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    insufficient_storage_webdav: {
        code: 100507,
        subject: 'HTTP | Insufficient Storage (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    loop_detected_webdav: {
        code: 100508,
        subject: 'HTTP | Loop Detected (WebDAV)',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    not_extended: {
        code: 100510,
        subject: 'HTTP | Not Extended',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    network_authentication_required: {
        code: 100511,
        subject: 'HTTP | Network Authentication Required',
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    }
};

const Custom = {
    Default: {
        subject: 'Custom | Default',
        code: 100601,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    Booking_Missing: {
        subject: 'Custom | Booking Missing',
        code: 100602,
        reportTo: {
            systemapps: false,
            dev_team: false,
        },
    },
    social_authorization_fail: {
        subject: 'Custom | Fail to authorize with social provider popup is closed or c\'not open.',
        code: 100603, 
        reportTo: {
            systemapps: false,
            dev_team: true,
        }
    }
};

const Localization = {
    Missing_translation: {
        subject: 'Localization | Missing text for key.',
        code: 100669,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    Missing_language: {
        subject: 'Localization | Missing language.',
        code: 100668,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    missing_required_module_text: {
        subject: 'Localization | Missing required module texts.',
        code: 100670,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
    missing_default_translations: {
        subject: 'Localization | Missing default translations.',
        code: 100667,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};

const UNKNOWN_ERROR = {
    Unknown: {
        subject: 'Unknown',
        code: 100777,
        reportTo: {
            systemapps: false,
            dev_team: true,
        },
    },
};



export interface ErrorCodeType {
    subject: string;
    code: number;
    reportTo: {
        systemapps: boolean;
        dev_team: boolean;
    };
}

export const ERROR_CODES = {
    Common,
    Login,
    Register,
    ResetPassword,
    BookingProcess,
    Payment,
    Voucher,
    Tracked,
    Javascript,
    Localization,
    Custom,
    HTTP,
    UNKNOWN_ERROR,
    Bookings,
};
