import { ModalsService } from './modals.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { PageComponent } from './page/page.component';
import { SystemIconModule } from '../shared/base/system-icon/system-icon.module';

@NgModule({
  declarations: [DialogComponent, SidebarComponent, PageComponent, ],
  imports: [CommonModule, OverlayModule, SystemIconModule],
  providers: [Overlay, ModalsService],
  exports: [OverlayModule, DialogComponent, SidebarComponent, PageComponent ],
})
export class ModalsModule {}
