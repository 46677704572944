import { LocalStorageService } from './../../common/services/local-storage/local-storage.service';
import { XrmResourcesService } from './../../common/services/xrm-resources/xrm-resources.service';
import { SosApiService } from '../../common/services/api/api.service';
import {
    State,
    StateContext,
    Selector,
    Action,
    NgxsAfterBootstrap,
    NgxsOnInit,
    Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SosAuthorizationService } from './authorization.service';
import { AuthorizationActions } from './authorization.actions';
import { tap } from 'rxjs/operators';
import { FeaturesActions } from '../../common/stores/core-store/features/features.actions';
import { Environment } from '../../common/environment/environment';
import { AuthorizationData, SocialProvider } from './authentication-types';
import { Observable, throwError } from 'rxjs';

export interface AuthorizationModel {
    sid: string | null;
    session: { [key: string]: any };
    socialProviders: SocialProvider[] | null;
    mergeAccount: any;
    status?: 'pending' | 'error' | 'success';
}

const defaultAuthorization: AuthorizationModel = {
    sid: null,
    session: {},
    socialProviders: [],
    mergeAccount: null,
    status: 'pending',
};

export const AUTHORIZATION_TOKEN = 'AUTHORIZATION';

@State<AuthorizationModel>({
    name: AUTHORIZATION_TOKEN,
    defaults: defaultAuthorization,
})
@Injectable()
export class AuthorizationState implements NgxsOnInit {
    constructor(
        private sosAuthorizationService: SosAuthorizationService,
        private xrmResourcesService: XrmResourcesService,
        private sosApiService: SosApiService,
        private localStorageService: LocalStorageService,
        private env: Environment,
    ) {}

    ngxsOnInit(ctx: StateContext<AuthorizationState>) {
        ctx.dispatch(new FeaturesActions.Register(AUTHORIZATION_TOKEN));
        // ctx.dispatch(new AuthorizationActions.GetSocialProviders());
    }

    // This is called from feature state inside core !! TODO: Is better for reduce complexity to remove it and switch to simple select.
    @Action(AuthorizationActions.Initialize)
    AuthorizationInitialize({ patchState }: StateContext<AuthorizationModel>) {
        const sid = this.sosAuthorizationService.checkAuthorization();
        const session = this.readSession(sid);
        this.setSid(sid);

        return patchState({
            sid,
            session,
            status: 'success',
        });
    }

    @Action(AuthorizationActions.setAuthorization)
    setAuthorizationToken(
        { patchState }: StateContext<AuthorizationModel>,
        payload: AuthorizationActions.setAuthorization
    ) {
        const session = this.readSession(payload.sid);
        this.setSid(payload.sid);

        return patchState({
            sid: payload.sid,
            session,
            status: 'success',
        });
    }

    

    @Action(AuthorizationActions.mergeAccount)
    mergeAccount(
        { patchState }: StateContext<AuthorizationModel>,
        payload: AuthorizationActions.mergeAccount
        ) {
        return this.sosAuthorizationService.mergeAccount(payload.token).pipe(
            tap(
                (res) => {
                    patchState({
                        mergeAccount: res.data
                    });
                }
            )
        );
    }

    @Action(AuthorizationActions.Login)
    login(
        { patchState }: StateContext<AuthorizationModel>,
        { data, keepSingIn }: AuthorizationActions.Login
    ) {
        return this.sosAuthorizationService.login(data, keepSingIn).pipe(
            tap((res) => {
                this.setSid(res.sid);

                patchState({
                    sid: res.sid,
                    session: {},
                    status: 'success',
                });
            })
        );
    }

    @Action(AuthorizationActions.Register)
    register(
        { patchState }: StateContext<AuthorizationModel>,
        { data }: AuthorizationActions.Register
    ) {
        return this.sosAuthorizationService.register(data).pipe(
            tap((res) => {
                this.setSid(res.sid);

                patchState({
                    sid: res.sid,
                    session: {},
                    status: 'success',
                });
            })
        );
    }

    @Action(AuthorizationActions.LoginWithToken)
    loginWithToken(
        { patchState }: StateContext<AuthorizationModel>,
        { data, keepSingIn }: AuthorizationActions.LoginWithToken
    ) {
        this.sosAuthorizationService.login(data, keepSingIn).pipe(
            tap((res) => {
                this.setSid(res.sid);

                patchState({
                    sid: res.sid,
                    session: {},
                    status: 'success',
                });
            })
        );
    }

    @Action(AuthorizationActions.ClaimAccount)
    claimAccount(
        { patchState }: StateContext<AuthorizationModel>,
        { data }: AuthorizationActions.ClaimAccount
    ) {
        this.sosAuthorizationService.register(data).pipe(
            tap((res) => {
                this.setSid(res.sid);

                patchState({
                    sid: res.sid,
                    session: {},
                    status: 'success',
                });
            })
        );
    }

    @Action(AuthorizationActions.SocialSingIn)
    socialSingIn(
        { patchState, dispatch }: StateContext<AuthorizationModel>,
        { social }: AuthorizationActions.SocialSingIn
    ) {
        let socialLogin: Observable<AuthorizationData> | undefined;
        switch (social.type) {
            case 'Google':
                socialLogin = this.sosAuthorizationService.loginGoogle(social);
                break;
            case 'Facebook':
                socialLogin = this.sosAuthorizationService.loginFacebook(
                    social
                );
                break;
            case 'Apple':
                socialLogin = this.sosAuthorizationService.loginApple(social);
                break;
            case 'Microsoft':
                socialLogin = this.sosAuthorizationService.loginAzure(social);
                break;
            case 'OAuth2':
                socialLogin = this.sosAuthorizationService.loginOAuth2(social);
                break;
        }

        if (socialLogin) {
            return socialLogin.pipe(
                tap((res) => {
                    this.setSid(res.sid);

                    patchState({
                        sid: res.sid,
                        session: {},
                        status: 'success',
                    });
                })
            );
        } else {
            return throwError(`Unknown social type: ${social.type}`);
        }
    }

    @Action(AuthorizationActions.Logout)
    logout({ patchState }: StateContext<AuthorizationModel>) {
        return this.sosAuthorizationService.logout().pipe(
            tap((response) => {
                this.setSid(null);

                this.localStorageService
                    .keysWithCertainWordInside(this.env.project + '_session_')
                    .forEach((key) => {
                        this.localStorageService.removeItem(key);
                    });

                return patchState(defaultAuthorization);
            })
        );
    }

    // -> към регистер
    @Action(AuthorizationActions.RequestClaimAccount)
    requestClaimAccount(
        {}: StateContext<AuthorizationModel>,
        { data }: AuthorizationActions.RequestClaimAccount
    ) {
        return this.sosAuthorizationService.requestClaimAccount(data);
    }

    @Action(AuthorizationActions.RequestResetPassword)
    requestResetPassword(
        {}: StateContext<AuthorizationModel>,
        { data }: AuthorizationActions.RequestResetPassword
    ) {
        return this.sosAuthorizationService.requestResetPassword(data);
    }

    @Action(AuthorizationActions.ResetPassword)
    resetPassword(
        {}: StateContext<AuthorizationModel>,
        { data }: AuthorizationActions.ResetPassword
    ) {
        return this.sosAuthorizationService.resetPassword(data);
    }

    @Action(AuthorizationActions.GetSocialProviders)
    getSocialProviders(
        { dispatch, patchState }: StateContext<AuthorizationModel>,
        payload: AuthorizationActions.GetSocialProviders
    ) {
        return this.sosAuthorizationService.getSocialProviders().pipe(
            tap(
                (response) => {
                    patchState({ socialProviders: response.data });
                },
                (error) => {
                    // error
                }
            )
        );
    }

    @Action(AuthorizationActions.ResetAuthorization)
    reset(ctx: StateContext<AuthorizationModel>) {
        ctx.setState(defaultAuthorization);
    }

    @Action(AuthorizationActions.DeleteSessionProperty)
    deleteSessionProperty(
        { patchState, setState, getState }: StateContext<AuthorizationModel>,
        payload: AuthorizationActions.DeleteSessionProperty
    ) {
        const state = getState();
        delete state.session[payload.property];
        this.localStorageService.setItem(
            this.env.project + '_session_' + state.sid,
            state.session
        );
        return patchState(state);
    }

    @Action(AuthorizationActions.SetSessionProperty)
    setSessionProperty(
        { patchState, setState, getState }: StateContext<AuthorizationModel>,
        payload: AuthorizationActions.SetSessionProperty
    ) {
        const state = getState();
        const session = { ...state.session };

        session[payload.property] = payload.value;

        this.localStorageService.setItem(
            this.env.project + '_session_' + state.sid,
            JSON.stringify(session)
        );
        patchState({ session });
    }

    private readSession(currentSessionKey) {
        this.localStorageService
            .keysWithCertainWordInside(this.env.project + '_session_')
            .forEach((key) => {
                if (this.env.project + '_session_' + currentSessionKey != key) {
                    this.localStorageService.removeItem(key);
                }
            });

        return (
            this.localStorageService.getItem(
                this.env.project + '_session_' + currentSessionKey
            ) || {}
        );
    }

    private setSid(sid) {
        if (sid) {
            this.sosApiService.authToken = sid;
            this.xrmResourcesService.authToken = sid;
        } else {
            this.sosApiService.authToken = null;
            this.xrmResourcesService.authToken = null;
        }
    }
}
