import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'serviceos-ng-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
  @ViewChild('TabContent')
  tabContent 

  @Input()
  unitInfo: any = null;

  @Output()
  onToggleFavorite = new EventEmitter();

  @Input()
  texts = {
    team_type: 'Team',
    back_team_cta: 'Back to team',

    profile_label_experience: 'Experience',
    profile_label_total_bookings: 'Total Bookings:',
    profile_label_languages: 'Languages',

    tab_title_badges: 'Badges',
    tab_title_rating: 'Rating',
    tab_title_documents: 'Documents',
    tab_title_biography: 'Bio',
    tab_title_professionals: 'PROs',

    documents_view_document_cta: 'View',
    rating_level_1: 'veryPoor',
    rating_level_2: 'poor',
    rating_level_3: 'okay',
    rating_level_4: 'good',
    rating_level_5: 'superb',
    rating_reviews_txt: 'reviews',
    rating_from_txt: 'from',
    professionals_view_pro_cta: 'View',
    documents_view_cta: 'View',
  };

  public active = 1;

  public descriptionTabsCount = 0;

  public currentUnit = null;

  // Initial reviews count
  public reviewsCount: any = {
    veryPoor: 0,
    poor: 0,
    okay: 0,
    good: 0,
    superb: 0,
  };

  constructor() {}

  ngOnInit(): void {
    this.extractReviewsCount();

    this.descriptionTabsCount = [
      this.unitInfo &&
        this.unitInfo.stats &&
        this.unitInfo.stats.rating &&
        this.unitInfo.stats.total_ratings, // Ratings tab condition
      this.unitInfo && this.unitInfo.details && this.unitInfo.details.documents, // Documents tab condition
      this.unitInfo && this.unitInfo.details && this.unitInfo.details.short_bio, // Bio tab condition
      this.unitInfo && this.unitInfo.badges, // Badges tab condition
    ].filter((tab: Boolean) => tab).length;
  }

  /**
   * Get each reviews count
   */
  private extractReviewsCount(): void {
    if (
      this.unitInfo &&
      this.unitInfo.stats &&
      this.unitInfo.stats.ratings &&
      this.unitInfo.stats.ratings.length
    ) {
      const mappedStats: Array<{ rating: number; statName: string }> = [
        {
          rating: 1,
          statName: this.texts.rating_level_1,
        },
        {
          rating: 2,
          statName: this.texts.rating_level_2,
        },
        {
          rating: 3,
          statName: this.texts.rating_level_3,
        },
        {
          rating: 4,
          statName: this.texts.rating_level_4,
        },
        {
          rating: 5,
          statName: this.texts.rating_level_5,
        },
      ];

      for (let statIndex = 0; statIndex < mappedStats.length; statIndex++) {
        const stat: any = mappedStats[statIndex];

        this.reviewsCount[stat.statName] = this.unitInfo.stats.ratings.filter(
          (ratingStats: any) => {
            return ratingStats.rating === stat.rating;
          }
        ).length;
      }
    }
  }

  public viewUnitInfo(unit) {
    this.currentUnit = unit;
  }

  toggleFavorite() {
    this.onToggleFavorite.emit(this.unitInfo);
  }

  public changeTab(){
    this.tabContent.nativeElement.scrollIntoView(true);
  }
}
