import { Inject, Injectable, Optional } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { LocalizationActions } from './localization.actions';

import { LocalizationSelectors } from './localization.selectors';

function isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
}

@Injectable()
export class SOSTranslateParserService extends TranslateDefaultParser {
    templateMatcher: RegExp = /\[\$\s?([^\[\]\s]*)\s?\]/g;

    constructor(@Optional() @Inject(Store) private store?) {
        super();
    }

    interpolateObject(expr: any, params?: any) {
        let result = JSON.stringify(expr);

        result = this.interpolate(result, params);

        return JSON.parse(result);
    }

    interpolate(expr: string | Function, params?: any): string {
        const globalShortcodes = this.store?.selectSnapshot(
            LocalizationSelectors.getShortcodes()
        );

        // console.error(
        //     '🚀 ~ file: sostranslate-parser.service.ts ~ line 31 ~ SOSTranslateParserService ~ interpolate ~ globalShortcodes',
        //     globalShortcodes,
        //     params
        // );

        params = { ...globalShortcodes, ...params };

        let result = super.interpolate(expr, params);

        if (typeof result == 'object') {
            result = this.interpolateObject(result, params);
        }

        return result;
    }

    getValue(target: any, key: string): any {
        let keys = typeof key === 'string' ? key.split('.') : [key];
        key = '';

        do {
            key += keys.shift();
            if (
                typeof target !== 'undefined' &&
                target !== null &&
                typeof target[key] !== 'undefined' &&
                target[key] !== null &&
                (typeof target[key] === 'object' || !keys.length)
            ) {
                target = target[key];
                key = '';
            } else if (!keys.length) {
                target = undefined;
            } else {
                key += '.';
            }
        } while (keys.length);

        if (typeof target === 'string') {
            target = JSON.stringify(target).slice(1, -1);
        }

        return target;
    }
}
