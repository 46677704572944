import { delay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    Component,
    ContentChild,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    slideInLeftOnEnterAnimation,
    slideOutLeftOnLeaveAnimation,
} from 'angular-animations';
import { NavService, NavStateInterface } from '../nav.service';
import { SosNavigationComponent } from '../navigation/navigation.component';
import { HeaderComponent } from '../header/header.component';

// TODO find way to fix the box shadow between side menu and header

@Component({
    selector: 'serviceos-ng-frame',
    templateUrl: './frame.component.html',
    styleUrls: ['./frame.component.scss'],
    providers: [NavService],
    animations: [
        slideInLeftOnEnterAnimation({ duration: 500 }),
        slideOutLeftOnLeaveAnimation({ duration: 500 }),
    ],
})
export class FrameComponent implements OnInit {
    public menuState$!: Observable<NavStateInterface>;
    public disableAnimations = false;

    navigation;
    @ContentChild(SosNavigationComponent) set navigationContent(
        value: SosNavigationComponent
    ) {
        this.navigation = value;
        if (value) {
            this.navService.navigationProvidedStatus(true);
        } else {
            this.navService.navigationProvidedStatus(false);
        }
    }

    constructor(private navService: NavService, private element: ElementRef) {
        this.menuState$ = this.navService.navState.pipe(
            tap((state) => {
                if (!state.mobileView) {
                    this.disableAnimations = true;
                } else {
                    this.disableAnimations = false;
                }
            }),
            delay(0) // secure to disable the animation for the sidebar menu.
        );
    }

    ngOnInit(): void {}

    closeMobileMenu(): void {
        this.navService.toggle();
    }
}
