import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { NgxsPlugin } from '@ngxs/store';

// NOTE https://github.com/ngxs/store/issues/1691
@Injectable()
export class NgxsErrorPlugin implements NgxsPlugin {
    public handle(state: any, action: any, next: any): void {
        return next(state, action).pipe(
            catchError((error) => {

                if (error['errorMetaData']) {
                    error['errorMetaData'].ngxsError = false;
                } else {
                    error['errorMetaData'] = {
                        ngxsError: false,
                        reported: false,
                    };
                }

                throw error;
            })
        );
    }
}
