<ng-container *ngIf="menuState$ | async as menuState">
    <ng-template #menu>
        <ng-content select="serviceos-ng-navigation"> </ng-content>
    </ng-template>

    <div class="frame">
        <div class="header">
            <ng-content select="serviceos-ng-header"></ng-content>
        </div>
        <div class="frame-content">
            <div
                class="navigation"
                *ngIf="!menuState?.mobileView && navigation"
            >
                <ng-container *ngTemplateOutlet="menu"></ng-container>
            </div>
            <div class="page-content">
                <div class="content">
                    <ng-content select="serviceos-ng-page-content">
                    </ng-content>
                </div>

                <div class="footer">
                    <ng-content select="serviceos-ng-footer"> </ng-content>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="menuState?.mobileView && navigation">
        <div
            class="frame-side-navigation"
            [@.disabled]="disableAnimations"
            [@slideInLeftOnEnter]
            [@slideOutLeftOnLeave]
            *ngIf="menuState.showNavigation"
        >
            <div class="mobile-navigation">
                <serviceos-ng-system-icon
                    (click)="this.closeMobileMenu()"
                    [icon]="'close-cancel'"
                ></serviceos-ng-system-icon>
            </div>
            <div class="mobile-navigation-content">
                <ng-container *ngTemplateOutlet="menu"></ng-container>
            </div>
        </div>
        <div class="frame-side-navigation-backdrop" (click)="this.closeMobileMenu()" [ngClass]="{'shown': menuState.showNavigation}">

        </div>
    </ng-container>
</ng-container>
