import { Injectable } from '@angular/core';
import { SosApiService } from '../../../common/services/api/api.service';

@Injectable({
    providedIn: 'root',
})
export class CreditActivityService {
    constructor(private sosApiService: SosApiService) {}

    public getAll(params?) {
        return this.sosApiService.get('client/credit_activity', params).pipe();
    }
}
