import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { tap, retry, catchError, map, switchMap } from 'rxjs/operators';
import { SosCoreConfig } from '../../../core-config';
import { jsonLongNumberFix } from '../../../helpers';
import { ApiRequest } from '../api/api.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

// NOTE This class and all methods is temporary until xrm complete the endpoint for bookings.
@Injectable({
    providedIn: 'root',
})
export class XrmResourcesService {
    public authToken: string | null = null;
    private apiUrl = '';

    constructor(
        private httpClient: HttpClient,
        private config: SosCoreConfig,
        private translateService: TranslateService,
        private _localStorageService: LocalStorageService
    ) {
        this.apiUrl =
            this.config.api_url.split('/api/')[0] +
            '/api/externalapp/XRMResources';
    }

    private get(
        request: ApiRequest,
        resources: { [key: string]: any }
    ): Observable<any> {
        const headers = this.createHeader();

        return this.httpClient
            .post(this.apiUrl, resources, { headers, responseType: 'text' })
            .pipe(
                map((res) => {
                    //! This property modification on the response is required to fix problem with number having more 15 digits
                    //* TASK NUMBER: https://pm.1dxr.com/issues/66355
                    //? let text = '{"normal":2.3,"long":123456789012345678901}';
                    //? JSON.parse will lose some digits and a whole number:
                    //? console.log(JSON.stringify(JSON.parse(text)));
                    //? '{"normal":2.3,"long":123456789012345680000}'      WHOOPS!!!

                    res = jsonLongNumberFix(res, ['objectId']);

                    return JSON.parse(res);
                }),
                tap(
                    (res) => {
                        // console.log(`GET: \n${this.config.api_url + path} \nRESPONSE: \n`, res)
                    },
                    (err) => {
                        // console.log(`GET ERROR: \n${this.config.api_url + path}  \nRESPONSE: \n`, err)
                    }
                ),
                retry(3), // retry a failed request up to 3 times
                catchError((error) => this.handleError(request, error)) // then handle the error
            );
    }

    private handleError(
        request: ApiRequest,
        error: HttpErrorResponse
    ): Observable<any> {
        let response = {
            error: [
                {
                    code: 100777,
                    message:
                        'Oops! Seems like something went wrong on our end! Please accept our apologies and try again.',
                },
            ],
        };

        // There is error.status == 0.Then request is blocked or something else has happened this is why we use then 100777
        if (error.status) {
            response.error[0].code = 100000 + error.status;
        }

        request.setResponse(response);

        // return an observable with a user-facing error message
        return throwError(request);
    }

    private setTypeApiResponse(request: ApiRequest, response) {
        request.setResponse(response);

        if (request.error) {
            throw request;
        }

        return request;
    }

    private createHeader() {
        let headers = new HttpHeaders();

        let  currentLanguage = '';

        if (this._localStorageService.getItem('app_default_language') && this._localStorageService.getItem('app_default_language') !== 'undefined') {
            currentLanguage = this._localStorageService.getItem('app_default_language');
        }

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('X-Application', this.config.key);
        headers = headers.append('X-Client-Profile', this.config.profile_id);

        if (currentLanguage) {
            headers = headers.append('X-Language', currentLanguage);
        }

        if (this.authToken) {
            headers = headers.append(
                'Authorization',
                `Bearer ${this.authToken}`
            );
        }

        return headers;
    }

    public getBookings(isHistory: boolean, limit: number = 999999) {
        const request = new ApiRequest({
            url: this.apiUrl,
            type: 'POST',
        });

        return this.get(request, {
            Resources: [
                {
                    name: 'AllBookings',
                    limit: limit,
                    isHistory: isHistory,
                },
            ],
        }).pipe(
            map((response) => {
                if (response.data) {
                    response.data = response.data[0].AllBookings;
                }
                return response;
            }),
            map((response) => this.setTypeApiResponse(request, response))
        );
    }

    /**
     * Booking getter
     * @param bookingId
     * @param isHistory decides whether booking is past or upcoming
     * @returns
     */
    public getBooking(bookingId: string, isHistory: boolean) {
        const request = new ApiRequest({
            url: this.apiUrl,
            type: 'POST',
        });
        return this.get(request, {
            Resources: [
                {
                    name: 'AllBookings',
                    limit: 999999,
                    isHistory: isHistory,
                    objectId: bookingId,
                },
            ],
        }).pipe(
            map((response) => {
                if (response.data) {
                    response.data = response.data[0].AllBookings;
                }

                if (response.error) {
                    request.setResponse({
                        error: [
                            {
                                code: response.error[0].code,
                                message: response.error[0].message
                            },
                        ],
                    });

                    throw request;
                } else if (!response || !response.data || !response.data.length) {
                    request.setResponse({
                        error: [
                            {
                                code: 100602,
                                message: 'Booking not found',
                            },
                        ],
                    });

                    throw request;
                }

                return response;
            }),
            map((response) => this.setTypeApiResponse(request, response))
        );
    }

    /**
     * Pass the regular plan id or regular id to get list of sessions or session.
     * @param regularId regularPlanId or regularSessionId
     * @param offset
     * @returns
     */
    public getRegularSessions(regularId: string | number, offset = 0) {
        const request = new ApiRequest({
            url: this.apiUrl,
            type: 'POST',
        });
        return this.get(request, {
            Resources: [
                {
                    name: 'RegularSessions',
                    periodOffset: offset,
                    regularId: regularId,
                },
            ],
        }).pipe(
            map((response) => {
                if (response.data) {
                    response.data = response.data[0].RegularSessions;
                }

                if (!response || !response.data || !response.data.length) {
                    request.setResponse({
                        error: [
                            {
                                code: 100602,
                                message: 'Booking not found',
                            },
                        ],
                    });

                    throw request;
                }

                return response;
            }),
            map((response) => this.setTypeApiResponse(request, response))
        );
    }
}
