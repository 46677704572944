import { Injectable } from '@angular/core';
import { UNITS_API_PATHS, UNITS_STATE_TOKEN } from './units.const';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UnitsActions } from './units.actions';
import { SosApiService } from '../../../common';
import { tap } from 'rxjs/operators';

export interface UnitsStateModel {
  id: number;
  favorite: boolean;
}

export interface UnitI {
  id: number;
  name: string;
  image_url: string;
  favorite: boolean;
  stats: {
    rating: number;
    total_ratings: number;
  };
  can_video_call: null;
  units?: {
    id: number;
    name: string;
    image_url: string;
    gender: 'Male' | 'Female';
    favorite: boolean;
  }[];
}

@State<UnitsStateModel | null>({
  name: UNITS_STATE_TOKEN,
  defaults: null,
})
@Injectable()
export class UnitsState {
  constructor(private sosApiService: SosApiService) {}

  @Action(UnitsActions.ToggleFavorite$)
  addToFavorites(
    { dispatch, patchState, getState }: StateContext<UnitsStateModel | null>,
    payload: UnitsActions.ToggleFavorite$
  ) {
    const unit = payload.data;

    let path = UNITS_API_PATHS.units;
    if (unit.units) {
      path = UNITS_API_PATHS.teams;
    }

    const currentDetailUnit = getState();

    return this.sosApiService
      .post(
        `${path}/${payload.data.id}/${
          unit.favorite
            ? UNITS_API_PATHS.removeFavorite
            : UNITS_API_PATHS.addFavorite
        }`,
        null,
        payload.queryParams
      )
      .pipe(
        tap((res) => {
          if (currentDetailUnit && unit.id == currentDetailUnit.id) {
            patchState({ favorite: !unit.favorite });
          }
        })
      );
  }

  @Action(UnitsActions.FetchSingleUnit$)
  fetchSingleUnit(
    { dispatch, patchState }: StateContext<UnitsStateModel | null>,
    payload: UnitsActions.FetchSingleUnit$
  ) {
    const unit = payload.data;

    let path = UNITS_API_PATHS.units;
    if (unit.units) {
      path = UNITS_API_PATHS.teams;
    }
    
    return this.sosApiService
      .get(`${path}/${payload.data.id}`, payload.queryParams)
      .pipe(
        tap((res) => {
          patchState(res.data[0]);
        })
      );
  }

  @Action(UnitsActions.Reset)
  reset({
    dispatch,
    patchState,
    setState,
  }: StateContext<UnitsStateModel | null>) {
    setState(null);
  }
}
