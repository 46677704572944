import { first, tap } from 'rxjs/operators';
import { DeepLinksService } from './../deep-links.service';
import { Injectable } from '@angular/core';
import { DEEP_LINKS_STATE_TOKEN } from './deep-links.const';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeepLinksActions } from './deep-links.actions';
import { ErrorActions } from '../../../common';
import { convertDeepLinkToObject } from '../../../helpers';
import { ModalsService } from '../../../elements';
import { DefaultModalConfig } from '../../../models';

export interface DeepLinksStateModel {
    type: string;
    deep_link_object: { [key: string]: any };
    resources: any | null;
    endpoint: string | null;
    deep_link: string;
    title: string | null;
    active_until?: number;
}

@State<DeepLinksStateModel | null>({
    name: DEEP_LINKS_STATE_TOKEN,
    defaults: null,
})
@Injectable()
export class DeepLinksState {
    constructor(
        private deepLinksService: DeepLinksService,
        private _modalsService: ModalsService
    ) {}

    @Action(DeepLinksActions.EmitDeepLink$)
    emitDeepLink(
        { patchState, dispatch }: StateContext<DeepLinksStateModel | null>,
        payload: DeepLinksActions.EmitDeepLink$
    ) {
        const deepLinkObject =
            convertDeepLinkToObject(payload.deepLink.deep_link) || {};

        const link = { ...payload.deepLink, deep_link_object: deepLinkObject };

        // ! TODO: not all deep links are going to be "reasons", needs rework
        this.deepLinksService
            .getResources(link)
            .toPromise()
            .then(
                (resources: any) => {
                    // // handle when reasons is array with reasons+terms
                    // if (reasons && reasons.length) {
                    //   link.reasons = reasons[0] ? reasons[0] : null;
                    //   link.terms = reasons.length > 1 && reasons[1] ? reasons[1] : null;
                    // } else {
                    //   link.reasons = reasons ? reasons : null;
                    // }

                    link.resources = resources;

                    // Emit the deepLink
                    patchState(link);
                    // Reset state
                    dispatch(new DeepLinksActions.Reset());
                },
                (err) => {
                    this._modalsService
                        .open(
                            new DefaultModalConfig(
                                '',
                                err?.error?.length && err.error[0]?.message
                                    ? err.error[0]?.message
                                    : 'Something went wrong',
                                [
                                    {
                                        title: 'Ok',
                                        type: 'close',
                                    },
                                ],
                                'error'
                            )
                        );
                        // .close$.pipe(first())
                        // .subscribe((res) => {
                        //     window.location.reload();
                        // });
                }
            );
        // .pipe(
        //   tap((resources: any) => {
        //     // // handle when reasons is array with reasons+terms
        //     // if (reasons && reasons.length) {
        //     //   link.reasons = reasons[0] ? reasons[0] : null;
        //     //   link.terms = reasons.length > 1 && reasons[1] ? reasons[1] : null;
        //     // } else {
        //     //   link.reasons = reasons ? reasons : null;
        //     // }

        //     link.resources = resources;

        //     // Emit the deepLink
        //     patchState(link);
        //     // Reset state
        //     dispatch(new DeepLinksActions.Reset());
        //   })
        // );
    }

    @Action(DeepLinksActions.EmitDeepLinkFromSearch$)
    emitDeepLinkFromSearch(
        { patchState, dispatch }: StateContext<DeepLinksStateModel | null>,
        payload: DeepLinksActions.EmitDeepLinkFromSearch$
    ) {
        const deepLinkObject = convertDeepLinkToObject(payload.search) || {};

        // Only if we gave fs_screen
        if (deepLinkObject.fs_screen) {
            const deepLink: DeepLinksStateModel = {
                type: deepLinkObject.fc_screen,
                deep_link_object: deepLinkObject,
                endpoint: null,
                resources: null,
                deep_link: payload.search,
                title: null,
            };

            // Emit the deepLink
            patchState({ ...deepLink });
        }

        dispatch(new DeepLinksActions.Reset());
    }

    @Action(DeepLinksActions.SubmitDeepLinkResult$)
    submitDeepLinkResult(
        { patchState, dispatch }: StateContext<DeepLinksStateModel | null>,
        payload: DeepLinksActions.SubmitDeepLinkResult$
    ) {
        return this.deepLinksService
            .sendDeepLinkData(payload.deepLink, payload.data)
            .pipe(tap());
    }

    @Action(DeepLinksActions.Reset)
    reset({ setState }: StateContext<DeepLinksStateModel | null>) {
        setState(null);
    }
}
