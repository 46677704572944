import { createSelector, Selector } from '@ngxs/store';
import { BookingService } from '../booking.service';
import { BookingState, BookingStateModel } from './booking.state';

export class BookingSelectors {
    @Selector([BookingState])
    static getSingle(state: BookingStateModel) {
        let booking = state?.booking;

        if (booking) {
            // Make booking extendable;
            booking = { ...booking };

            // Remove from booking the paymentType because is not human readable. And if we have v2booking data we add the payment_method_title
            if (
                booking.paymentMethodTitle ||
                state.v2booking?.payment_method_title
            ) {
                booking.payment_method_title =
                    booking.paymentMethodTitle ||
                    state.v2booking.payment_method_title;
            }
        }

        return booking;
    }

    @Selector([BookingState])
    static getRegularSessions(state: BookingStateModel) {
        return state?.bookingSessions;
    }

    @Selector([BookingState])
    static getBookingNotes(state: BookingStateModel) {
        return state?.booking?.comments;
    }

    @Selector([BookingState])
    static proTracking(state: BookingStateModel) {
        return state?.proTracking;
    }

    @Selector([BookingState])
    static serviceDetails(state: BookingStateModel) {
        return state?.booking?.serviceSummary || state?.booking?.service_summary;
    }

    static getRegularSingleSession(sessionId) {
        return createSelector([BookingState], (state: BookingStateModel) => {
            return state?.bookingSessions.find(
                (session) => session?.objectId == sessionId
            );
        });
    }
}
