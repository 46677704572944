import { ModulesSelectors } from './../stores/core-store/modules/modules.selectors';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FeatureGuard implements CanActivate {
    constructor(private store: Store, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const feature = route.data.feature as RouteFeatureConfig;

        if (!route?.data?.feature) {
            throw new Error('Missing specify feature');
        }

        if (this.store.selectSnapshot(ModulesSelectors.featureEnabled(feature.name, feature.moduleName))) {
            return true;
        } else {
            this.router.navigate(['page-not-found']);
            return false;
        }
    }
}

export class RouteFeatureConfig {
    public name;
    public moduleName;
    constructor(feature: string, module: string) {
        this.name = feature;
        this.moduleName = module;
    }
}
