export namespace AccountActions {
    export class Fetch {
        static readonly type = `[ACCOUNT] Fetch`;
    }

    export class updateLanguage {
        static readonly type = `[ACCOUNT] Update Language`;
        constructor(public language: string) {}
    }

    export class updateName {
        static readonly type = `[ACCOUNT] Update Name`;
        constructor(public firstName: string, public lastName: string) {}
    }

    export class updatePassword {
        static readonly type = `[ACCOUNT] Update Password`;
        constructor(
            public oldPassword: any,
            public newPassword: any,
            public confirmNewPassword: any
        ) {}
    }

    export class updateBirthdate {
        static readonly type = `[ACCOUNT] Update Birthdate`;
        constructor(public birthdate: string) {}
    }

    export class updateAvatar {
        static readonly type = `[ACCOUNT] Update Avatar`;
        constructor(public file: any, public token: string) {}
    }

    export class getAvatar {
        static readonly type = `[ACCOUNT] Get Avatar`;
    }

    export class uploadFile {
        static readonly type = `[ACCOUNT] Upload File`;
        constructor(public file: any) {}
    }
    export class CreateAddress {
        static readonly type = `[ACCOUNT] Create Address`;
        constructor(
            public address: {
                address_line_one: string;
                address_line_two: string;
                postcode: string;
            }
        ) {}
    }
    export class FetchAddresses {
        static readonly type = `[ACCOUNT] Fetch Addresses`;
        constructor() {}
    }
    export class DeleteAddress {
        static readonly type = `[ACCOUNT] Delete Address`;
        constructor(public address: { id: number }) {}
    }
    export class MarkAddressAsDefault {
        static readonly type = `[ACCOUNT] Mark Address As Default`;
        constructor(
            public address: {
                id: number;
                address_line_one: string;
                address_line_two: string;
                postcode: string;
                default: boolean;
            }
        ) {}
    }
    export class FetchPhones {
        static readonly type = `[ACCOUNT] Fetch Phones`;
        constructor() {}
    }
    export class CreatePhone {
        static readonly type = `[ACCOUNT] Create Phone`;
        constructor(public phone: { value: string }) {}
    }
    export class DeletePhone {
        static readonly type = `[ACCOUNT] Delete Phone`;
        constructor(public phone: { id: number }) {}
    }
    export class MarkPhoneAsDefault {
        static readonly type = `[ACCOUNT] Mark Phone As Default`;
        constructor(
            public phone: { id: number; value: string; default: boolean }
        ) {}
    }
    export class FetchPreferences {
        static readonly type = `[ACCOUNT] Fetch Preferences`;
        constructor() {}
    }
    export class UpdatePreferences {
        static readonly type = `[ACCOUNT] Update Preferences`;
        constructor(
            public preferences: {
                allow_mk_sms?: boolean;
                allow_mk_email?: boolean;
                allow_mk_call?: boolean;
                allow_mk_push_notification?: boolean;
                allow_mk_letter?: boolean;
                preferences_submitted?: boolean;
            }
        ) {}
    }
    export class RequestDeleteAccount {
        static readonly type = `[ACCOUNT] Request Delete Account`;
        constructor(public data: { comment: string; password: string }) {}
    }
    export class FetchPaymethods {
        static readonly type = `[ACCOUNT] Fetch Paymethods`;
        constructor() {}
    }
    export class CreatePaymethod {
        static readonly type = `[ACCOUNT] Create Paymethod`;
        constructor(public paymethod: {
            payment_method_id: number;
            // paymethod: {
                setup: {
                    type: string;
                    payload: {
                        token: string;
                        device_data?: any;
                    };
                };
            // };
        }) {}
    }
    export class DeletePaymethod {
        static readonly type = `[ACCOUNT] Delete Paymethod`;
        constructor(public paymethod: { id: number }) {}
    }
    export class MarkPaymethodAsDefault {
        static readonly type = `[ACCOUNT] Mark Paymethod As Default`;
        constructor(
            public paymethod: {
                id: number;
                sort: number;
                default: boolean;
                description: string;
                label: string;
                brand: string;
                payment_method_id: number;
            }
        ) {}
    }
    export class FetchPaymentMethods {
        static readonly type = `[ACCOUNT] Fetch Payment Methods`;
        constructor() {}
    }
    export class Reset {
        static readonly type = `[ACCOUNT] Reset`;
    }
    export class Initialize {
        static readonly type = `[ACCOUNT] Initialize`;
    }

    export class FetchReferralResults {
        static readonly type = "[ACCOUNT] Fetch Referral Results"
    }
}
