import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { ColorMask } from './../../../../helpers/color-mask';

@Directive({
    selector: '[serviceosNgImageColorFilter]',
})
export class ImageColorFilterDirective implements AfterContentInit {
    @Input()
    public color = '';

    constructor(private el: ElementRef) {}

    public ngAfterContentInit(): void {
        this.applyFilter();
    }

    private applyFilter() {
        if (!this.color || this.color.indexOf('#') === -1) {
            return;
        }

        const colorMask = ColorMask.generate(this.color);

        this.el.nativeElement.setAttribute('style', colorMask);
    }
}
