<ng-container *ngIf="!settings?.customize?.chips">
    <div class="sos-checkbox-wrap" [ngClass]="{ 'sos-selected': settings.value }" (click)="onClicked(settings)"
        #checkBox>
        <div class="sos-checkbox-check">
            <span class="sos-box icon-si-check"></span>
        </div>

        <div class="sos-checkbox-icon-holder d-flex align-items-center">
            <span class="sos-checkbox-icon" *ngIf="settings.image_url">
                <img src="{{ settings?.image_url }}" />
            </span>
        </div>
        <div class="sos-checkbox-content d-flex align-items-center">
            <div class="sos-text-holder">
                <span class="sos-checkbox-title">
                    {{ settings.title }}
                </span>
                <span class="align-vertical-icon"
                    *ngIf="settings && settings.customize && settings.customize.obf_additional_info">
                    <span class="sos-icon icon-si-info-circle"></span>
                </span>
                <span class="sos-checkbox-description" *ngIf="settings.description">{{ settings.description }}</span>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="settings?.customize?.chips">
    <div class="sos-checkbox-chips-wrap">
        <div class="sos-checkbox-chips pointer" [ngClass]="{ 'sos-selected': settings.value }"
            (click)="onClicked(settings)" #checkBox>
            <span class="sos-checkbox-chip-title">
                {{ settings.title }}
            </span>
        </div>
    </div>
</ng-container>