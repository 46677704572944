import { Selector, createSelector } from '@ngxs/store';
import { DeepLinksState, DeepLinksStateModel } from './deep-links.state';

export class DeepLinksSelectors {
  static listenerDeepLink(type?: string) {
    return createSelector(
      [DeepLinksState],
      (deepLinkState: DeepLinksStateModel) => {
        if (type && deepLinkState && type == deepLinkState.type) {
          return deepLinkState;
        }

        return deepLinkState;
      }
    );
  }
}
