import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DynamicFormStateService {
  public action$ = new Subject();
  public valueChange$ = new Subject();
  constructor() {}

  emitAction(action: any) {
    this.action$.next(action);
  }

  valueChange(formChoiceItem: any) {
    this.valueChange$.next(formChoiceItem);
  }
}
