import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    Choice,
    ChoiceItem,
    ChoiceItemValueChange,
} from './../../../../../typings';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, Subscription } from 'rxjs';
import { scan, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'serviceos-ng-dropdown-item',
    templateUrl: './dropdown-item.component.html',
    styleUrls: ['./dropdown-item.component.scss'],
})
export class DropdownItemComponent implements OnInit {
    @Output()
    public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

    @Input()
    public settings!: ChoiceItem;

    @Input()
    public serviceCustomize;

    @Input()
    public parentSettings!: Choice | ChoiceItem;

    public selectedItem: any = 'null';
    public mobile = true;

    private keyUpSubject: Subject<string> = new Subject();
    public showItems = false;
    private subscription: Subscription | null = null;
    @ViewChild('dropdownList')
    public dropdownList;

    @ViewChildren('dropdownItems')
    public dropdownItems;

    constructor(private deviceDetectorService: DeviceDetectorService) {}

    public keuUp(event) {
        this.keyUpSubject.next(event.key);
    }

    public ngOnInit() {
        if (this.settings) {
            this.settings.value = 1;

            // find and set selectedItem
            this.selectedItem = this.settings.choice_items.find((el) => {
                return el.value !== 0;
            });

            this.selectItem(this.selectedItem, false);
        }

        if (
            !this.deviceDetectorService.isMobile() &&
            !this.deviceDetectorService.isTablet()
        ) {
            this.mobile = false;
        }

        this.searchResult();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    /**
     * Dropdown toggle
     * @param value
     */
    public toggleDropDown(value: any): void {
        if (value === undefined) {
            this.showItems = !this.showItems;
        }

        this.showItems = value;
    }

    /**
     * User mousedown handler
     * @param item
     */
    public selectItem(item: any, userInteraction: any): void {
        this.settings.choice_items.map((el) => {
            el.value = 0;
            return el;
        });

        if (item) {
            this.selectedItem = item;

            if (item !== 'null') {
                this.selectedItem.value = 1;
            }

            this.showItems = false;
        } else {
            this.selectedItem = 'null';
        }

        this.changeChoiceItemValue.emit({
            item: this.settings,
            userInteraction,
        });
    }

    /**
     * Subscription to user typing to scroll to specific element
     */
    private searchResult(): void {
        let newSearch = false;

        this.subscription = this.keyUpSubject
            .pipe(
                scan((acc, val) => {
                    if (newSearch) {
                        newSearch = false;
                        return val;
                    }
                    return acc + val;
                }),
                debounceTime(500)
            )
            .subscribe((data) => {
                if (data) {
                    newSearch = true;
                    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
                        window.navigator.userAgent
                    );

                    const firstElementWithString = this.dropdownItems.find(
                        (li) => {
                            return li.nativeElement.innerText
                                .slice(0, data.length)
                                .toUpperCase()
                                .includes(data.toUpperCase());
                        }
                    );

                    if (firstElementWithString && !isIEOrEdge) {
                        this.dropdownList.nativeElement.scrollTo({
                            top: firstElementWithString.nativeElement.offsetTop,
                            behavior: 'smooth',
                        });
                    }
                }
            });
    }
}
