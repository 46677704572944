<img
    class="vector-logo"
    *ngIf="isLogoInSvgFormat"
    [src]="_logoUrl"
    alt="{{ brandName }}"
    data-e2e="header-brand-logo"
/>
<img
    class="image-logo"
    *ngIf="!isLogoFromFileServer && !isLogoInSvgFormat"
    [src]="_logoUrl"
    alt="{{ brandName }}"
    data-e2e="header-brand-logo"
/>
<img
    class="server-logo"
    *ngIf="isLogoFromFileServer && !isLogoInSvgFormat"
    [src]="_logoUrl"
    alt="{{ brandName }}"
    data-e2e="header-brand-logo"
/>
