import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ModalOverlayRef } from '../../modals/modal-overlay-ref';

@Component({
  selector: 'serviceos-ng-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss']
})
export class DefaultModalComponent implements OnInit {

  public modalData;

  constructor(
    private modalRef: ModalOverlayRef,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.modalData = this.modalRef.configData;
  }

  public closeModal(data) {
    this.modalRef.close(data);
  }

}
