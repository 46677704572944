import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SosLoaderComponent } from './loader.component';


@NgModule({
  declarations: [
    SosLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SosLoaderComponent
  ]
})
export class SosLoaderModule { }
