import { SosCryptoService } from './../../../helpers/crypto/crypto.service';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { popupCenter } from '../../../helpers';
import { ApiRequest, Environment } from '../../../common';
import { BaseSocialAuth } from './base-social-auth';
import { AuthorizationData, SocialProvider } from '../authentication-types';
import { TranslateService } from '@ngx-translate/core';
/**
 * Declarate global property gapi in window scope / Add gapi to window property interface
 */
declare global {
    interface Window {
        gapi: any;
    }
}

/**
 * Google authentication services use in register and login components
 */
@Injectable({
    providedIn: 'root',
})
export class GoogleAuthService extends BaseSocialAuth {
    authPaths: { login: string } = { login: '/ext/google-login' };

    constructor(cryptoService: SosCryptoService, env: Environment, translate: TranslateService) {
        super(cryptoService, env, translate);
    }

    /**
     * @param requestType
     */
    public beginAuth(socialProvider: SocialProvider) {
        return new Promise<ApiRequest<AuthorizationData>>((res, rej) => {
            const clientId = socialProvider.data.client_id;

            // This need to be change when we have one authorization location for all clients
            const authorizationDomain =
                window.location.origin + this.authPaths.login;

            const state = this.createState();

            // eslint-disable-next-line max-len
            const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=${state};&redirect_uri=${encodeURIComponent(
                authorizationDomain
            )}&client_id=${clientId}`;

            const apiRequest = new ApiRequest({
                type: 'POST',
                url: googleAuthUrl,
                data: null,
                options: null,
            });

            let authenticationResponse;

            const centerPopup = popupCenter();
            const googlePopup = window.open(
                googleAuthUrl,
                'popUpWindow',
                'height=710,width=630,left=' +
                    centerPopup.left +
                    ',top=' +
                    centerPopup.top +
                    ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no'
            );

            const googlePostMessageHandler = (event) => {
                if (event.data.c_google_login_data) {
                    authenticationResponse = event.data.c_google_login_data;
                    googlePopup?.close();
                }
            };
            // append listener for event
            window.addEventListener('message', googlePostMessageHandler);

            const subscription = interval(1000).subscribe(async () => {
                // Wait to close popup.
                if (googlePopup && !googlePopup.closed) {
                    return;
                }

                if (authenticationResponse == null) {
                    try {
                        authenticationResponse = localStorage.getItem(
                            'c_google_login_data'
                        );
                        localStorage.removeItem('c_google_login_data');
                    } catch (e) {}
                }

                if (authenticationResponse) {
                    // decryptData data.
                    const socialData: any = this.cryptoService.decryptData(
                        authenticationResponse,
                        this.env.cookieKey
                    );

                    apiRequest.setResponse(socialData);
                    if (socialData && !socialData.error) {
                        res(apiRequest);
                    } else {
                        rej(apiRequest);
                    }
                } else {
                    apiRequest.setResponse(
                        await this.unexpectedErrorWhenAuthorize(socialProvider)
                    );
                    rej(apiRequest);
                }

                window.removeEventListener('message', googlePostMessageHandler);
                subscription.unsubscribe();
            });
        });
    }
}
