import { filter, map, tap } from 'rxjs/operators';
import { CoreState } from './../stores/core-store/core.state';
import { Injectable } from '@angular/core';
import {
    CanLoad,
    Route,
    UrlSegment,
    UrlTree,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CoreConfigGuard implements CanLoad, CanActivate {
    constructor(private store: Store) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.store.select(CoreState).pipe(
            filter((data) => {
                if (data.status != 'pending') {
                    return true;
                }

                return false;
            }),
            map((data) => {
                return data.status == 'success';
            }),
            tap((res) => {
                //..
            })
        );
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.store.select(CoreState).pipe(
            filter((data) => {
                if (data.status != 'pending') {
                    return true;
                }

                return false;
            }),
            map((data) => {
                return data.status == 'success';
            }),
            tap((res) => {
                //..
            })
        );
    }
}
