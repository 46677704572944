import { Observable } from 'rxjs';
import { PageComponent } from './../../modals/page/page.component';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import { NavService } from '../nav.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ModulesSelectors } from '../../../common';

@Component({
    selector: 'serviceos-ng-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input()
    logo: string | null = null;

    @Input()
    logoText: string | null = '';

    @Input()
    mobileMenuNotifications = false;

    @Input()
    topBarConfig: {
        message: string,
        centered: boolean,
		href?: string
    } | null = null;

    // navigation is manipulate from parent frame component.
    navigation = false;

    @Output()
    onClickLogo: EventEmitter<any> = new EventEmitter();

    navigationState$: Observable<any>;

    constructor(
        @Optional() private navService: NavService
    ) {
        this.navigationState$ = this.navService.navState;
    }

    ngOnInit(): void {}

    toggleMenu() {
        this.navService.toggle();
    }

    clickLogo() {
        this.onClickLogo.emit();
    }

    setNavigationState() {}
}
