
/**
 * Concat random hex numbers
 */
 export function genGUID() {
    return getRandom4SymbolString() + getRandom4SymbolString() + getRandom4SymbolString();
}

export function genLongGUID() {
    return getRandom10SymbolString() + getRandom10SymbolString() + getRandom10SymbolString() + getRandom10SymbolString();
}

/**
 * Generate random 4 digit string
 */
function getRandom4SymbolString() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

/**
 * Generate random 10 digit string
 */
function getRandom10SymbolString() {
    return Math.floor((1 + Math.random()) * 0x1000000000)
        .toString(16);
}
