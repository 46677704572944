import { filter, map } from 'rxjs/operators';
import { CoreState } from './../stores/core-store/core.state';
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitchGuard implements CanLoad {
  constructor(private store: Store, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const themes = route.data?.themeSwitchCases;

    if (!themes) {
      return true;
    }

    return this.store.select(CoreState).pipe(
      filter((data) => {
        if (data.status != 'pending') {
          return true;
        }

        return false;
      }),
      map((data) => {
        // Check the template
        if (data.config?.theme?.name && themes[data.config.theme.name]) {
          route.loadChildren = themes[data.config?.theme?.template];
        }

        if (data.status == 'success') {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
