import { BookingCardDetailsModule } from './booking-card-details/booking-card-details.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SosLoaderModule } from './loader/loader.module';
import { SosDealCardModule } from './deal-card/deal-card.module';
import { LinkModule } from './shared/base/link/link.module';
import { ButtonModule } from './shared/base/button/button.module';
import { LogoComponent } from './logo/logo.component';

@NgModule({
    imports: [
        CommonModule,
        SosLoaderModule,
        SosDealCardModule,
        LinkModule,
        ButtonModule,
        BookingCardDetailsModule,
    ],
    exports: [
        SosLoaderModule,
        SosDealCardModule,
        LinkModule,
        ButtonModule,
        BookingCardDetailsModule,
    ],
})
export class SosElementsModule {}
