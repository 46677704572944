import { finalize, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TOKEN } from './tracking.const';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { TrackingActions } from './tracking.actions';
import { interval } from 'rxjs';

declare global {
    interface Window {
        dataLayer: any[];
        hj: any;
    }
}

export class TrackingStateModel {
    options: {
        hotjarId: string | null;
    } = {
        hotjarId: 'N/A',
    };
    dataLayer: { event: string; data: any }[] = [];
    status?: 'pending' | 'error' | 'success' = 'pending';
}

@State<TrackingStateModel>({
    name: TOKEN,
    defaults: new TrackingStateModel(),
})
@Injectable()
export class TrackingState implements NgxsOnInit {
    constructor() {
        // Secure the existence of dataLayer.
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
    }

    ngxsOnInit(ctx?: StateContext<any> | undefined) {
        /**
         * Read hotjar id.
         */
        interval(1000)
            .pipe(take(10))
            .subscribe(() => {
                let hotjarId: any = null;
                try {
                    hotjarId = window.hj.pageVisit.property
                        .get('userId')
                        .split('-')
                        .shift();
                } catch (e) {
                    try {
                        hotjarId = window.hj.globals
                            .get('userId')
                            .split('-')
                            .shift();
                    } catch (e) {}
                }
                const currentState = ctx?.getState();
                if (hotjarId && currentState?.options?.hotjarId) {
                    ctx?.patchState({
                        options: { ...currentState.options, ...{ hotjarId } },
                    });

                    ctx?.dispatch(
                        new TrackingActions.Push('hotjarSessionId', {
                            hotjarSessionId: hotjarId,
                        })
                    );
                }
            });
    }

    @Action(TrackingActions.Push)
    push(
        { patchState, getState }: StateContext<TrackingStateModel>,
        payload: TrackingActions.Push
    ) {
        const state = getState();

        window.dataLayer.push({ event: payload.event, ...payload.data });
        patchState({
            dataLayer: [...state.dataLayer, payload],
        });
    }
}
