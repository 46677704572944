import { NavigationStart, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface NavStateInterface {
    mobileView: boolean;
    showNavigation: boolean;
    haveNavigation: boolean;
}

@Injectable()
export class NavService {
    public navState: BehaviorSubject<NavStateInterface> = new BehaviorSubject({
        mobileView: false,
        showNavigation: false,
        haveNavigation: false,
    } as NavStateInterface);

    constructor(
        private breakpointObserver: BreakpointObserver,
        private router: Router
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                
                const currentState: NavStateInterface = JSON.parse(
                    JSON.stringify(this.navState.getValue())
                );

                if (currentState.mobileView === true) {
                    currentState.showNavigation = false;
                    this.navState.next(currentState);
                }
            });

        this.breakpointObserver
            .observe(['(min-width: 1200px)'])
            .subscribe((state: BreakpointState) => {
                const currentState: NavStateInterface = JSON.parse(
                    JSON.stringify(this.navState.getValue())
                );

                if (!state.matches && currentState.mobileView == false) {
                    currentState.showNavigation = false;
                    currentState.mobileView = true;

                    this.navState.next(currentState);
                } else if (currentState.mobileView) {
                    currentState.mobileView = false;
                    this.navState.next(currentState);
                }
            });
    }

    public toggle() {
        const currentState: NavStateInterface = JSON.parse(
            JSON.stringify(this.navState.getValue())
        );

        currentState.showNavigation = !currentState.showNavigation;

        this.navState.next(currentState);
    }

    public navigationProvidedStatus(status) {
        const currentState: NavStateInterface = JSON.parse(
            JSON.stringify(this.navState.getValue())
        );

        currentState.haveNavigation = status;

        this.navState.next(currentState);
    }
}
