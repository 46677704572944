import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { DeepLinksState } from './deep-links.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([DeepLinksState]),
  ],
})
export class DeepLinksStateModule {}
