import * as cryptoAES from 'crypto-js/aes';

export class MailSender {
    private encryptionKey: string | null = null;
    private emailReportLocation: string | null = null;

    public constructor(emailReportLocation: string, encryptionKey: string) {
        this.encryptionKey = encryptionKey;
        this.emailReportLocation = emailReportLocation;
    }

    public send(reportData: MailReportInterface, reportOptions?: MailReportOptionsInterface) {

        let mailOptions: MailReportOptionsInterface = {
            emails: ['team'],
            subject: `[${reportData.project} | ${reportData.version}] ${reportData.clientIp}`,
            reportType: 'ReportEmail'
        }

        let mailReportData: Partial<MailReportInterface> = {
            currentPosition: 'N/A',
            domainUrl: 'N/A',
            fullDomainUrl: 'N/A',
            website_phone: 'N/A',

            clientId: 'N/A',
            email: 'N/A',
            name: 'N/A',
            hotjarSessionId: 'N/A',
            clientInfo: 'N/A',

            service: 'N/A',
            postcode: 'N/A',
            transaction: 'N/A'
        }

        reportOptions && Object.assign(mailOptions, reportOptions);
        Object.assign(mailReportData, reportData);

        let request;

        // Create new request
        request = new window.XMLHttpRequest();

        request.addEventListener('load', () => {
            // Do something on email sent
        });

        // Api Return Server Error
        request.addEventListener('error', () => {
            // Do something on email sending error
        });

        let phpHandlerName = 'debug-report.php',
            emailTemplateName = 'reportEmailTemplate'

        // Temporary until xrm create specific endpoint.
        if(mailOptions.reportType == 'RatingSurvey'){
            emailTemplateName = 'RatingSurveyEmailTemplate';
        }

        request.open('post', this.emailReportLocation + phpHandlerName);

        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        let data = {
            data: JSON.stringify(reportData),
            template: emailTemplateName,
            templateFolder: '',
            emails: mailOptions.emails,
            subject: mailOptions.subject,
            phone: 'N/A',
            href: reportData.fullDomainUrl,
            websiteName: 'N/A',
        };

        if (this.encryptionKey) {
            data = this.encryptData(data, this.encryptionKey);
        }

        request.send(JSON.stringify(data));
    }

    /**
     * @param {any} data
     * @param {string} key
     */
    private encryptData(data, key: string, toString = true) {
        let encryptedData: any = '';

        if (typeof data === 'object') {
            encryptedData = cryptoAES.encrypt(JSON.stringify(data), key);
        } else {
            encryptedData = cryptoAES.encrypt(data.toString(), key);
        }

        return toString ? encryptedData.toString() : encryptedData;
    }
}


export interface MailReportInterface {
    project: string;
    version: string,
    clientIp: string

    currentPosition?: string,
    domainUrl?: string,
    fullDomainUrl?: string,
    website_phone?: string,

    clientId?: string,
    email?: string,
    name?: string,
    hotjarSessionId?: string,
    clientInfo?: string,

    service?: string,
    postcode?: string,
    transaction?: string

    errors: string,
}


export interface MailReportOptionsInterface {
    subject?: string;
    emails?: Array<'team' | 'system' | 'fs_coverage' | 'coverage' | 'fs_rating' | 'rating'>;
    reportType?: 'RatingSurvey' | 'ReportEmail'; 
}

