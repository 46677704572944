import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { BookingState } from './booking.state';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([BookingState]),
  ]
})
export class BookingStateModule { }
