import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { SosApiService } from './common/services/api/api.service';
import { SosCoreConfig } from './core-config';
import { SosCoreStoreModule } from './common/stores/core-store/core-store.module';
import { CustomErrorHandler, Environment, NgxsErrorPlugin } from './common';
import { SosErrorStoreModule } from './common/stores/error-store/error-store.module';

import { TranslateDefaultParser, TranslateModule, TranslateParser } from '@ngx-translate/core';

import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import { APP_INITIALIZER } from '@angular/core';
import { ModalsModule } from './elements';
import { NGXS_PLUGINS } from '@ngxs/store';
import { SOSTranslateParserService } from './common/stores/core-store/localization/sostranslate-parser.service';
import { TrustContentPipe } from './common/pipes/trust-content.pipe';
import { ScriptLoaderModule } from 'ngx-script-loader';

function initializeSentry(environment: Environment) {
    const sentryFingerprintId = Math.random().toString(36).substr(2, 9);

    if (
        environment?.reporting?.sentry?.dsn &&
        environment?.reporting?.sentry?.enabled
    ) {
        Sentry.init({
            dsn: environment.reporting.sentry.dsn,
            environment: environment.type,
            release: `${environment.project}@${environment.version}`,
            maxBreadcrumbs: 50,
            debug: environment.production ? false : true,
            attachStacktrace: true,

            ignoreErrors: [
                'https://c.paypal.com/',
                'https://in.hotjar.com/',
                'fb_xd_fragment',
                'Google auth register',
            ],

            integrations: [
                new TracingIntegrations.BrowserTracing({
                    tracingOrigins: [window.location.origin + '/'],
                    routingInstrumentation: Sentry.routingInstrumentation,
                }),
            ],

            // Modify Errors Exceptions with tags
            beforeSend: (event, hint) => {
                let sendEvent = false;
                let sentryExceptionData: any = new Object();

                if (event.hasOwnProperty('exception')) {
                    sentryExceptionData = event;
                }

                if (
                    event.hasOwnProperty('extra') &&
                    event.extra &&
                    event.extra.hasOwnProperty('parse-error') &&
                    event.extra['parse-error']
                ) {
                    if (Object.keys(sentryExceptionData).length === 0) {
                        sentryExceptionData = event;
                    }

                    sentryExceptionData.fingerprint = [sentryFingerprintId];
                    sentryExceptionData.tags = event.tags;
                    sentryExceptionData.extra = event.extra;
                    sendEvent = true;
                }

                return sendEvent ? sentryExceptionData : null;
            },

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        SosCoreStoreModule,
        SosErrorStoreModule,
        TranslateModule,
        ModalsModule,
        ScriptLoaderModule,
    ],
    exports: [TranslateModule, ModalsModule, TrustContentPipe],
    declarations: [
      TrustContentPipe
    ], // export translation directive and pipe
})
export class SosCoreModule {
    static forRoot(
        config: SosCoreConfig,
        environment: Environment
    ): ModuleWithProviders<SosCoreModule> {
        initializeSentry(environment);

        const translateModuleProviders = TranslateModule.forRoot({
            parser: {
                provide: TranslateParser,
                useClass: SOSTranslateParserService,
            },
            defaultLanguage: 'default',
        });

        return {
            ngModule: SosCoreModule,
            providers: [
                //Import providers from initialize TranslateModule.forRoot
                ...(translateModuleProviders.providers ?? []),
                {
                    provide: SosCoreConfig,
                    useValue: config,
                },
                {
                    provide: Environment,
                    useValue: environment,
                },
                
                {
                    provide: ErrorHandler,
                    useClass: CustomErrorHandler,
                },
                {
                    provide: NGXS_PLUGINS,
                    useClass: NgxsErrorPlugin,
                    multi: true,
                },
                {
                    provide: Sentry.TraceService,
                    deps: [Router],
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: () => () => {},
                    deps: [Sentry.TraceService],
                    multi: true,
                },
                SosApiService,
            ],
        };
    }
}
