import { HandlerErrorService } from './handler-error';
import { ApiRequest } from './../../services/api/api.service';
import { ERROR_CODES } from './error.const';

import { Store } from '@ngxs/store';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import * as Sentry from '@sentry/angular';
import { ErrorActions } from './error.actions';
import { Environment } from '../../environment/environment';
import { convertErrorToObject, deepMerge, refCopy } from '../../../helpers';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
    constructor(private store: Store, private injector: Injector) {
        super();
    }

    handleError(error: any) {
        const env: Environment = this.injector.get(Environment);
        const registerAppHandlerErrorService = this.injector.get(
            HandlerErrorService
        );
            debugger;
        if (env && env.type !== 'production') {
            // Simply prints out the error in the console
            super.handleError(error);
        }

        // Secure Error Structure
        if (!error.hasOwnProperty('errorMetaData'))
            error['errorMetaData'] = {
                reported: false,
            };

        // NOTE https://github.com/ngxs/store/issues/1691
        // Skip error handle when error is happened inside ngxsStore the error is catch again where is fire the dispatch of the action if have subscribe to him.
        // !! Import this way subscribe is required when dispatch action in store.
        if (
            error['errorMetaData'] &&
            error.errorMetaData.hasOwnProperty('ngxsError') &&
            !error.errorMetaData.ngxsError &&
            !(error?.error && error.error.length && error.error.find((item) => item.code == ERROR_CODES.Common.Unauthorized.code ))
        ) {
            error.errorMetaData['ngxsError'] = true;
        } else {
            // Call handleErrorService to handle error.

            registerAppHandlerErrorService.handle(error, true);
        }

        if (!error.errorMetaData.reported) {
            error.errorMetaData.reported = true;

            this.reportError(error);
        }
    }

    // Submit the error for reporting.
    private reportError(error) {
        const env: Environment = this.injector.get(Environment);
        const registerAppHandlerErrorService = this.injector.get(
            HandlerErrorService
        );

        // Api Errors.
        if (error instanceof ApiRequest) {
            this.store.dispatch(new ErrorActions.Api(refCopy(error)));
            return;
        }

        this.store.dispatch(
            new ErrorActions.Javascript(
                registerAppHandlerErrorService.determineErrorCode(error),
                convertErrorToObject(error)
            )
        );

        if (
            env &&
            (env.type === 'stage' ||
                env.type === 'development' ||
                env.type === 'production')
        ) {
            const eventId = Sentry.captureException(
                error.originalError || error
            );
            if (env.type === 'stage' || env.type === 'development') {
                Sentry.showReportDialog({ eventId });
            }
        }
    }
}
