import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Choice,
  ChoiceItem,
  ChoiceItemValueChange,
} from '../../../../../typings';

@Component({
  selector: 'serviceos-ng-checkbox-item',
  templateUrl: './checkbox-item.component.html',
  styleUrls: ['./checkbox-item.component.scss'],
})
export class CheckboxItemComponent implements OnInit {
  
  @ViewChild('checkBox')
  private checkBox!: ElementRef;

  @Output()
  public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

  @Input()
  public settings!: ChoiceItem;

  @Input()
  public serviceCustomize;

  @Input()
  public parentInstance!: Choice | ChoiceItem;

  constructor() {}

  public ngOnInit() {
    this.initialize();
  }

  /**
   * Component initializer
   */
  private initialize(): void {
    if (this.settings.value === 1) {
      this.settings.value = 1;
    } else {
      this.settings.value = 0;
    }

    this.changeChoiceItemValue.emit({
      item: this.settings,
      userInteraction: false,
    });
  }

  /**
   * Check the value of the checkbox
   * and run calculate of the hours
   * @param settings
   */
  public onClicked(settings: any): void {
    this.settings.value = this.settings.value ? 0 : 1;

    this.changeChoiceItemValue.emit({
      item: this.settings,
      userInteraction: true,
    });
  }
}
