import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SosLoaderService } from '../../loader/loader.service';


/**
 *  @param url Accept tree types of value 
 *  When is null will not display link.
 *  When is string the url should be full url too where to navigate 
 *  And object routerNavigatiomParams will pass the object to router like so this.router.navigate(url.commands, url.extras);
 *  
 * */
export interface IBreadcrumb {
    title: string;
    url: routerNavigationParams | string | null;
}

interface routerNavigationParams {
    commands: any[];
    extras?: NavigationExtras | undefined;
}

@Component({
    selector: 'serviceos-ng-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
    @Input()
    breadcrumbs!: IBreadcrumb[];

    constructor(private route: ActivatedRoute, private router: Router, private loaderService: SosLoaderService) {}

    ngOnInit(): void {}

    public navigateTo(url: routerNavigationParams | string) {
        if (typeof url != 'string' && url.commands) {
            this.router.navigate(url.commands, url.extras);
        }

        if (typeof url == 'string') {
            this.loaderService.showLoader();

            window.location.href = url;
        }
    }
}
