<div class="choice {{ parentSettings?.id && 'parent_id_' + parentSettings?.id}}" *ngIf="settings"
    [attr.required]="settings?.required" [id]="settings?.id">
    <div class="choice-title" *ngIf="settings?.title || settings?.description">
        <h3 *ngIf="settings?.title ">{{ settings?.title }}</h3>
        <p *ngIf="settings?.description">
            {{ settings.description }}
        </p>
    </div>
    <div class="choice-items">
        <div class="choice-item choice-item-{{choiceItem.type}} {{settings?.choice_items[i+1] && settings.choice_items[i+1].type != choiceItem.type? 'diff-choice-item': ''}}"
            *ngFor="let choiceItem of settings?.choice_items;index as i"
            [ngClass]="{'hidden': choiceItem.id === 0}">
            <serviceos-ng-choice-item [settings]="choiceItem" [parentSettings]="settings"
                (changeChoiceItemValue)="changeChoiceItemValue.emit($event)">
            </serviceos-ng-choice-item>

            <ng-container *ngIf="choiceItem?.value && choiceItem?.type !== 'dropdown'">
                <div *ngIf=" choiceItem.choice_items !=null && choiceItem.choice_items.length ">
                    <serviceos-ng-default [settings]=" choiceItem" [parentSettings]="settings"
                (changeChoiceItemValue)="changeChoiceItemValue.emit($event)">
                    </serviceos-ng-default>
                </div>
            </ng-container>
    </div>
</div>
</div>