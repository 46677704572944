<label [for]="inputId">
    <input
        type="radio"
        #input
        [value]="value"
        [checked]="checked"
        [id]="inputId"
        [name]="name"
        (change)="_onInputInteraction($event)"
        (click)="_onInputClick($event)"
    />

    <div
        class="radio-wrap"
        [ngClass]="{
            checked: checked,
            disabled: disabled,
            error: error
        }"
    >
        <div class="radio">
            <div class="radio-check"></div>
        </div>
        <div class="radio-label">
            <ng-content></ng-content>
        </div>
    </div>
</label>
