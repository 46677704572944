import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { ValidationsActions } from './validations.actions';
import { ValidationPattern, VALIDATIONS_STATE_TOKEN } from './validations.const';
import { tap } from 'rxjs/operators';
import { ValidationsService } from '../../../services/validations/validations.service';

export class ValidationsStateModel {
    validationPatterns: ValidationPattern[] = [];
}

@State<ValidationsStateModel>({
    name: VALIDATIONS_STATE_TOKEN,
    defaults: new ValidationsStateModel(),
})
@Injectable()
export class ValidationsState implements NgxsOnInit {
	constructor(private _store: Store, private _validationsService: ValidationsService) {}

	ngxsOnInit(ctx?: StateContext<any> | undefined) {
        // ctx?.dispatch(new ValidationsActions.Initialize()).subscribe();
    }

    @Action(ValidationsActions.Initialize)
    initialize({ dispatch }: StateContext<ValidationsStateModel>) {
        dispatch(new ValidationsActions.GetAll()).subscribe();
    }

    @Action(ValidationsActions.GetAll)
    getAll({ dispatch, setState, patchState }: StateContext<ValidationsStateModel>) {
        return this._validationsService.getAll().pipe(
            tap(
                (response) => {
                    const validations: ValidationPattern[] = response.data;

                    patchState({ validationPatterns: validations });
                },
                (error) => {
                    patchState({ validationPatterns: [] });
                }
            )
        );
    }
}
