import {
    Localization,
    LocalizationState,
    LocalizationStateModel,
} from './localization.state';
import { Selector, createSelector } from '@ngxs/store';

export class LocalizationSelectors {
    static getSelectedLanguage() {
        return createSelector(
            [LocalizationState],
            (state: LocalizationStateModel) => {
                return state.language;
            }
        );
    }

    static getLocalization(lang: string) {
        return createSelector(
            [LocalizationState],
            (state: LocalizationStateModel) => {
                return state.languages.find((el) => el.code == lang);
            }
        );
    }

    static isPageLocalizationLoaded(page) {
        return createSelector(
            [LocalizationSelectors.getCurrentLocalization()],
            (localization: Localization): boolean => {
                return !!localization?.texts[page];
            }
        );
    }

    static getDefaultLanguage() {
        return createSelector(
            [LocalizationState],
            (state: LocalizationStateModel) => {
                const defaultLanguage = state.languages.find((localization) => {
                    return localization.selected == true;
                });

                return defaultLanguage ?? state.languages[0];
            }
        );
    }

    static getCurrentLocalization() {
        return createSelector(
            [LocalizationState],
            (state: LocalizationStateModel) => {
                return state.languages.find((localization) => {
                    return localization.code == state.language;
                });
            }
        );
    }

    static getShortcodes() {
        return createSelector(
            [LocalizationState],
            (state: LocalizationStateModel) => {
                return state.shortcodes;
            }
        );
    }

    static getLanguages() {
        return createSelector(
            [LocalizationState],
            (state: LocalizationStateModel) => {
                return state.languages;
            }
        );
    }
}
