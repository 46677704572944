<div class="sos-pro-wrapper">
    <div class="sos-pro-header">
        <div class="sos-pro-avatar">
            <img [src]="unitInfo.image_url" alt="">
        </div>
        <div class="sos-pro-info">

            <div *ngIf="unitInfo.name" class="sos-pro-title">
                <span class="title">{{ unitInfo?.name }}</span>
            </div>
            <div *ngIf="unitInfo && unitInfo.stats && unitInfo.stats.rating && unitInfo.details && unitInfo.details.gender"
                class="sos-pro-additional-info">
                <div class="d-flex">
                    <span class="unit-type" [innerHtml]="unitInfo?.details?.gender"></span>
                    <serviceos-ng-system-icon class="sos-icon rate" [icon]="'rating'">
                    </serviceos-ng-system-icon>
                    <span class="rating">
                        {{ unitInfo?.stats?.rating}}
                    </span>
                </div>
                
                <span class="unit-social-holder" *ngIf="!hideAddFavorite && (unitInfo.favorite === true || unitInfo.favorite === false)">
                    <serviceos-ng-system-icon *ngIf="unitInfo.favorite" (click)="toggleFavorite()" class="sos-icon fav"
                        [icon]="'add-to-favourites-full'"></serviceos-ng-system-icon>
                    <serviceos-ng-system-icon *ngIf="!unitInfo.favorite" (click)="toggleFavorite()" class="sos-icon"
                        [icon]="'add-to-favourites'"></serviceos-ng-system-icon>
                </span>
            </div>

            <div class="sos-line-devider-box">
                <span class="line"></span>
            </div>

            <div *ngIf="unitInfo && unitInfo.stats && unitInfo.stats.total_bookings && unitInfo.stats.experience"
                class="sos-pro-experience-holder">
                <ul>
                    <li>
                        <serviceos-ng-system-icon class="sos-icon" [icon]="'total-bookings'"></serviceos-ng-system-icon>
                        <span class="label" [innerHTML]="texts.profile_label_total_bookings">
                        </span>
                        <span>
                            {{ unitInfo?.stats?.total_bookings }}
                        </span>
                    </li>
                    <li>
                        <serviceos-ng-system-icon class="sos-icon" [icon]="'clock-time-start-at'">
                        </serviceos-ng-system-icon>
                        <span class="label" [innerHTML]="texts.profile_label_experience">
                        </span>
                        <span>
                            {{ unitInfo?.stats?.experience }}
                        </span>
                    </li>
                    <li *ngIf="unitInfo?.details?.languages">
                        <serviceos-ng-system-icon class="sos-icon" [icon]="'global-planet-icon'">
                        </serviceos-ng-system-icon>
                        <span class="label" [innerHTML]="texts.profile_label_languages">
                        </span>
                        <span>
                            {{ unitInfo?.details?.languages ?
                            (unitInfo?.details?.languages.toString().replace('[','').replace(']','')) : '' }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div *ngIf="descriptionTabsCount && !(descriptionTabsCount === 1 && unitInfo && unitInfo.stats && unitInfo.stats.rating && unitInfo.stats.total_ratings)"
        class="sos-pro-description-tabs">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="sos-nav-tabs"
            [ngClass]="{'no-cursor': descriptionTabsCount === 1}">
            <li [ngbNavItem]="1" *ngIf="unitInfo?.badges" class="nav-item">
                <a ngbNavLink><span [innerHTML]="texts.tab_title_badges"></span></a>
                <ng-template ngbNavContent>
                    <ul class="sos-badges-list" *ngIf="unitInfo?.badges">
                        <li *ngFor="let badge of unitInfo?.badges">
                            <div class="badge-box" [ngStyle]="{'background-color':  badge.background_color }">
                                <img src="{{ badge.icon_url }}" alt="{{ badge.name }}" title="{{ badge.description }}">
                                <span>{{ badge.name }}</span>
                                <span *ngIf="badge.count_formatted && badge.count_formatted != '0'"
                                    [ngStyle]="{'background-color':  badge.count_color, 'color': badge.text_color }"
                                    class="badge-counter">{{ badge.count_formatted }}</span>
                            </div>
                        </li>
                    </ul>
                </ng-template>
            </li>
            <li [ngbNavItem]="2"
                *ngIf="unitInfo && unitInfo.stats && unitInfo.stats.rating && unitInfo.stats.total_ratings"
                class="nav-item">
                <a ngbNavLink><span [innerHtml]="texts.tab_title_rating"></span></a>
                <ng-template ngbNavContent>
                    <div class="rating-head-holder">

                        <serviceos-ng-system-icon class="sos-icon" [icon]="'rating'">
                        </serviceos-ng-system-icon>
                        <span class="rating-head">
                            {{unitInfo?.stats?.rating }}
                        </span>
                        <span class="rating-subhead"><span [innerHtml]="texts.rating_reviews_txt"></span> {{
                            unitInfo?.stats?.total_ratings }} <span
                                [innerHtml]="texts.rating_from_txt"></span></span>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" *ngIf="unitInfo?.details?.documents" class="nav-item">
                <a ngbNavLink><span [innerHtml]="texts.tab_title_documents"></span></a>
                <ng-template ngbNavContent>
                    <div class="sos-documents-list-holder">
                        <ul *ngIf="unitInfo?.details?.documents" class="sos-documents-list">
                            <li *ngFor="let document of unitInfo?.details?.documents">
                                <div class="icon-holder">
                                    <serviceos-ng-system-icon class="sos-icon" [icon]="'doc'"></serviceos-ng-system-icon>
                                </div>
                                <div class="type-holder">
                                    <span>{{ document.type }}</span>
                                </div>
                                <div class="cta-holder">
                                    <a href="{{ document.url}}" target="_blank">
                                        <serviceos-ng-link
                                            [type]="'primary'"
                                            [size]="'small'"
                                        >
                                            {{ texts.documents_view_cta }}
                                        </serviceos-ng-link>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" *ngIf="unitInfo?.details?.short_bio" class="nav-item">
                <a ngbNavLink><span [innerHtml]="texts.tab_title_biography"></span></a>
                <ng-template ngbNavContent>
                    <div class="pro-bio-holder">
                        {{ unitInfo?.details?.short_bio }}
                    </div>
                </ng-template>
            </li>
        </ul>
        <div #TabContent>
            <div [ngbNavOutlet]="nav" class="mt-2 tab-content"></div>
        </div>
    </div>
</div>