import { map } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import moment from 'moment-mini';

@Pipe({
  name: 'shouldDisplayDeepLink',
})
export class ShouldDisplayDeepLinkPipe implements PipeTransform {

  // ! Active until is utc this why check only utc hare dont convert to local time.
  private date = new Date().getTime();
  private time = moment.utc(this.date);

  transform(value: any): Observable<boolean> {
    if (!value?.active_to) {
      return of(false);
    }

    if (value.active_until) {
      return interval(1000).pipe(
        map((r) => {
          return this.time < value.active_until;
        })
      );
    } else {
      return of(true);
    }
  }
}
