import { NotificationsState, NotificationsStateModel } from './notifications.state';
import { Selector } from '@ngxs/store';

export class NotificationsSelectors {

    @Selector([NotificationsState])
    static getAll(state: NotificationsStateModel) {
        return state?.notifications
    }

    @Selector([NotificationsState])
    static getAllUnclosed(state: NotificationsStateModel) {
        return state?.notifications.filter((item)=> !item.closed)
    }

    @Selector([NotificationsState])
    static getCount(state: NotificationsStateModel) {
        return state?.notifications?.length
    }
}
