import { SosDealCardConfig } from './deal-card-config.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'serviceos-ng-deals-card',
  templateUrl: './deal-card.component.html',
  styleUrls: ['./deal-card.component.scss']
})
export class SosDealCardComponent implements OnInit {
  @Input()
  dealsConfig!: SosDealCardConfig;

  @Output()
  showDetailsEvent = new EventEmitter();

  @Output()
  selectEvent = new EventEmitter();

  expand = false;

  constructor() { }

  ngOnInit(): void {

  }

  selectHandler() {
    this.selectEvent.emit();
  }

  expandHandler(event: any) {
    if (event.target.classList.contains('deal-description')) {
      this.expand = true;
      return;
    }
    this.expand = !this.expand

  }

}
