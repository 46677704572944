import { Observable } from 'rxjs';

export function getObfClientLoadedSubscription(): Observable<void> {
    return new Observable((subscriber) => {
        const timeOut = setTimeout(() => {
            clearInterval(interval);

            subscriber.error('Check if page load obf');
            subscriber.complete();
        }, 15000);

        const interval = setInterval(() => {
            if ((window as any).obfClientSDK) {
                clearInterval(interval);
                clearTimeout(timeOut);
                subscriber.next();
                subscriber.complete();
            }
        }, 250);
    });
}
