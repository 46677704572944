import { Choice, ChoiceItem, ChoiceItemValueChange } from './../../../../../typings';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CHOICE_ITEMS_TYPES } from '../../../../../constants';


@Component({
  selector: 'serviceos-ng-radio-item',
  templateUrl: './radio-item.component.html',
  styleUrls: ['./radio-item.component.scss'],
})
export class RadioItemComponent implements OnInit {
  @Input()
  public settings!: ChoiceItem;

  @Input()
  public parentSettings!: Choice | ChoiceItem;

  @Output()
  public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.settings.value == 1) {
      this.changeChoiceItemValue.emit({
        item: this.settings,
        userInteraction: false,
      });
    } else {
      this.changeChoiceItemValue.emit({
        item: this.settings,
        userInteraction: false,
      });
    }
  }

  public onClick() {
    this.resetAllSelectedRadio();

    this.settings.value = 1;
    this.changeChoiceItemValue.emit({
      item: this.settings,
      userInteraction: true,
    });
  }

  /**
   * Radio buttons resetter
   */
  private resetAllSelectedRadio(): void {
    for (let i = 0; i < this.parentSettings.choice_items.length; i++) {
      if(this.parentSettings.choice_items[i] && this.parentSettings.choice_items[i].type == CHOICE_ITEMS_TYPES.Radio){
        this.parentSettings.choice_items[i].value = 0;
      }
    }
  }
}
