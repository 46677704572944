import { ModulesStateModel } from './../../common/stores/core-store/modules/modules.state';
import { filter } from 'rxjs/operators';
import { Selector } from '@ngxs/store';
import { ModulesState } from '../../common/stores/core-store/modules/modules.state';

import { NavigationState, NavigationStateModel } from './navigation.state';
import { ModulesSelectors } from '../../common';

export class NavigationSelectors {
    @Selector([
        NavigationState,
        ModulesSelectors.getModuleByName('application'),
    ])
    static getNavigation(
        navigationState: NavigationStateModel,
        applicationConfig: ModulesStateModel
    ) {
        const modules = applicationConfig.settings.modules;
        return navigationState.items.filter((navigationItem) => {
            if (
                navigationItem.requiredModules &&
                navigationItem.requiredModules.length
            ) {
                const containsAll = navigationItem.requiredModules.every((m) =>
                    modules.includes(m)
                );

                return containsAll;
            }
            return true;
        });
    }

    @Selector([NavigationState])
    static getInitRoute(state: NavigationStateModel) {
        return state?.route;
    }
}
