import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import {
    MailReportInterface,
    MailReportOptionsInterface,
    MailSender,
} from './MailSender';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountSelectors } from '../../../stores-modules/account-store/account.selectors';

import * as Sentry from '@sentry/angular';
import { Environment } from '../../environment/environment';
import { genGUID } from '../../../helpers';
import { CoreSelectors } from '../core-store/core.selectors';

@Injectable({
    providedIn: 'root',
})
export class ErrorReportingService {
    private mailSender: MailSender;
    private clientIp: string | null = null;

    constructor(
        private httpClient: HttpClient,
        private env: Environment,
        private store: Store
    ) {
        this.mailSender = new MailSender(
            this.env.reporting.email.url,
            this.env.cookieKey
        );
    }

    // ? Maybe we need this in tracking state + service to get the ip from store.
    public getClientIp(): Observable<string> {
        let identifier: string | null = null;

        try {
            identifier = localStorage.getItem('cl_gen');
        } catch (err) {
            // this.logger.debug(err);
        }

        if (!identifier) {
            try {
                identifier = sessionStorage.getItem('cl_gen');
            } catch (err) {
                // this.logger.debug(err);
            }
        }

        if (!identifier) {
            // clientId don't exist
            identifier = genGUID(); // generate new client id.
        }

        // save the clientId if is posable
        try {
            localStorage.setItem('cl_gen', identifier);
        } catch (error) {
            // this.logger.debug(error);
            try {
                sessionStorage.setItem('cl_gen', identifier);
            } catch (e) {
                // the session storage is disable
                // this.logger.debug(e);
            }
        }

        return this.httpClient
            .get(this.env.reporting.email.url + this.env.reporting.getIpFile)
            .pipe(
                catchError((error) => {
                    return of({});
                }),
                map((response: any) => {
                    if (response && response.client_ip) {
                        return response.client_ip;
                    } else {
                        return identifier;
                    }
                })
            );
    }

    // TODO Move this to tracking state + service.
    private getHotjarId(): string {
        const hj = (window as any).hj;
        let hotjarId = 'N/A';

        try {
            hotjarId = hj.pageVisit.property.get('userId').split('-').shift();
        } catch (e) {
            try {
                hotjarId = hj.globals.get('userId').split('-').shift();
            } catch (e) {
                // hotjarId = 'N/A';
            }
        }
        return hotjarId;
    }

    public async reportToEmailsReporting(error, errorType) {
        // Set report data and send error mail report
        try {
            if (errorType.reportTo.systemapps || errorType.reportTo.dev_team) {
                if (!this.clientIp) {
                    this.clientIp = await this.getClientIp().toPromise();
                }

                const userAccount = this.store.selectSnapshot(
                    AccountSelectors.account
                );

                // get javascript error message and add it to mail subject instead of custom message
                const errMessage = error && error.error && error.error.message ? error.error.message.slice(0, 30) + '...' : '';

                // Set custom project/environemnt string for report data
                const project = this.env.project === 'accounts' ? 'WA' : this.env.project,
                      environment = this.env.type !== 'production' ? this.env.type === 'development' ? ' - DEV' : this.env.type === 'stage' ? ' - STG' : '' : '',
                      errorCodeString = errorType.code ? '| ' + errorType.code : '';

                const reportData: MailReportInterface = {
                    project: `${project}${environment}`,
                    version: this.env.version,
                    clientIp: this.clientIp,
                    domainUrl: window.location.origin,
                    fullDomainUrl: window.location.href,
                    clientId: userAccount?.id || 'N/A',
                    email: userAccount?.username || 'N/A',
                    name: userAccount
                        ? `${userAccount.first_name} ${userAccount.last_name}`
                        : 'N/A',
                    hotjarSessionId: this.getHotjarId(),
                    errors: error,
                };

                // Send mail error report
                // Send mail error report
                if (this.env.reporting.email.enabled) {
                    this.mailSender.send(reportData, {
                        subject: `[${reportData.project}] ${reportData.clientIp} ${errorCodeString} | ${errMessage ? errMessage : errorType.subject}`,
                        emails: errorType.reportTo.systemapps
                            ? ['system']
                            : ['team'],
                    });
                } else {
                    console.error('EMAIL OPTIONS', {
                        subject: `[${reportData.project}] ${reportData.clientIp} ${errorCodeString} | ${errMessage ? errMessage : errorType.subject}`,
                        emails: errorType.reportTo.systemapps
                            ? ['system']
                            : ['team'],
                    });
                    console.error('EMAIL:', reportData);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async reportToSentry(error, errorType) {
        // TODO GET ALL THE DATA FROM OTHER STORES IF IS PRESENT AND FILL THE ERROR EMAIL.

        if (this.env.reporting.sentry.enabled) {
            Sentry.configureScope((scope) => {
                scope.setTag('full-domain-url', window.location.href);
                scope.setTag('current-position', 'N/A');
                scope.setTag('service-name', 'N/A');
                scope.setTag('website-phone', 'N/A');
                scope.setTag('app-version', this.env.version);
                scope.setTag('hotjar-id', this.getHotjarId());

                scope.setExtra('parse-error', true);

                scope.setExtra('response error', error);
                scope.setExtra('booking transaction', 'N/A');

                // Flag the Error with the tags data
                Sentry.captureMessage(errorType.subject);
            });
        }
    }
}
