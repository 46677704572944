import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ServicesState } from './services.state';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forFeature([ServicesState]),
  ],
})
export class ServicesStateModule {}
