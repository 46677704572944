import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {

	sessionStorageEnabled;

    constructor() {
        this.sessionStorageEnabled = this.isEnabled;
    }

    public setItem(key: string, value: any) {
        if (this.sessionStorageEnabled) {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    public getItem(key: string) {
        if (this.sessionStorageEnabled) {
            const item = sessionStorage.getItem(key) || '';
            if (item) {
                return JSON.parse(item);
            }
        }

        return null;
    }

    public removeItem(key: string) {
        if (this.sessionStorageEnabled) {
            sessionStorage.removeItem(key);
        }
    }

    public isEnabled() {
        const key = (Math.random() + 1).toString(36).substring(2);
        try {
            sessionStorage.setItem('test-' + key, key);
            sessionStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    }
}
