import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    ViewEncapsulation,
} from '@angular/core';
import {
    fadeInOnEnterAnimation,
    fadeOutOnLeaveAnimation,
} from 'angular-animations';
import { DynamicFormStateService } from './../dynamic-form-state.service';

@Component({
    selector: 'serviceos-ng-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    providers: [DynamicFormStateService],
    animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()],
    encapsulation: ViewEncapsulation.None,
})
export class FormComponent implements OnInit, OnDestroy {
    @Input()
    choices: any[] = [];

    @Input()
    showLoader = false;

    @Input()
    errorMessage: string | null | undefined = '';

    @Output()
    public onAction = new EventEmitter();

    choiceValid = false;
    currentChoiceIndex = 0;

    private actionSubscription: Subscription;

    constructor(
        private stateService: DynamicFormStateService,
        private cdRef: ChangeDetectorRef
    ) {
        this.actionSubscription = this.stateService.action$
            .pipe(
                tap((action: any) => {
                    if (action.type == 'on_submit') {
                        this.nextChoice();
                    }

                    if (action.type == 'on_cancel') {
                        this.prevChoice();
                    }
                }),
                filter((action: any) => {
                    return action?.deep_link;
                })
            )
            .subscribe((action) => {
                this.onAction.emit(action);
            });
    }

    ngOnDestroy(): void {
        this.actionSubscription.unsubscribe();
    }

    nextChoice() {
        if (this.choices.length > this.currentChoiceIndex + 1) {
            this.choiceValid = false;
            this.currentChoiceIndex += 1;
        }
    }

    prevChoice() {
        if (0 <= this.currentChoiceIndex - 1) {
            this.choiceValid = false;
            this.currentChoiceIndex -= 1;
        }
    }

    onFormAction(action) {
        if (action.type == 'on_submit' && this.choiceValid) {
            this.stateService.emitAction(action);
        } else if (action.type != 'on_submit') {
            this.stateService.emitAction(action);
        }
    }

    onChoiceValueChange() {
        if (this.choices[this.currentChoiceIndex].submit == 'on_change') {
            const action = this.choices[this.currentChoiceIndex].actions.find(
                (action) => action.type == 'on_change'
            );
            if (action) this.stateService.emitAction(action);
        }
        // SHOULD UPDATE ?
    }

    onValid(event) {
        // FIX: Expression has changed after it was checked
        setTimeout(() => {
            this.choiceValid = event;
            this.cdRef.detectChanges();
        });
    }

    ngOnInit(): void {}
}
