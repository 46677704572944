import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { AlertItemComponent } from './alert-item/alert-item.component';
import { RateItemComponent } from './rate-item/rate-item.component';
import { TextFieldItemComponent } from './text-field-item/text-field-item.component';
import { RadioItemComponent } from './radio-item/radio-item.component';
import { CheckboxItemComponent } from './checkbox-item/checkbox-item.component';
import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Choice, ChoiceItem, ChoiceItemValueChange } from '../../../../typings';
import { CHOICE_ITEMS_TYPES } from '../../../../constants';

@Component({
    selector: 'serviceos-ng-choice-item',
    templateUrl: './choice-item.component.html',
    styleUrls: ['./choice-item.component.scss'],
})
export class ChoiceItemComponent implements OnInit {
    @Input()
    public settings!: ChoiceItem;

    @Input()
    public parentSettings!: Choice | ChoiceItem;

    @Output()
    public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

    /**
     * Component container reference for feature use in dynamically build component
     */
    @ViewChild('dynamicChoiceItemContainer', {
        read: ViewContainerRef,
        static: true,
    })
    public dynamicChoiceItemContainer!: ViewContainerRef;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit(): void {
        const widgetComponent = this.generateWidgetComponent(
            this.settings.type,
            this.settings
        );

        this.dynamicChoiceItemContainer.clear();

        const component = this.dynamicChoiceItemContainer.createComponent<any>(
            widgetComponent
        );

        component.instance.settings = this.settings;
        component.instance.parentSettings = this.parentSettings;

        if (component.instance.changeChoiceItemValue) {
            component.instance.changeChoiceItemValue.subscribe((event) => {
                this.changeChoiceItemValue.emit(event);
            });
        }
    }

    /**
     * Generate widget component by given type
     * @param {string} type
     */
    private generateWidgetComponent(type, item) {
        let widgetComponent;

        type = this.returnTrueType(type, item);

        // console.log(type);
        switch (type) {
            case CHOICE_ITEMS_TYPES.Checkbox:
                widgetComponent = this.componentFactoryResolver.resolveComponentFactory(
                    CheckboxItemComponent
                );
                break;
            case CHOICE_ITEMS_TYPES.Radio:
                widgetComponent = this.componentFactoryResolver.resolveComponentFactory(
                    RadioItemComponent
                );
                break;
            case CHOICE_ITEMS_TYPES.TextField:
                widgetComponent = this.componentFactoryResolver.resolveComponentFactory(
                    TextFieldItemComponent
                );
                break;
            case CHOICE_ITEMS_TYPES.Rate:
                widgetComponent = this.componentFactoryResolver.resolveComponentFactory(
                    RateItemComponent
                );
                break;
            case CHOICE_ITEMS_TYPES.Alert:
                widgetComponent = this.componentFactoryResolver.resolveComponentFactory(
                    AlertItemComponent
                );
                break;
            case CHOICE_ITEMS_TYPES.Dropdown:
                widgetComponent = this.componentFactoryResolver.resolveComponentFactory(
                    DropdownItemComponent
                );
                break;
            // default: widgetComponent = this.componentFactoryResolver.resolveComponentFactory(ButtonComponent);
        }
        return widgetComponent;
    }

    /**
     * Return modify type base on customize.
     * @param type ChoiceItemType
     * @param item Item
     * @returns
     */
    returnTrueType(type, item) {
        return type;
    }
}
