<div
    *ngIf="visible"
    [@slideInRightOnEnter]
    [@slideOutRightOnLeave]
    class="sidebar-modal"
    [class]="config.class"
>
    <div class="options" *ngIf="config.close">
        <serviceos-ng-system-icon
            (click)="close()"
            class="close"
            [icon]="'close-cancel'"
        ></serviceos-ng-system-icon>
    </div>
    <div class="content">
        <div>
            <ng-template #componentContainer> </ng-template>
        </div>
    </div>
</div>
