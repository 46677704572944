import { Injectable } from '@angular/core';
import { NOTIFICATIONS_STATE_TOKEN } from './notifications.const';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { NotificationsActions } from './notifications.actions';
import { NotificationsService } from './notifications.service';
import { UserNotification } from '../../../typings/notification';
import { map, switchMap, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { AuthorizationSelectors } from '../../authorization-store/authorization.selectors';

export class NotificationsStateModel {
    notifications: NotificationModel[] = [];
}

export class NotificationModel implements UserNotification {
    constructor(
        public id: number | string,
        public closed: boolean,
        public message: string,
        public messageType: string,
        public url: string,
        public urlParams: {
            action?: string
        },
        public button: string,
        public isNormalBooking: boolean | number,
        public showRebookButton: boolean | number,
        public messageTwo?: string,
        public objectId?: number,
        public referenceNumber?: string,
        public serviceTitle?: string,
        public serviceId?: number
    ) {}
}

@State<NotificationsStateModel>({
    name: NOTIFICATIONS_STATE_TOKEN,
    defaults: new NotificationsStateModel(),
})
@Injectable()
export class NotificationsState implements NgxsOnInit {
    private intervalUpdate: Subscription | null = null;
    constructor(
        private notificationsService: NotificationsService,
        public store: Store
    ) {}

    ngxsOnInit(ctx?: StateContext<any> | undefined) {
        // Functionality to refetch bookings.
        // this.store
        //     .select(AuthorizationSelectors.isAuthorize)
        //     .subscribe((isAuthorize) => {
        //         if (isAuthorize && !this.intervalUpdate) {
        //             this.intervalUpdate = interval(1000 * 60 * 30).subscribe(
        //                 (intervalPass) => {
        //                     ctx?.dispatch(new NotificationsActions.GetAll());
        //                 }
        //             );

        //             ctx?.dispatch(new NotificationsActions.GetAll());
        //         } else {
        //             this.intervalUpdate?.unsubscribe();
        //             this.intervalUpdate = null;
        //         }
        //     });
    }

    @Action(NotificationsActions.GetAll)
    fetchAll({
        patchState,
        getState,
        dispatch,
    }: StateContext<NotificationsStateModel>) {
        return this.notificationsService.getAll().pipe(
            tap(
                (response) => {
                    const notifications: UserNotification[] = response;

                    patchState({ notifications });
                },
                (error) => {
                    patchState({ notifications: [] });
                }
            )
        );
        // [FEATURE FIX] This is temporary implementation.
    }

    @Action(NotificationsActions.Remove)
    remove(
        { setState, patchState, getState }: StateContext<NotificationsStateModel>,
        payload: NotificationsActions.Remove
    ) {
        // use notification id as unique identifier
        const state = getState();

        // const filteredNotifications = state?.notifications?.filter(
        //     (notification) =>
        //         notification.id !== payload.notification.id
        // );

        const filteredNotifications = state?.notifications?.map(
            (notification) => {

                if (notification.id === payload.notification.id) {
                    notification.closed = true;
                }

                return notification;
            }
        );

        return patchState({
            notifications: filteredNotifications,
        });
    }

    @Action(NotificationsActions.Reset)
    reset() {}
}
