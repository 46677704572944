import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDirective } from './input.directive';
import { FormFieldComponent } from './form-field/form-field.component';
import { LabelComponent } from './label/label.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { DescriptionComponent } from './description/description.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent, RadioGroup } from './radio/radio.component';

// TODO Optimize the input directive remove label and error from directive and create correct items to add inside form-field

@NgModule({
    declarations: [
        InputDirective,
        FormFieldComponent,
        LabelComponent,
        ErrorMessageComponent,
        DescriptionComponent,
        SlideToggleComponent,
        CheckboxComponent,
        RadioComponent,
        RadioGroup,
    ],
    imports: [CommonModule],
    exports: [
        InputDirective,
        FormFieldComponent,
        LabelComponent,
        ErrorMessageComponent,
        DescriptionComponent,
        SlideToggleComponent,
        CheckboxComponent,
        RadioComponent,
        RadioGroup
    ],
})
export class InputModule {}
