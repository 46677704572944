import { Environment } from '@serviceos-ng/core';
import PackageJson from '../../../../package.json';

export const environment: Environment = {
  production: true,
  type: 'production',
  project: 'accounts',
  cookieKey: 'LMrofhHejiTWJeRYuVI6wV4cPmcb7H7u1nQg1M3UW8g',
  version: PackageJson.version + '.' + PackageJson.build,
  googleApiKey: 'AIzaSyCNN907cEUcIScGXC6w45IeNix1IYw711o',
  deployURL: 'accounts',
  reporting: {
    getIpFile: 'get-ip.php',
    email: {
      enabled: true,
      url: 'https://web.serviceos.app/reports/'
    },
    sentry: {
      enabled: true, 
      dsn: 'https://b29b667c00d544aca8770c54460966be@sentry.serviceos.app/3',
    }
  }
};