export function phoneParser(
    phone: string,
    code: string
): { phone: string; phone_formatted: string } {
    let replaceString: RegExp | string = code;
    try {
        replaceString = new RegExp(`(\\${code})+\\s*`);
    } catch (error) {}

    return {
        phone: phone,
        phone_formatted: phone.replace(replaceString, '0'),
    };
}
