<!-- Separate templates for different choice_item types -->
<div [ngSwitch]="type">
    <!-- Text field -->
    <div *ngSwitchCase="'text'" class="row">
        <serviceos-ng-form-field class="col-12">
            <input [(ngModel)]="settings.value" spellcheck="false" autocapitalize="off" autocomplete="off"
                autocorrect="off" (focus)="focusIn(textField)" (blur)="focusOut(textField)"
                [serviceosNgInput]="settings.value" #textField [errorMessage]="''"
                type="text" name="text" (ngModelChange)="onChangeValue($event)" [placeholder]="
                    settings?.customize?.obfTextAreaPlaceholder
                    ? settings?.customize?.obfTextAreaPlaceholder
                    : ''
                    " />
            <serviceos-ng-label>{{ settings?.title }}</serviceos-ng-label>
            <!-- <serviceos-ng-error-message *ngIf="error message condition">{{ error msg here }}</serviceos-ng-error-message> -->
        </serviceos-ng-form-field>
    </div>
    <!-- Decimal field -->
    <div *ngSwitchCase="'decimal'" class="row">
        <serviceos-ng-form-field class="col-12">

            <input [(ngModel)]="settings.value" spellcheck="false" autocapitalize="off" autocomplete="off"
                autocorrect="off" (focus)="focusIn(textField)" (blur)="focusOut(textField)"
                [serviceosNgInput]="settings.value" [specialFieldType]="'decimal'"
                (keyup)="onChangeValue(textField.value)" #textField [labelText]="settings?.title ? settings?.title : ''"
                [errorMessage]="''" type="text" name="number" (ngModelChange)="onChangeValue($event)" [placeholder]="
                        settings?.customize?.obfTextAreaPlaceholder
                            ? settings?.customize?.obfTextAreaPlaceholder
                            : ''
                    " />
        </serviceos-ng-form-field>

    </div>
    <!-- Textarea field -->
    <div *ngSwitchCase="'textarea'" class="sos-textfield-box">
        <ng-container *ngIf="settings?.customize?.expandable">
            <div class="text-field-expandable">
                <span class="text-field-expandable-cta pointer" (click)="expandedTextarea = !expandedTextarea">
                    <serviceos-ng-system-icon *ngIf="!expandedTextarea" class="sos-icon" [icon]="'plus'">
                    </serviceos-ng-system-icon>
                    <serviceos-ng-system-icon *ngIf="expandedTextarea" class="sos-icon" [icon]="'minus'">
                    </serviceos-ng-system-icon>
                    <span class="text-field-expandable-cta-text">
                        {{settings?.customize?.expand_cta_text}}
                    </span>
                </span>
            </div>
        </ng-container>
        <serviceos-ng-form-field class="col-12" *ngIf="settings.show_comment || settings.required">
            <textarea [(ngModel)]="settings.value" spellcheck="false"
                [ngClass]="{'expandable': settings?.customize?.expandable, 'expanded': expandedTextarea}"
                [serviceosNgInput]="settings.value"
                autocapitalize="off" autocomplete="off" autocorrect="off" rows="4" type="text" FsFocusHandler
                name="textarea" #textField (focus)="focusIn(textField)" (blur)="focusOut(textField)" [placeholder]="settings?.customize?.obfTextAreaPlaceholder || settings.placeholder || ''
                " (ngModelChange)="onChangeValue($event)"></textarea>
        </serviceos-ng-form-field>
    </div>
    <!-- Password field -->
    <div *ngSwitchCase="'password'" class="sos-textfield-box">
        <input [(ngModel)]="textField.value" FsFocusHandler type="password" name="password" #textField
            (focus)="focusIn(textField)" (blur)="focusOut(textField)" [placeholder]="settings.placeholder"
            (ngModelChange)="onChangeValue($event)" value="{{ settings.value }}" />
    </div>
    <!-- Datepicker field -->
    <div *ngSwitchCase="'date-picker'" class="row">
        <serviceos-ng-form-field class="col-12 col-lg-7" (serviceosNgClickOutSide)="focusOut(datePicker)">
            <div class="sos-date-picker" (click)="focusIn()">
                <input [(ngModel)]="settings.value" readonly="true" spellcheck="false" autocapitalize="off"
                    autocomplete="off" autocorrect="off" class="sos-date-picker-input" type="text" name="datePicker"
                    #datePicker="ngModel" #datePickerElement [serviceosNgInput]="settings.value"
                    [labelText]="settings?.title" [placeholder]="
                    settings?.customize?.obfTextAreaPlaceholder
                    ? settings?.customize?.obfTextAreaPlaceholder
                    : ''
                    " [errorMessage]="'Invalid date format'" [error]="datePicker.errors?.minlength && !showDataPicker"
                    (keyup)="onChangeValue(datePickerElement.value)" [specialFieldType]="'date-picker'" minlength="5"
                    (ngModelChange)="onChangeValue(datePickerElement.value)" />
                <span class="sos-date-selector" (click)="focusIn()" [ngClass]="{ active: showDataPicker }">
                    <serviceos-ng-system-icon class="sos-icon" [icon]="'calendar1'"></serviceos-ng-system-icon>
                </span>
            </div>
            <ngb-datepicker *ngIf="showDataPicker" navigation="arrows" (select)="selectedDate($event)"
                [minDate]="datePickerMinDate" [startDate]="startDate"></ngb-datepicker>
        </serviceos-ng-form-field>
    </div>
</div>