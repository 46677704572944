<span class="sos-item-title">
    <span class="item-title-cell">{{ settings?.title }}</span>
</span>
<div class="rate-unit" *ngIf="settings?.customize?.unit_img">
    <div class="unit-img"><img [src]="settings.customize.unit_img" alt=""></div>
    <span class="unit-text">{{settings.customize.unit_text}}</span>
</div>
<div class="rate">
    <span *ngFor="let item of [1,2,3,4,5]" (click)="onClicked(item)" (mouseenter)="onHover(item)"
        (mouseleave)="onHover(0)" [ngClass]="{'fill': (item <= settings.value), 'hover': item <= hover}">
        <serviceos-ng-system-icon [icon]="'rating'">

        </serviceos-ng-system-icon>
    </span>
</div>