import { createSelector, Selector } from '@ngxs/store';
import { ValidationsState, ValidationsStateModel } from './validations.state';

export class ValidationsSelectors
 {
    static getPatterns() {
        return createSelector(
            [ValidationsState],
            (state: ValidationsStateModel) => {
                return state?.validationPatterns
            }
        );
    }
    static getPattern(searchPath: string, searchPattern: string) {
        return createSelector(
            [ValidationsState],
            (state: ValidationsStateModel) => {
                if (state?.validationPatterns) {
                    let regex = '',
                        newPattern = '',
                        flags = '';

                    const validation = state.validationPatterns.find((group) => group.path === searchPath);

                    if (validation) {
                        const pattern = validation.fields.find((pattern) => pattern.name === searchPattern);

                        if (pattern?.regex) {
                            regex = pattern.regex;
            
                            flags = regex.replace(/.*\/([gimy]*)$/, '$1');
            
                            if (flags) {
                                newPattern = regex.replace(
                                    new RegExp('^/(.*?)/' + flags + '$'),
                                    '$1'
                                );
                            } else {
                                newPattern = regex.replace(new RegExp('^/(.*?)/$'), '$1');
                            }
            
                            return new RegExp(newPattern, flags);
                        }
                    } else {
                        return new RegExp('^[a-zA-Z0-9\s,-]{2,}');
                    }
                }

                return new RegExp('^[a-zA-Z0-9\s,-]{2,}');
            }
        );
    }
}
