import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import {
    formatDate,
    formatYearAndMonthByLocale,
} from '../../../helpers/date-formatter';
import { Store } from '@ngxs/store';
import { ModulesSelectors } from '../../../common/stores/core-store/modules/modules.selectors';
import { LocalizationSelectors } from '../../../common/stores/core-store/localization/localization.selectors';


@Component({
    selector: 'serviceos-ng-upcoming-sessions',
    templateUrl: './upcoming-sessions.component.html',
    styleUrls: ['./upcoming-sessions.component.scss'],
})
export class UpcomingSessionsComponent implements OnInit {
    @Input()
    bookingSessions;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output()
    onSelectSession = new EventEmitter();

    @Input()
    TextOptions = {
        selectingSessionLink: 'Manage',
    };

    sessions: any[] = [];
    public applicationConfig;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.applicationConfig = this.store.selectSnapshot(
            ModulesSelectors.getModuleByName('application')
        );
        this.generateBookingSessions(this.bookingSessions);
    }

    selectedSession(sessionId) {
        this.onSelectSession.emit(sessionId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.bookingSessions?.currentValue) {
            this.generateBookingSessions(this.bookingSessions);
        }
    }

    private generateBookingSessions(sessions: any[] | null) {
        if (sessions?.length) {

            const currentLanguage = this.store.selectSnapshot(LocalizationSelectors.getSelectedLanguage());

            this.sessions = sessions.reduce(
                (currentFilteredArray: any[], currentSession) => {
                    //  UTC locale
                    currentSession = {
                        ...currentSession,
                        sessionFullDate: formatDate(
                            currentSession.bookDate,
                            'ddd, MMM DD',
                            currentLanguage
                        ),
                    };

                    const indexOfMontInCFA = currentFilteredArray?.findIndex(
                        (el) => {
                            return (
                                el.monthY ===
                                formatYearAndMonthByLocale(
                                    currentSession.bookDate,
                                    this.applicationConfig?.settings?.locale
                                )
                            );
                        }
                    );

                    if (indexOfMontInCFA != -1) {
                        currentFilteredArray[indexOfMontInCFA].sessions.push(
                            currentSession
                        );
                    } else {
                        currentFilteredArray.push({
                            monthY: formatYearAndMonthByLocale(
                                currentSession.bookDate,
                                this.applicationConfig?.settings?.locale
                            ),
                            sessions: [currentSession],
                        });
                    }

                    return currentFilteredArray;
                },
                []
            );
        }
    }
}
