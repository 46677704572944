import { DeviceDetectorService } from 'ngx-device-detector';

/**
 *
 * @param selector element selector to scroll to
 */
export function scrollToItem(selector: string): void {
    const el = document.querySelector(selector);

    if (el) {
        const elementOffsetTop: number = el?.getBoundingClientRect().top,
            windowScrollY: number = window.scrollY,
            headerElement: HTMLElement | null = document.querySelector('header'),
            headerHeight: number = headerElement ? headerElement.offsetHeight : 0;

        window.scrollTo({
            top: elementOffsetTop + windowScrollY - headerHeight - 20,
            left: 0,
            behavior: 'smooth',
        });
    }

    // el.scrollIntoView({ behavior: "smooth" });

    // const mainContent: any = window.document.querySelector('#serviceos-dynamic-theme-wrap');

    // console.warn('mainContent', mainContent);

    // if (mainContent && typeof mainContent.scrollTo === 'function') {

    //     if (el) {
    //         const scrollToPix = el.getBoundingClientRect().top + mainContent.scrollTop - 40;

    //         setTimeout(() => {
    //             mainContent.scrollTo({
    //                 top: scrollToPix,
    //             });
    //         }, 300);
    //     }
    // }
}

// export function scrollToItem(deviceDetectorServiceInstance: DeviceDetectorService): void {
//     if (!deviceDetectorServiceInstance.isMobile() || this.el.nativeElement.classList.contains('date-picker-input')) {
//         return;
//     }

//     if (deviceDetectorServiceInstance.getDeviceInfo().os === 'Android' && deviceDetectorServiceInstance.getDeviceInfo().browser === 'Samsung') {
//         scrollToItemAdditional();
//     } else {
//         scrollToItem();
//     }
// }

// export function scrollToItemAdditional(): void {
//     const mainContent: any = window.document.querySelector(this.inputScrollParent);

//     if (mainContent && typeof mainContent.scrollTo === 'function') {

//         const scrollToPix = this.el.nativeElement.getBoundingClientRect().top + mainContent.scrollTop - 40;

//         setTimeout(() => {
//             mainContent.scrollTo({
//                 top: scrollToPix,
//             });
//         }, 300);

//     }
// }

// /**
//      * Scroll to item
//      */
//  export function scroll(): void {

//     const mainContent: any = window.document.querySelector(this.inputScrollParent);

//     if (mainContent) {

//         if (this.mainNavigation !== '') {
//             this.elementNavigation = window.document.querySelector(this.mainNavigation);
//         }

//         if (this.elementNavigation && this.deviceDetectorService.getDeviceInfo().os === 'iOS') {
//             this.elementNavigation.classList.add('refresh');
//         }

//         // setTimeout(() => {
//         const datePickerElement: any = window.document.querySelector('.ngb-datepicker');
//         let scrollToPix = this.el.nativeElement.getBoundingClientRect().top + mainContent.scrollTop - 40;

//         if (window.innerHeight / 2 < scrollToPix + this.el.nativeElement.offsetHeight + this.dropdownElementMaxHeight) {
//                 if (this.inputScrollParent === '.obf-main-content') {
//                     const pageContent: any = window.document.querySelector('.obf-page-content');
//                     let addBonusPix = window.innerHeight - pageContent.offsetHeight > 0 ? window.innerHeight - pageContent.offsetHeight : 0;

//                     // Reduce bonus pixels with calendar's height when current field is date-picker
//                     if (datePickerElement) {
//                         const datePickerHeight: number = datePickerElement.offsetHeight;

//                         addBonusPix -= datePickerHeight;
//                     }

//                     pageContent.style.paddingBottom = addBonusPix + this.el.nativeElement.offsetHeight + this.dropdownElementMaxHeight + 125 + 'px';
//                 } else {
//                     let reducePixels = 0;

//                     // Reduce bonus pixels with calendar's height when current field is date-picker
//                     if (datePickerElement) {
//                         const datePickerHeight: number = datePickerElement.offsetHeight;

//                         reducePixels -= datePickerHeight;
//                     }

//                     mainContent.style.paddingBottom = this.el.nativeElement.offsetHeight + this.dropdownElementMaxHeight + 125 + reducePixels +  'px';
//                 }
//             }

//         if (this.deviceDetectorService.getDeviceInfo().os === 'iOS') {

//                 // fake scroll coming from iOS mobiles
//                 const fakeScroll = Math.round(this.el.nativeElement.getBoundingClientRect().top - ((window.innerHeight / 2) - this.el.nativeElement.offsetHeight) / 2);

//                 // let textarea = document.getElementsByClassName('obf-header-section')[0];

//                 // if (textarea) {

//                 //     textarea.innerHTML += '[' + this.el.nativeElement.getBoundingClientRect().top + ' - ' + mainContent.scrollTop + '] ';
//                 // }

//                 if (fakeScroll > 0) {
//                     scrollToPix = scrollToPix - fakeScroll;
//                 }

//                 if (this.el.nativeElement.getBoundingClientRect().top != 0 && mainContent && typeof mainContent.scrollTo === 'function') {
//                     setTimeout(() => {
//                         mainContent.scrollTo({
//                             top: scrollToPix,
//                             behavior: 'smooth',
//                         });
//                     }, 10);
//                 }

//             } else {

//                 // IE doesn't support scrollTo
//                 if (this.deviceDetectorService.getDeviceInfo().browser !== 'IE' && this.deviceDetectorService.getDeviceInfo().browser !== 'EDGE') {

//                     if (mainContent && typeof mainContent.scrollTo === 'function') {

//                         // setTimeout(() => {
//                             mainContent.scrollTo({
//                                 top: scrollToPix,
//                                 behavior: 'smooth',
//                             });
//                         // }, 600);
//                     }

//                 }
//             }
//         // }, 520);
//     }
// }
