//! moment.js locale configuration
//! locale : Hungarian [hu]
//! author : Adam Brunner : https://github.com/adambrunner
//! author : Peter Viszt  : https://github.com/passatgt

;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined'
        && typeof require === 'function' ? factory(require('../../../../../../node_modules/moment-mini/moment.min')) :
    typeof define === 'function' && define.amd ? define(['../../../../../../node_modules/moment-mini/moment.min'], factory) :
    factory(global.moment)
 }(this, (function (moment) { 'use strict';
 
     //! moment.js locale configuration
 
     var weekEndings =
         'vasárnap hétfőn kedden szerdán csütörtökön pénteken szombaton'.split(' ');
     function translate(number, withoutSuffix, key, isFuture) {
         var num = number;
         switch (key) {
             case 's':
                 return isFuture || withoutSuffix
                     ? 'néhány másodperc'
                     : 'néhány másodperce';
             case 'ss':
                 return num + (isFuture || withoutSuffix)
                     ? ' másodperc'
                     : ' másodperce';
             case 'm':
                 return 'egy' + (isFuture || withoutSuffix ? ' perc' : ' perce');
             case 'mm':
                 return num + (isFuture || withoutSuffix ? ' perc' : ' perce');
             case 'h':
                 return 'egy' + (isFuture || withoutSuffix ? ' óra' : ' órája');
             case 'hh':
                 return num + (isFuture || withoutSuffix ? ' óra' : ' órája');
             case 'd':
                 return 'egy' + (isFuture || withoutSuffix ? ' nap' : ' napja');
             case 'dd':
                 return num + (isFuture || withoutSuffix ? ' nap' : ' napja');
             case 'M':
                 return 'egy' + (isFuture || withoutSuffix ? ' hónap' : ' hónapja');
             case 'MM':
                 return num + (isFuture || withoutSuffix ? ' hónap' : ' hónapja');
             case 'y':
                 return 'egy' + (isFuture || withoutSuffix ? ' év' : ' éve');
             case 'yy':
                 return num + (isFuture || withoutSuffix ? ' év' : ' éve');
         }
         return '';
     }
     function week(isFuture) {
         return (
             (isFuture ? '' : '[múlt] ') +
             '[' +
             weekEndings[this.day()] +
             '] LT[-kor]'
         );
     }
 
     var hu = moment.defineLocale('hu', {
         months: 'január_február_március_április_május_június_július_augusztus_szeptember_október_november_december'.split(
             '_'
         ),
         monthsShort:
             'jan._feb._márc._ápr._máj._jún._júl._aug._szept._okt._nov._dec.'.split(
                 '_'
             ),
         monthsParseExact: true,
         weekdays: 'vasárnap_hétfő_kedd_szerda_csütörtök_péntek_szombat'.split('_'),
         weekdaysShort: 'vas_hét_kedd_sze_csüt_pén_szo'.split('_'),
         weekdaysMin: 'v_h_k_sze_cs_p_szo'.split('_'),
         longDateFormat: {
             LT: 'H:mm',
             LTS: 'H:mm:ss',
             L: 'YYYY.MM.DD.',
             LL: 'YYYY. MMMM D.',
             LLL: 'YYYY. MMMM D. H:mm',
             LLLL: 'YYYY. MMMM D., dddd H:mm',
         },
         meridiemParse: /de|du/i,
         isPM: function (input) {
             return input.charAt(1).toLowerCase() === 'u';
         },
         meridiem: function (hours, minutes, isLower) {
             if (hours < 12) {
                 return isLower === true ? 'de' : 'DE';
             } else {
                 return isLower === true ? 'du' : 'DU';
             }
         },
         calendar: {
             sameDay: '[ma] LT[-kor]',
             nextDay: '[holnap] LT[-kor]',
             nextWeek: function () {
                 return week.call(this, true);
             },
             lastDay: '[tegnap] LT[-kor]',
             lastWeek: function () {
                 return week.call(this, false);
             },
             sameElse: 'L',
         },
         relativeTime: {
             future: '%s múlva',
             past: '%s',
             s: translate,
             ss: translate,
             m: translate,
             mm: translate,
             h: translate,
             hh: translate,
             d: translate,
             dd: translate,
             M: translate,
             MM: translate,
             y: translate,
             yy: translate,
         },
         dayOfMonthOrdinalParse: /\d{1,2}\./,
         ordinal: '%d.',
         week: {
             dow: 1, // Monday is the first day of the week.
             doy: 4, // The week that contains Jan 4th is the first week of the year.
         },
     });
 
     return hu;
 
 })));
