export namespace ClubActions {
    export class FetchBenefits {
        static readonly type = `[MEMBERSHIP] Get Benefits`;
        constructor() {}
    }

    export class FetchCancelReasons {
        static readonly type = `[MEMBERSHIP] Cancel Reasons`;
        constructor() {}
    }

    export class ResetCancelReasons {
        static readonly type = `[MEMBERSHIP] Reset Cancel Reasons`;
        constructor() {}
    }

    export class MembershipCancel {
        static readonly type = `[MEMBERSHIP] MEMBERSHIP Cancel`;
        constructor(
            public cancel: {
                cancel_membership_reason_id: number;
                comment: string;
            }
        ) {}
    }

    export class ClaimDeal {
        static readonly type = `[MEMBERSHIP] Claim Deal`;
        constructor(public departmentId: number, public priorityId : number) {}
    }
}
