

export namespace NotificationsActions {
    export class GetAll {
        static readonly type = '[NOTIFICATIONS] Get';
        constructor() {
        }
    }
    export class Remove {
        static readonly type = '[NOTIFICATIONS] Remove';
        constructor(public notification: any) {
        }
    }
    export class Reset {
        static readonly type = '[NOTIFICATIONS] Reset'
    }
}
