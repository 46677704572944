import moment from 'moment-mini';

declare let require: any;

/**
 * Init date to utc
 * @param date
 * @returns
 */
export function initialFormat(date?: string | number, language?) {
    
    const currentLanguage = language ? language : 'en';

    if (currentLanguage != 'en') {
        // remove require moment-locales files when publish submodules only
        require(`./moment-locales/${currentLanguage}`);
    }

	if (currentLanguage != 'en') {
		moment.locale(currentLanguage);
	}

    if (typeof date === 'string' && date.length) {
        date = new Date(date).getTime();
        return moment.utc(date);
    } else if (typeof date === 'number') {
        return moment.unix(date).utc();
    } else {
        return moment.utc();
    }
}

/**
 * Format date as a string in passed format
 * @param date
 * @param format
 * @returns
 */
export function formatDate(
    date: string | number,
    format: string | undefined,
    language?
): string {
    return initialFormat(date, language).format(format);
}

/**
 * Return range between two dates in minutes
 * @param startDate
 * @param endDate
 * @returns
 */
export function getTwoDatesRange(startDate, endDate) {
    try {
        startDate = moment(startDate);
        endDate = moment(endDate);
        const duration = moment.duration(startDate.diff(endDate));
        const mins = duration.asMinutes();

        return Math.floor(Math.abs(mins));

    } catch (err) {
        return null;
    }
    
}

/**
 * Format date as a string in passed format
 * @param date unixTimestamp
 * @param locale 'en-US'
 * @returns
 */
export function formatYearAndMonthByLocale(
    date: number,
    locale: string
): string {
    if (!locale) return '';

    let dateTimeFormat;

    try {
        dateTimeFormat = new Intl.DateTimeFormat(locale.replace('_', '-'), {
            year: 'numeric',
            month: 'long',
        });
    } catch (e) {
        return '';
    }

    const dateMapingParts = new Map();
    const unixTimestamp = date;
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);

    const parts = dateTimeFormat.formatToParts(dateObject);
    parts.map((p) =>
        p.value.trim().length !== 0
            ? dateMapingParts.set(p.type, p.value)
            : null
    );

    return dateMapingParts.get('month') + ' ' + dateMapingParts.get('year');
}

/**
 * Returns wether the date is before current
 * @param date
 * @returns
 */
export function isInPast(date: string | number): boolean {
    return initialFormat(date).isBefore(moment().utc());
}

/**
 * Returns wether the date is after current
 * @param date
 * @returns
 */
export function isInFuture(date: string | number): boolean {
    return initialFormat(date).isAfter(moment().utc());
}

/**
 * Get year of full date
 * @param date
 * @returns
 */
export function getFullYear(date?: string | number) {
    return initialFormat(date).year();
}

/**
 * Get month by index
 * @param monthIndex
 * @returns
 */
export function getMonth(monthIndex, language?) {
    return initialFormat('', language).month(monthIndex).format('MMMM');
}

/**
 * Get range for past bookings filter // FIX - this is not CORE functionality
 * @param month
 * @param year
 * @returns
 */
export function getBookingsFilterRange(month, year) {
    let filterYear = year ? year : initialFormat().year(),
        filterMonth = month ? month : 0, // set to january if month is not present
        rangeFullYear = month ? false : true, // set flag for full year range if month is not present
        pureStartDate = moment([filterYear, filterMonth]),
        lastMonthPureStartDate = moment([filterYear, 11]), // get first day of last month of year for start date
        startDate = moment([filterYear, filterMonth]).utc().unix(),
        endDate = moment(rangeFullYear ? lastMonthPureStartDate : pureStartDate)
            .endOf('month')
            .utc()
            .unix(); // if month is not present endDate will be end of year

    if (!year && !month) {
        startDate = 0;
        endDate = 0;
    }
    let range = {
        fromDate: startDate,
        toDate: endDate,
    };

    return range;
}
