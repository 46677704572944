import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapseViewComponent } from './collapse-view/collapse-view.component';

@NgModule({
  declarations: [CollapseViewComponent],
  imports: [CommonModule, NgbCollapseModule],
  exports: [CollapseViewComponent],
})
export class CollapseViewModule {}
