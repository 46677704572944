export interface ModalButtons {
    title: string;
    type: string;
}

export class DefaultModalConfig {
  
    constructor(
        private title: string,
        private message: string,
        private buttons: ModalButtons[],
        private theme: 'error' | 'success' | 'info' | 'warning'
    ) {}
  }