import { SystemIconModule } from './../shared/base/system-icon/system-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpcomingSessionsComponent } from './upcoming-sessions/upcoming-sessions.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LinkModule } from '../shared/base/link/link.module';


@NgModule({
  declarations: [
    UpcomingSessionsComponent
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    LinkModule,
    SystemIconModule
  ],
  exports: [
    UpcomingSessionsComponent
  ]
})
export class UpcomingSessionsModule { }
