import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'serviceos-ng-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss'],
})
export class UnitComponent implements OnInit {
  public unitType: 'unit' | 'team' = 'unit';

  @Input() 
  showLoader = false; 

  public data;

  @Output()
  onToggleFavorite = new EventEmitter();

  @Input()
  public texts = {
    team_type: 'team',
    back_team_cta: 'Back to team',

    profile_label_experience: 'Experience',
    profile_label_total_bookings: 'Total Bookings:',
    profile_label_languages: 'Languages',

    tab_title_badges: 'Badges',
    tab_title_rating: 'Rating',
    tab_title_documents: 'Documents',
    tab_title_biography: 'Bio',
    tab_title_professionals: 'PROs',

    documents_view_document_cta: 'View',
    rating_level_1: 'veryPoor',
    rating_level_2: 'poor',
    rating_level_3: 'okay',
    rating_level_4: 'good',
    rating_level_5: 'superb',
    rating_reviews_txt: 'reviews',
    rating_from_txt: 'from',
    professionals_view_pro_cta: 'View',
    documents_view_cta: 'View',
  };

  @Input() set unit(value) {
    if (value.units) {
      this.unitType = 'team';
    }

    this.data = value;
  }

  constructor() {}

  ngOnInit(): void {}

  toggleFavorite(unit) {
    this.onToggleFavorite.emit(unit);
  }
}
