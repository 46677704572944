import { ErrorCodeType, ERROR_CODES } from './error.const';

import { StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { Action } from '@ngxs/store';
import { Injectable, Injector } from '@angular/core';
import { State } from '@ngxs/store';
import { ErrorActions } from './error.actions';
import { ErrorReportingService } from './error-reporting.service';

import * as Sentry from '@sentry/angular';
import { convertErrorToObject, refCopy } from '../../../helpers';

export interface ErrorStateModel {
    errorBody: any | null;
    errorPayload: any | null;
    errorType: ErrorCodeType | null;
    historyErrors: any[];
}

@State<ErrorStateModel>({
    name: 'ERROR',
    defaults: {
        errorBody: null,
        errorPayload: null,
        errorType: null,
        historyErrors: [],
    },
})
@Injectable()
export class ErrorState implements NgxsOnInit {
    private errorCount = 0;

    constructor(
        private errorReportingService: ErrorReportingService,
        private store: Store,
        private injector: Injector
    ) {}

    ngxsOnInit(ctx: StateContext<ErrorState>) {
        this.store.select(ErrorState).subscribe((data: ErrorStateModel) => {
            data = JSON.parse(JSON.stringify(data));
            if (data.errorBody) {
                try {
                    this.errorReportingService.reportToEmailsReporting(
                        data.errorBody,
                        data.errorType
                    );
                } catch (err) {
                    Sentry.captureException(err);
                }

                try {
                    this.errorReportingService.reportToSentry(
                        data.errorBody,
                        data.errorType
                    );
                } catch (err) {
                    Sentry.captureException(err);
                }
            }
        });
    }

    @Action(ErrorActions.Api)
    apiErrorHandler(
        ctx: StateContext<ErrorStateModel>,
        payload: ErrorActions.Api
    ) {
        const state = ctx.getState();
        this.errorCount++;

        let error = payload.request;

        let errorBody = refCopy(error) as any;
        errorBody.errorId = this.errorCount;

        let errorType = ERROR_CODES.UNKNOWN_ERROR.Unknown;

        if (error.error && error.error.length) {
            errorType = this.findErrorCodeType(error?.error[0]);
        }

        if (errorType) {
            ctx.patchState({
                errorBody: error,
                errorPayload: payload,
                errorType,
                historyErrors: [...state.historyErrors, error],
            });
        }
    }

    @Action(ErrorActions.Javascript)
    javascriptHandler(
        { patchState, getState }: StateContext<ErrorStateModel>,
        payload: ErrorActions.Javascript
    ) {
        const state = getState();
        this.errorCount++;

        payload.error = convertErrorToObject(payload.error);

        const error: any = { ...payload };
        error.errorId = this.errorCount;

        const errorType = this.findErrorCodeType(error);

        if (errorType) {
            patchState({
                errorBody: error,
                errorPayload: payload,
                errorType,
                historyErrors: [...state.historyErrors, payload],
            });
        }
    }

    @Action(ErrorActions.Custom)
    customHandler(
        { patchState, getState }: StateContext<ErrorStateModel>,
        payload: ErrorActions.Custom
    ) {
        const state = getState();
        this.errorCount++;

        const error = payload.data;
        error.errorId = this.errorCount;

        const errorType = ERROR_CODES.Custom.Default;

        if (errorType) {
            patchState({
                errorBody: error,
                errorPayload: payload,
                errorType,
                historyErrors: [...state.historyErrors, payload],
            });
        }
    }

    @Action(ErrorActions.Localization)
    localizationHandler(
        { patchState, getState }: StateContext<ErrorStateModel>,
        payload: ErrorActions.Localization
    ) {
        const state = getState();
        this.errorCount++;

        const error = payload.error;
        error.errorId = this.errorCount;

        const errorType = this.findErrorCodeType(error);

        if (errorType) {
            patchState({
                errorBody: error,
                errorPayload: payload,
                errorType,
                historyErrors: [...state.historyErrors, payload],
            });
        }
    }

    private findErrorCodeType(error: { code: number; [key: string]: any }) {
        for (const errorGroupKey in ERROR_CODES) {
            for (const errorTypeKey in ERROR_CODES[errorGroupKey]) {
                if (
                    ERROR_CODES[errorGroupKey][errorTypeKey].code == error.code
                ) {
                    if (
                        ERROR_CODES[errorGroupKey][errorTypeKey].reportTo.dev_team ||
                        ERROR_CODES[errorGroupKey][errorTypeKey].reportTo.systemapps
                    ) {
                        return ERROR_CODES[errorGroupKey][errorTypeKey];
                    } else {
                        return null;
                    }
                }
            }
        }

        return ERROR_CODES.UNKNOWN_ERROR.Unknown;
    }
}
