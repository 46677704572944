// TODO Question should this be normal class with default config and we only modify some of the properties.
export abstract class Environment {
  abstract readonly type: 'development' | 'production' | 'stage' | 'localhost' | string;
  abstract readonly production: boolean;
  abstract readonly project: string;
  abstract readonly cookieKey: string;
  abstract readonly version: string;
  abstract readonly googleApiKey: string;
  /**
   * This is when we cant use base url for the build. 
   * This will add to base_href the passed deployURL
   */
  abstract readonly deployURL?: string;
  abstract readonly reporting: {
    getIpFile: string;
    email: {
      enabled: boolean;
      url: string;
    };
    sentry: {
      enabled: boolean;
      dsn: string;
    };
  };
}
