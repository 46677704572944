import { Injectable } from '@angular/core';
import { SosApiService } from '../../../common/services/api/api.service';

@Injectable({
    providedIn: 'root',
})
export class TreatsService {
    constructor(private sosApiService: SosApiService) {}

    fetchTreats() {
        return this.sosApiService.get('client/treats');
    }
}
