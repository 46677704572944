import {
    ClaimAccountData,
    isLoginWithTokenData,
    LoginData,
    LoginWithTokenData,
    RegisterData,
    RequestClaimAccountData,
    RequestResetPasswordData,
    ResetPasswordData,
    SocialProvider,
} from './authentication-types';

export namespace AuthorizationActions {
    export class Initialize {
        static readonly type = '[AUTHORIZATION] Initialize';
        constructor() {}
    }
    export class mergeAccount {
        static readonly type = `[AUTHORIZATION] Merge Account`;
        constructor(public token: string) {}
    }

    export class Logout {
        static readonly type = '[AUTHORIZATION] Logout';
    }

    export class RequestResetPassword {
        static readonly type = '[AUTHORIZATION] Request Reset Password';
        constructor(public data: RequestResetPasswordData) {}
    }

    export class ResetPassword {
        static readonly type = '[AUTHORIZATION] Reset Password';
        constructor(public data: ResetPasswordData) {}
    }

    export class RequestClaimAccount {
        static readonly type = '[AUTHORIZATION] Request Claim Account';
        constructor(public data: RequestClaimAccountData) {}
    }

    export class ClaimAccount {
        static readonly type = '[AUTHORIZATION] Claim Account';
        constructor(public data: ClaimAccountData) {}
    }

    export class LoginWithToken {
        static readonly type = '[AUTHORIZATION] Login With Token';
        constructor(
            public data: LoginWithTokenData,
            public keepSingIn: boolean = false
        ) {}
    }

    export class SocialSingIn {
        static readonly type = '[AUTHORIZATION] Social Sing In';
        constructor(
            public social: SocialProvider,
            // public keepSingIn: boolean = false
        ) {}
    }

    export class Login {
        static readonly type = '[AUTHORIZATION] Login';
        constructor(
            public data: any, //LoginData,
            public keepSingIn: boolean = false
        ) {}
    }

    export class Register {
        static readonly type = '[AUTHORIZATION] Register';
        constructor(public data: RegisterData) {}
    }

    export class GetSocialProviders {
        static readonly type = '[AUTHORIZATION] Get Social Providers';
    }

    export class setAuthorization {
        static readonly type = '[AUTHORIZATION] Set Authorization';
        constructor(public sid: string) {}
    }

    export class ResetAuthorization {
        static readonly type = '[AUTHORIZATION] Reset';
        constructor() {}
    }

    export class SetSessionProperty {
        static readonly type = '[AUTHORIZATION] Set Session Property';
        constructor(public property: string, public value: any) {}
    }

    export class DeleteSessionProperty {
        static readonly type = '[AUTHORIZATION] Delete Session Property';
        constructor(public property: string) {}
    }
}
