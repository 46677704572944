export namespace BookingsActions {
  export class GetAll {
    static readonly type = `[BOOKINGS] Get All Bookings`;
    constructor(public isHistory: boolean, public params?: any) {}
  }

  export class LoadMoreBookings {
    static readonly type = `[BOOKINGS] Load More Bookings`;
    constructor(public isHistory: boolean, public params?: any) {}
}

  export class RefreshSingle {
      static readonly type = `[BOOKINGS] Refresh Single Booking`;
      constructor(public bookingId: string, public isHistory: boolean) {}
  }

  export class UpdateSingleBooking {
    static readonly type = `[BOOKINGS] Update Single Booking`;
    constructor(public newBooking: any, public isHistory: boolean) {}
}

  export class ResetAll {
    static readonly type = `[BOOKINGS] Reset All`;
  }
}
