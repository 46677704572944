import { ServicesState, ServicesStateModel } from './services.state';
import { Selector, createSelector } from '@ngxs/store';

export class ServicesSelectors {
  @Selector([ServicesState])
  static getAll(state: ServicesStateModel) {
    return state?.services;
  }

  static getSingleService(serviceId: number) {
    return createSelector([ServicesState], (state) => {
      return state.services.find((service) => service.id == serviceId);
    });
  }
}
