import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Select } from '@ngxs/store';
import { isNotEmittedStatement } from 'typescript';
import { ModulesSelectors } from '../../../common/stores/core-store/modules/modules.selectors';
import { Observable } from 'rxjs';

@Component({
    selector: 'serviceos-ng-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {

    @Select(ModulesSelectors.getModuleConfigText('bookings'))
    moduleBookingsTexts$!: Observable<any>;

    @Input()
    readonly = false;

    @Input()
    notesData;

    @Input()
    texts = {
        heading: "heading",
        readonly_heading: "readonly_heading",
        readonly_heading_no_data: "readonly_heading_no_data",
        submit_button_title: "submit_button_title",
        note_field_placeholder: "note_field_placeholder"
    };

    @Output()
    noteAdded = new EventEmitter();

    @Input()
    showLocalLoader = false;

    public note = new FormControl('');

    constructor() {}

    public addNote(note) {        
        if (note) {
            this.noteAdded.emit(note);
            this.note.setValue('');
        }
    }

    public onKeyPress(event) {
        if (event.charCode == 13) {
            this.addNote(this.note.value);
        }
    }

    ngOnInit(): void {}
}
