class GenerateUniqueQuerySelectorForElement {
    constructor() {}

    /**
     * Generate String with given number of characters
     * @param numberOfCharacters
     */
    private randomCharacters() {
        // tslint:disable-next-line:no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    private generatorForTheFullString(numberOfStringCombination) {
        let uniqueString = 'u';

        for (let index = 0; index < numberOfStringCombination; index++) {
            uniqueString += (index !== 0 ? '-' : '') + this.randomCharacters() + this.randomCharacters();
        }

        return uniqueString;
    }

    public addElementUniqueSelector(element, lengthCombination = 4) {
        const uniqueSelector = this.generatorForTheFullString(lengthCombination);
        let returnData = {
            element,
            selector: uniqueSelector,
        };

        if (this.checkSelector(uniqueSelector)) {
            returnData = this.addElementUniqueSelector(element, lengthCombination);
        }

        returnData.element.nativeElement.classList.add(returnData.selector);

        return returnData;
    }

    private checkSelector(uniqueSelector) {
        return document.querySelectorAll(`.${uniqueSelector}`).length > 1;
    }
}

export const generatorQuerySelector = new GenerateUniqueQuerySelectorForElement();
