import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AuthorizationActions } from './authorization.actions';
import { AuthorizationSelectors } from './authorization.selectors';
import { AuthorizationModel } from './authorization.state';

@Injectable({
    providedIn: 'root',
})
export class LogoutGuardGuard implements CanActivate {
    constructor(private store: Store, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.store.select(AuthorizationSelectors.getAuthorization).pipe(
            filter((data) => {
                if (typeof data == 'undefined') {
                    console.warn(
                        'BE SURE YOU ARE IMPORTING THE STORE WHERE YOU USE THE GUARD BEFORE LOSING MORE TIME DEBUGGING'
                    );
                }

                return data.status !== 'pending';
            }),
            switchMap((authorize: AuthorizationModel) => {
                return this.store
                    .dispatch(new AuthorizationActions.Logout())
                    .pipe(
                        catchError((e) => of(true)),
                        map(() => {
                            return this.router.createUrlTree(['']);
                        })
                    );
            })
        );
    }
}
