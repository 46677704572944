import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { ApiRequest, Environment } from '../../../common';
import { genGUID, popupCenter, SosCryptoService } from '../../../helpers';
import { SocialProvider } from '../authentication-types';
import { BaseSocialAuth } from './base-social-auth';
import { Store } from '@ngxs/store';
import { AuthorizationSelectors } from '../authorization.selectors';

declare global {
    interface Window {
        obfOptions: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class OauthService extends BaseSocialAuth {

    authPaths: { login: string, register: string } = {
        login: '/ext/login',
        register: '/ext/register'
    };

    public popupInstance: Window | null = null;

    constructor(
        cryptoService: SosCryptoService,
        env: Environment,
        translate: TranslateService,
        private _store: Store
    ) {
        super(cryptoService, env, translate);
    }

    /**
     * Run Oauth authorization cycle
     */
    public beginAuth(socialProvider: SocialProvider) {
        return new Promise<
            ApiRequest<{
                oauth_id: any;
                id_token: any;
                social_provider_id: any;
            }>
        >((resolve: any, reject: any) => {

            const apiRequest = new ApiRequest({
                type: 'POST',
                url: this.authPaths.login,
                data: null,
                options: null,
            });

            const oauthProvider = this._store.selectSnapshot(AuthorizationSelectors.getSocialProvider('OAuth2'));
            
            const oauth2Payload = {
                oauth_id: null,
                redirect_uri: '',
                social_provider_id: 0,
                // code_verifier: null
            }

            if (oauthProvider) {
                oauth2Payload.social_provider_id = oauthProvider.id;
            }

            const clientId = oauthProvider?.data.client_id;
            const authorizationUrl = oauthProvider?.data.authorization_url;
            const obfOptions = window.obfOptions;

            const redirectUri = obfOptions?.app_url ? obfOptions?.app_url + 'authorization.html' : window.location.origin + '/obf/authorization.html';

            // Localhost debug
            // const redirectUri = 'http://accounts-local.1dxr.com/authorization.html';
            
            oauth2Payload.redirect_uri = redirectUri;

            const oauth2UrlPlain = authorizationUrl + '?client_id=' + clientId + '&redirect_uri=' + redirectUri + '&response_type=code';

            const popupPostMessageHandler = ((event) => {

                if (window.location?.origin && event?.origin && window.location.origin.indexOf(event.origin) !== -1) {
                    if (event?.data?.code) {
                        oauth2Payload.oauth_id = event.data.code
                        if (oauth2Popup) {
                            oauth2Popup.close();
                        }
                    }
                } else {
                    apiRequest.setResponse(null);
                    reject(apiRequest);
                }

            });
            // append listener for event
            window.addEventListener('message', popupPostMessageHandler);

            const centerPopup = popupCenter();
            const oauth2Popup = window.open(
                oauth2UrlPlain,
                'popUpWindow',
                'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no',
            );

            this.popupInstance = oauth2Popup;

            const subscription = interval(1000).subscribe(() => {

                // Wait to close popup.
                if (oauth2Popup && !oauth2Popup.closed) { return; }

                if (oauth2Payload?.oauth_id) {
                    // res(oauth2Payload);

                    apiRequest.setResponse({
                        data: {
                            oauth_id: oauth2Payload.oauth_id,
                            redirect_uri: oauth2Payload.redirect_uri,
                            social_provider_id: socialProvider.id,
                        },
                    });

                    resolve(apiRequest);
                } else {
                    apiRequest.setResponse(null);
                    reject(apiRequest);
                }

                window.removeEventListener('message', popupPostMessageHandler);
                subscription.unsubscribe();
            });

        });
    }
}
