import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeepLinksStateModule } from './deep-links/deep-links-state.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    DeepLinksStateModule
  ],
  exports: [
  ]
})
export class SosDeepLinksStoreModule { }
