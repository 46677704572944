import { CreditActivityState, CreditActivityStateModel } from './credit-activity.state';
import { Selector } from '@ngxs/store';

export class CreditActivitySelectors {

    @Selector([CreditActivityState])
    static getAll(state: CreditActivityStateModel) {
        return state.creditActivity
    }

    @Selector([CreditActivityState])
    static getCreditActivityLength(state: CreditActivityStateModel) {
        return state.creditActivityLength
    }
}