<div id="overlay" *ngIf="settings.overlayEnabled && !isHidden"></div>

<div class="obf-loader" *ngIf="!isHidden">
  <div class="centered">
    <div id="obf-loader-wrapper">
      <div id="obf-inner-loader" class="obf-loading"></div>
      <div class="obf-loader-section section-left"></div>
      <div class="obf-loader-section section-right"></div>
    </div>
    <div class="obf-loading-text-container">
      <span>{{ settings.text }}</span>
    </div>
  </div>
</div>
