import { Injectable } from '@angular/core';
import * as cryptoAES from 'crypto-js/aes';
import * as cryptoUtf8 from 'crypto-js/enc-utf8';
@Injectable({
    providedIn: 'root',
})
// NOTE Do we need this to be service ?
export class SosCryptoService {
    constructor() { }

    /**
     * @param {any} data
     * @param {string} key
     */
    public encryptData(data: any, key: string, toString = true) {
        let encryptedData: any = '';

        if (typeof data === 'object') {
            encryptedData = cryptoAES.encrypt(JSON.stringify(data), key);
        } else {
            encryptedData = cryptoAES.encrypt(data.toString(), key);
        }

        return toString ? encryptedData.toString() : encryptedData;
    }

    /**
     * @param {any} data
     * @param {string} key
     */
    public decryptData(data: any, key: string) {
        const decryptedData = cryptoAES.decrypt(data.toString(), key);
        let plaintext = '';

        try {
            plaintext = JSON.parse(decryptedData.toString(cryptoUtf8));
        } catch (err) {
            plaintext = decryptedData.toString(cryptoUtf8);
        }

        return plaintext;
    }
}
