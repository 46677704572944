import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { SessionStorageService } from '../../../common/services/session-storage/session-storage.service';

import { SosCoreConfig } from '../../../core-config';
import { AccountSelectors } from '../account.selectors';
import { BookingsSelectors } from '../../bookings-store/bookings/bookings.selectors';
import { LocalStorageService, ModulesSelectors } from '../../../common';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    
    private accountSettingsScreen;
    private userData;
    private unratedPastBookings: any[] = [];

    private notificationsComponentConfig: any = null; 

    constructor(
        private config: SosCoreConfig,
        private _localStorageService: LocalStorageService,
        private store: Store
    ) {}

    /**
     * Get all user notifications -  this is temporary
     * @param path
     * @param params
     * @returns
     */
    public getAll(): Observable<any> {

        this.notificationsComponentConfig = this.store.selectSnapshot(
            ModulesSelectors.getComponentFromModule('account', 'notifications')
        );

        let notifications = [];

        this.unratedPastBookings = this.store.selectSnapshot(BookingsSelectors.getUnratedPastBookings);

        notifications = this.generateNotifications();

        return of(notifications)
    }

    private generateNotifications() {
        const generatedNotifications: any = [];
        const domain = window?.location?.origin;

        this.userData = this.store.selectSnapshot(AccountSelectors.account);
        this.accountSettingsScreen = this.store.selectSnapshot(ModulesSelectors.getComponentFromModule(
            'account',
            'account_settings_screen'
        ));

        this.accountSettingsScreen = this.store.selectSnapshot(ModulesSelectors.getComponentFromModule(
            'account',
            'account_settings_screen'
        ));

        // Avatar notification
		if ((!this.userData?.avatar || !this.userData?.avatar?.id)) { //! TODO implement notDisabled
			generatedNotifications.push({
                id: generatedNotifications.length + 100,
                closed: this._localStorageService.getItem(this.userData?.id + '_account_personalinfo_avatar_notice') == 1 ? true : false,
                button: this.notificationsComponentConfig?.account_personalinfo_avatar_notice?.button_title ? this.notificationsComponentConfig.account_personalinfo_avatar_notice.button_title : "Change photo",
                message: this.notificationsComponentConfig?.account_personalinfo_avatar_notice?.message ? this.notificationsComponentConfig.account_personalinfo_avatar_notice.message : "Please add a profile photo.",
                messageType: "account_personalinfo_avatar_notice",
                url: "/account-settings",
                urlParams: {}
			});
		}
		
		// Address notification
		if ((!this.userData?.addresses || !this.userData?.addresses.length)) {
			generatedNotifications.push({
                id: generatedNotifications.length + 100,
                closed: this._localStorageService.getItem(this.userData?.id + '_account_address_notice') == 1 ? true : false,
				button: this.notificationsComponentConfig?.account_address_notice?.button_title ? this.notificationsComponentConfig.account_address_notice.button_title : "Add Address",
                message: this.notificationsComponentConfig?.account_address_notice?.message ? this.notificationsComponentConfig.account_address_notice.message : "Please add your address. Book even faster when you store your address (your information is always safe).",
                messageType: "account_address_notice",
                url: "/account-settings",
                urlParams: {
                    action: 'add-address'
                }
			});
		}
		
		// Paymethods notification
		if (this.accountSettingsScreen.payments && !this.accountSettingsScreen.payments.disable && (!this.userData?.paymethods || !this.userData?.paymethods.length)) {
			generatedNotifications.push({
                id: generatedNotifications.length + 100,
                closed: this._localStorageService.getItem(this.userData?.id + '_account_paymentdetails_notice') == 1 ? true : false,
				button: this.notificationsComponentConfig?.account_paymentdetails_notice?.button_title ? this.notificationsComponentConfig.account_paymentdetails_notice.button_title : "Add Card",
                message: this.notificationsComponentConfig?.account_paymentdetails_notice?.message ? this.notificationsComponentConfig.account_paymentdetails_notice.message : "Please add a credit card number. Book even faster when you store your payment details (your information is always safe).",
                messageType: "account_paymentdetails_notice",
                url: "/account-settings",
                urlParams: {
                    action: 'add-card'
                }
			});
		}

		// Phone notification
		if ((!this.userData?.phones || !this.userData?.phones.length)) {
			generatedNotifications.push({
                id: generatedNotifications.length + 100,
                closed: this._localStorageService.getItem(this.userData?.id + '_account_phones_notice') == 1 ? true : false,
				button: this.notificationsComponentConfig?.account_phones_notice?.button_title ? this.notificationsComponentConfig.account_phones_notice.button_title : "Add phone number",
                message: this.notificationsComponentConfig?.account_phones_notice?.message ? this.notificationsComponentConfig.account_phones_notice.message : "Please add your phone number. We'll contact you in case we need additional details to process your booking.",
                messageType: "account_phones_notice",
                url: "/account-settings",
                urlParams: {
                    action: 'add-phone'
                }
			});
		}

        // Membership notification
        if (this.shouldNotifyMembership()) {
            generatedNotifications.push({
                id: generatedNotifications.length + 100,
                closed: this._localStorageService.getItem(this.userData?.id + '_account_membership_notice') == 1 ? true : false,
				button: this.notificationsComponentConfig?.account_membership_notice?.button_title ? this.notificationsComponentConfig.account_membership_notice.button_title : "Renew membership",
                message: this.notificationsComponentConfig?.account_membership_notice?.message ? this.notificationsComponentConfig.account_membership_notice.message : "Your Membership will expire soon. Renew it to keep enjoying your member benefits.",
                messageType: "account_membership_notice",
                url: "/membership-club",
                urlParams: {}
			});
        }

        // Unrated bookings notifications
        if (this.unratedPastBookings) {
            generatedNotifications.push(...this.getRateNotifications());
        }
        
        return generatedNotifications;
    }

    private getRateNotifications(): any[] {

        const rateNotifications: any[] = [];

        if (this.unratedPastBookings && this.unratedPastBookings.length) {
            this.unratedPastBookings.forEach( booking => {
                if ( booking.service?.id && booking.service?.title) {
                    rateNotifications.push({
                        id: rateNotifications.length + 200,
                        closed: this._localStorageService.getItem(this.userData?.id + '_bookings_unrated_notice' + '_' + booking.id) == 1 ? true : false,
                        button: this.notificationsComponentConfig?.bookings_unrated_notice?.button_title ? this.notificationsComponentConfig.bookings_unrated_notice.button_title : "Rate",
                        isNormalBooking: true,
                        message: this.notificationsComponentConfig?.bookings_unrated_notice?.message ? this.notificationsComponentConfig.bookings_unrated_notice.message : "Thanks for booking",
                        messageTwo: this.notificationsComponentConfig?.bookings_unrated_notice?.message_end ? this.notificationsComponentConfig.bookings_unrated_notice.message_end : " with us! Please rate the service to let us know how your experience was!",
                        messageType: "bookings_unrated_notice",
                        objectId: booking.id,
                        referenceNumber: booking.reference_number,
                        serviceId: booking.service?.id,
                        serviceTitle: booking.service?.title ? booking.service?.title : '',
                        showRebookButton: true,
                        // url: window?.location?.origin + "/history/"+booking.id+"?action=rate"
                        url: "/history/"+booking.id,
                        urlParams: {
                            action: 'rate'
                        }
                    })
                }
            })
        }
        return rateNotifications;
        
    }

    private shouldNotifyMembership() {
        let shouldNotify = false;

        const membershipExpireData = this.store.selectSnapshot(
            AccountSelectors.expiringMembershipAlert
        );     

        if (membershipExpireData && membershipExpireData.shouldRemind && !membershipExpireData.auto_renew_enabled) {
            shouldNotify = true;
        }

        return shouldNotify;
    }
}
