import { CoreStateModel, CoreState } from './core.state';
import { Selector, createSelector } from '@ngxs/store';

export class CoreSelectors {
    @Selector([CoreState])
    static config(state: CoreStateModel) {
        return state.application;
    }

    @Selector([CoreState])
    static completeInitialize(state: CoreStateModel) {
        return state.application && state.status === 'success';
    }

    @Selector([CoreState])
    static logo(state: CoreStateModel) {
        return state.application?.assets?.images?.logo_url || '';
    }

    @Selector([CoreState])
    static brandName(state: CoreStateModel) {
        return state.application?.settings?.brand_name || '';
    }

    @Selector([CoreState])
    static automated_brand(state: CoreStateModel) {
        if (
            state.application?.assets?.images?.automated_by_logo_url &&
            state.application?.settings?.automated_by_site_url
        ) {
            return {
                automated_by_logo_url:
                    state.application.assets.images.automated_by_logo_url,
                automated_by_site_url:
                    state.application.settings.automated_by_site_url,
            };
        }
        return null;
    }
}
