export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}
// darker
export const rgbShade = (rgb, factor) => {
    const shadeFactor = 1 - Math.min(Math.max(factor, 0), 1);
    return {
        r: Math.floor(rgb.r * shadeFactor),
        g: Math.floor(rgb.g * shadeFactor),
        b: Math.floor(rgb.b * shadeFactor)
    }
}

// lighter
export const rgbTint = (rgb, factor) => {
    const tintFactor = Math.min(Math.max(factor, 0), 1)
    return {
        r: Math.floor(rgb.r + (255 - rgb.r) * tintFactor),
        g: Math.floor(rgb.g + (255 - rgb.g) * tintFactor),
        b: Math.floor(rgb.b + (255 - rgb.b) * tintFactor)
    }
}
// Generate contrast color 
export const generateVisibleTextColor = (rgb) => {
    let luminosity = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;
    return luminosity > .5 ? 'rgb(51,51,51)' : 'rgb(255,255,255)';
}

export const toRGBStyle = (rgb) => `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;