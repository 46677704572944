export namespace CoreActions {

    export class InitializeCoreStore$ {
        static readonly type = '[CORE] Initialize'
    }

    export class GetSingleModule {
        static readonly type = '[CORE] Get Single Module'
        constructor(public pathName: string, public storeName: string) { }
    }

    export class RegisterFeatureStore {
        static readonly type = '[CORE] Register Feature Store'
        constructor(public name: string) { }
    }

    export class InitializeFeatureStore {
        static readonly type = '[CORE] Initialize Feature Store'
        constructor(public name: string) {}
    }
}