import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ElementRef,
    ViewEncapsulation,
    AfterViewInit,
    ContentChildren,
    QueryList,
    ContentChild,
    TemplateRef,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ModalsService } from '../../modals/modals.service';
import { take } from 'rxjs/operators'
import { Select, Store } from '@ngxs/store';
import { BookingSelectors } from '../../../stores-modules';
import { ModulesSelectors } from '../../../common/stores/core-store/modules/modules.selectors';
import { Observable } from 'rxjs';
import { ThemeSelectors } from '../../../common/stores/core-store/theme/theme.selectors';
import { TrackingActions } from '../../../common/stores/core-store/tracking/tracking.actions';
import { BookingCardDetailsEvent } from './booking-card-details-event.interfase';
import { convertDeepLinkToObject } from '../../../helpers';

declare global {
    interface Window {
        fcWidget: any;
        fcWidgetMessengerConfig: any;
        fwcrm: any;
    }
}

@Component({
    selector: 'serviceos-ng-booking-card-details',
    templateUrl: './booking-card-details.component.html',
    styleUrls: ['./booking-card-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BookingCardDetailsComponent implements OnInit, AfterViewInit {
    @Input()
    booking;

    @Input()
    position: string = '';

    @Output()
    onEvent = new EventEmitter<BookingCardDetailsEvent>();

    @Select(ModulesSelectors.getImageFromModule('tip', 'tip_icon_url'))
    tipIcon$!: Observable<string>;

    @Input()
    texts = {
        change_pro_link: "change_pro_link",
        price_title: "price_title",
        edit_plan: "edit_plan",
        edit_service: "edit_service",
        edit_session: "edit_session",
        regular_price_description: "regular_price_description",
        regular_tag: "regular_tag",
        card_payment: "card_payment",
        cash: "cash",
        card_payment_on_sitе: "card_payment_on_sitе",
        paypal: "paypal",
        view_booking_link: "view_booking_link",
        view_regular_link: "view_regular_link",
        track_pro_title: "track_pro_title",
        contact_box_text: 'To reschedule or cancel, please contact us via <span class="open-chat">Chat</span>.'
        
    };

    @Input()
    screen = 'bookings';

    @Input()
    isInnerView = false;

    public tipProCondition = false;
    public tipSectionConfig: any = null;
    public tipProIconColor = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private el: ElementRef,
        private _modalsService: ModalsService,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.tipSectionConfig = this.store.selectSnapshot(ModulesSelectors.getComponentFromModule('tip', 'tip_section'));

        if (this.booking) {
            this.tipProCondition = this.tipSectionConfig && this.booking && this.booking.tip_choice ? true : false;

            // Send data layer event
            if (this.tipProCondition) {
                this.onEvent.emit({
                    type: 'track',
                    data: {
                        event: 'tip_button_viewed',
                        position: this.position,
                        booking_id: this.booking?.id ? this.booking.id : this.booking.objectId
                    }
                });
            }
        }
        const theme = this.store.selectSnapshot(ThemeSelectors.getTheme);

        this.tipProIconColor = theme?.primary_color;

        if (this.booking && this.booking.actions && this.booking.actions.length) {
            this.booking.actions.forEach(action => {
                if (action.type === 'rate' || action.type === 'complain') {
                    this.onEvent.emit({
                        type: 'track',
                        data: {
                            event: `${action.type === 'complain' ? 'complaint' : action.type}_button_viewed`,
                            position: this.position,
                            booking_id: this.booking?.id ? this.booking.id : this.booking.objectId
                        }
                    });
                }
            });
        }
    }

    ngAfterViewInit () {
        // Add event after content is loaded (open chat)
        if(this.el.nativeElement.querySelector('.open-chat')){
            this.el.nativeElement.querySelector('.open-chat').addEventListener('click', this.openChat.bind(this));
        }
    }

    actionFavPro(unitData: any) {
        // this.onToggleUnitFav.emit(unit);
        this.onEvent.emit({
            type: 'favorite_pro',
            data: unitData
        });
    }

    showUnitInfo(unitData: any) {
        this.onEvent.emit({
            type: 'show_unit_info',
            data: unitData
        });
    }

    public deepLinkClicked(deepLinkData: any) {

        let deepLinkObejct:any = {};

        if (deepLinkData?.deep_link) {
            deepLinkObejct = convertDeepLinkToObject(deepLinkData.deep_link);
        }

        const navigationMenu = this.store.selectSnapshot(ModulesSelectors.getComponentFromModule('account', 'menu'));
        const isCalendarEnabled = navigationMenu?.items.some(item => item.name === 'calendar');
        const isButtonHandleByCalendar = deepLinkData.type === 'reschedule' || deepLinkData.type === 'edit';

        if (isCalendarEnabled && isButtonHandleByCalendar) {
            this.router.navigate(['/calendar'], { queryParams: {
                    action: deepLinkData.type,
                    booking_id: deepLinkObejct?.fs_element_id
                }
            });
            return;
        }

        // assign initiator position property which indicates from where is deep link clicked
        Object.assign(deepLinkData, { initiator_position: this.position });

        // this.onDeepLink.emit(deepLink);
        this.onEvent.emit({
            type: 'deep_link',
            data: deepLinkData
        });

        if (deepLinkData.type === 'rate' || deepLinkData.type === 'complain') {
            this.onEvent.emit({
                type: 'track',
                data: {
                    event: `${deepLinkData.type === 'complain' ? 'complaint' : deepLinkData.type}_button_tapped`,
                    position: this.position,
                    booking_id: this.booking?.id ? this.booking.id : this.booking.objectId
                }
            });
        }
    }

    /**
    * Navigate to booking details page
    * @param {any} bookingId
    */
    public viewBookingDetails(bookingId) {
        this.onEvent.emit({
            type: 'view_booking_details',
            data: {
                booking_id: bookingId,
                screen: this.screen
            }
        });
    }

    /**
     * Open chat
     */
    public openChat() {
        if (window.fcWidget) {
            window.fcWidget.open();
        }
    }

    /**
     * Tip professional handler
     */
    public onTipProfessional(): void {
        this.onEvent.emit({
            type: 'tip_pro',
            data: {
                bookingId: this.booking?.id ? this.booking.id : this.booking.objectId,
                position: 'booking_details'
            }
        });

        this.onEvent.emit({
            type: 'track',
            data: {
                event: 'tip_button_tapped',
                position: this.position,
                booking_id: this.booking?.id ? this.booking.id : this.booking.objectId
            }
        });
    }
}
