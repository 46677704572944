<div class="booking-card" *ngIf="booking">
    <!-- IMAGE BOX -->
    <div
        class="image-box-holder"
        [style.background-image]="'url(' + booking?.service_image_url + ')'"
        [ngClass]="{ desaturate: screen === 'history' && !booking?.service?.inactive_booking_image_url }"
    >
        <span *ngIf="booking.type == 'regular'" class="regular-tag">{{
            texts.regular_tag
        }}</span>
        <!-- <img height="100%" width="100%" alt="" [ngClass]="{'desaturate': screen === 'history'}"
            [src]="booking.service_image_url"> -->
        <!-- 'https://accounts-dev.1dxr.com/images/fantastic-services/default-color.png' -->
    </div>
    <!-- HEADER BOX -->
    <div #header>
        <ng-content selector="booking-card-details-header"></ng-content>
    </div>
    <ng-container *ngIf="header.children.length == 0">
        <div
            class="header-box-holder"
            *ngIf="
                isInnerView &&
                (booking.serviceTitle ||
                    (booking.service_summary?.length &&
                        booking.service_summary[0].type === 'service'))
            "
        >
            <div class="service-title">
                <span class="title">
                    {{
                        booking.serviceTitle
                            ? booking.serviceTitle
                            : booking.service_summary[0].title
                    }}
                </span>
                <div class="rate" *ngIf="booking.rated">
                    <span
                        *ngFor="let item of [1, 2, 3, 4, 5]"
                        [ngClass]="{ fill: item <= booking.rated }"
                    >
                        <serviceos-ng-system-icon [icon]="'rating'">
                        </serviceos-ng-system-icon>
                    </span>
                </div>
            </div>
            <div class="booking-ref" *ngIf="booking.referenceNumber">
                Ref.#{{ booking.referenceNumber }}
            </div>
        </div>
    </ng-container>

    <!-- DETAILS BOX -->
    <div #body>
        <ng-content selector="booking-card-details-body"></ng-content>
    </div>

    <ng-container *ngIf="body.children.length == 0">
        <div class="details-box-holder">
            <div class="tiny-header" *ngIf="!isInnerView">
                <span class="title">
                    <ng-container
                        *ngIf="
                            booking.serviceTitle ||
                            (booking.service_summary?.length &&
                                booking.service_summary[0].type === 'service')
                        "
                    >
                        {{
                            booking.serviceTitle
                                ? booking.serviceTitle
                                : booking.service_summary[0].title
                        }}
                    </ng-container>
                </span>
                <div class="rate" *ngIf="booking.rate">
                    <span
                        *ngFor="let item of [1, 2, 3, 4, 5]"
                        [ngClass]="{ fill: item <= booking.rate?.rating }"
                    >
                        <serviceos-ng-system-icon [icon]="'rating'">
                        </serviceos-ng-system-icon>
                    </span>
                </div>
            </div>
            <div
                class="info"
                *ngIf="booking.addressDisplayText || booking.address_formatted"
            >
                <serviceos-ng-system-icon
                    class="sos-icon"
                    [icon]="'map-address-postcode'"
                ></serviceos-ng-system-icon>
                <span class="info-text">
                    {{
                        booking.addressDisplayText
                            ? booking.addressDisplayText
                            : booking.address_formatted
                    }}
                </span>
            </div>
            <!-- Normal -->
            <div
                class="info"
                *ngIf="
                    booking.arrival_timeframe_formatted &&
                    (booking.frequencies?.length == 0 || screen === 'history')
                "
            >
                <serviceos-ng-system-icon
                    class="sos-icon"
                    [icon]="'appointment-time'"
                ></serviceos-ng-system-icon>
                <span class="info-text">
                    {{ booking.arrival_timeframe_formatted }}
                </span>
            </div>
            <!-- Regular -->
            <div class="info" *ngIf="booking.frequencies?.length">
                <serviceos-ng-system-icon
                    class="sos-icon"
                    [icon]="'appointment-time'"
                ></serviceos-ng-system-icon>
                <span class="info-text">
                    {{ booking.frequencies[0].frequencyFormatted }}
                </span>
            </div>
            <div class="info" *ngIf="booking.payment_method_title">
                <serviceos-ng-system-icon
                    class="sos-icon"
                    [icon]="'credit-card-card-payment'"
                >
                </serviceos-ng-system-icon>
                <span class="info-text">
                    {{ booking.payment_method_title }}
                </span>
            </div>
            <div class="info" *ngIf="booking.unit || booking.team; let unit">
                <serviceos-ng-system-icon
                    class="sos-icon"
                    [icon]="'account-contact-details'"
                >
                </serviceos-ng-system-icon>
                <span class="info-text unit-text">
                    {{ unit.name }}
                </span>

                <serviceos-ng-system-icon
                    (click)="showUnitInfo(unit)"
                    class="sos-icon info"
                    [icon]="'info-circle'"
                >
                </serviceos-ng-system-icon>

                <ng-container *ngIf="unit.favorite === true || unit.favorite === false">

                    <serviceos-ng-system-icon
                    *ngIf="unit.favorite"
                    (click)="actionFavPro(unit)"
                    class="sos-icon fav"
                    [icon]="'add-to-favourites-full'"
                    ></serviceos-ng-system-icon>
                    
                    <serviceos-ng-system-icon
                    *ngIf="!unit.favorite"
                    (click)="actionFavPro(unit)"
                    class="sos-icon no-fav"
                    [icon]="'add-to-favourites'"
                    ></serviceos-ng-system-icon>
                </ng-container>

                <ng-container *ngFor="let deepLink of booking.actions">
                    <serviceos-ng-link
                        *ngIf="deepLink.type === 'reschedule'"
                        class="unit-reschedule-link ms-auto"
                        (click)="deepLinkClicked(deepLink)"
                        [size]="'small'"
                    >
                        {{ texts.change_pro_link }}
                    </serviceos-ng-link>
                </ng-container>
            </div>
            <div class="info tip-pro-holder" *ngIf="tipProCondition">
                <ng-container *ngIf="tipIcon$ | async as tipIcon">
                    <img serviceosNgImageColorFilter [color]="tipProIconColor" [src]="tipIcon">
                </ng-container>
                <span class="info-text">
                    <serviceos-ng-link
                        *ngIf="tipSectionConfig && tipSectionConfig.title"
                        class="unit-reschedule-link ms-auto"
                        [size]="'small'"
                        (click)="onTipProfessional()"
                    >
                        {{ tipSectionConfig?.title }}
                    </serviceos-ng-link>
                </span>
            </div>
        </div>
    </ng-container>

    <div #footer>
        <ng-content selector="booking-card-details-footer"> </ng-content>
    </div>

    <ng-container *ngIf="footer.children.length === 0">
        <!-- TRACK PRO BOX -->
        <div
            class="track-pro-box-holder"
            *ngIf="
                !isInnerView && booking?.unitStatusOption
            "
        >
            <span
                class="track-label"
                style="
                    background-image: url('https://files.dxr.cloud/i6cUaidoApYJFxmPRZpUm0yPdaG5rycDPrmqxZq98VbFE5n71PJUfhzalGaC');
                "
                (click)="
                    viewBookingDetails(
                        booking.objectId ? booking.objectId : booking.id
                    )
                "
            >
                <serviceos-ng-system-icon
                    class="sos-icon"
                    [icon]="'map-address-postcode'"
                ></serviceos-ng-system-icon>
                <span class="info-text">
                    {{ texts.track_pro_title }}
                </span>
            </span>
        </div>
        <!-- TOTAL BOX -->
        <div
            class="total-box-holder"
            *ngIf="
                isInnerView &&
                (booking.totalFormatted ||
                    (booking.price?.price_breakdown?.length &&
                        booking.price?.price_breakdown[0]?.type === 'total'))
            "
        >
            <div class="price-break d-flex justify-content-between">
                <span>{{ texts.price_title }}</span>
                <span>{{
                    booking.totalFormatted
                        ? booking.totalFormatted
                        : booking.price.price_breakdown[0].value
                }}</span>
            </div>
            <div
                *ngIf="booking.type == 'regular'"
                class="regular-price-description"
            >
                <span>{{ texts.regular_price_description }}</span>
            </div>
        </div>
        <!-- ACTION BOX -->
        <div
            class="action-box-holder"
            *ngIf="booking.actions?.length"
            [ngClass]="{
                'no-border': isInnerView && booking.type === 'regular'
            }"
        >
            <ng-content select="serviceos-ng-booking-card-actions">
            </ng-content>
            <ng-container *ngFor="let deepLink of booking.actions">
                <ng-container [ngSwitch]="deepLink.type">
                    <ng-container *ngIf="isInnerView">
                        <serviceos-ng-button
                            *ngSwitchCase="'edit'"
                            (click)="deepLinkClicked(deepLink)"
                            [isFullWidth]="true"
                            [type]="'primary'"
                        >
                            {{ deepLink.title }}
                        </serviceos-ng-button>
                    </ng-container>
                    <serviceos-ng-button
                        *ngSwitchCase="'reschedule'"
                        (click)="deepLinkClicked(deepLink)"
                        [isFullWidth]="true"
                        [type]="'secondary'"
                    >
                        {{ deepLink.title }}
                    </serviceos-ng-button>
                    <serviceos-ng-button
                        *ngSwitchCase="'cancel'"
                        (click)="deepLinkClicked(deepLink)"
                        [isFullWidth]="true"
                        [type]="'tertiary'"
                    >
                        {{ deepLink.title }}
                    </serviceos-ng-button>
                    <serviceos-ng-button
                        *ngSwitchCase="'skip_session'"
                        (click)="deepLinkClicked(deepLink)"
                        [isFullWidth]="true"
                        [type]="'tertiary'"
                    >
                        {{ deepLink.title }}
                    </serviceos-ng-button>
                    <serviceos-ng-button
                        *ngSwitchCase="'rate'"
                        (click)="deepLinkClicked(deepLink)"
                        [isFullWidth]="true"
                        [type]="'secondary'"
                    >
                        {{ deepLink.title }}
                    </serviceos-ng-button>
                    <serviceos-ng-button
                        *ngSwitchCase="'renew'"
                        (click)="deepLinkClicked(deepLink)"
                        [isFullWidth]="true"
                        [type]="'primary'"
                    >
                        {{ deepLink.title }}
                    </serviceos-ng-button>
                    <serviceos-ng-button
                        *ngSwitchCase="'complain'"
                        (click)="deepLinkClicked(deepLink)"
                        [isFullWidth]="true"
                        [type]="'tertiary'"
                    >
                        {{ deepLink.title }}
                    </serviceos-ng-button>
                    <ng-container *ngIf="!isInnerView">
                        <serviceos-ng-button
                            *ngSwitchCase="'navigate'"
                            (click)="deepLinkClicked(deepLink)"
                            [isFullWidth]="true"
                            [type]="'secondary'"
                        >
                            {{ deepLink.title }}
                        </serviceos-ng-button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <!-- CONTACT BOX-->
        <div class="contact-box-holder" *ngIf="booking.show_contact_box">
            <serviceos-ng-system-icon
                class="sos-icon"
                [icon]="'info-circle'"
            ></serviceos-ng-system-icon>
            <span class="text" [innerHTML]="texts.contact_box_text"> </span>
        </div>
        <!-- VIEW DETAILS BOX -->
        <div class="view-details-box-holder">
            <!-- View details link -->
            <serviceos-ng-link
                *ngIf="
                    !isInnerView &&
                    (booking.type === 'booking' || screen === 'history')
                "
                [type]="'primary'"
                [font_weight]="'semi-bold'"
                [size]="'small'"
                (click)="
                    viewBookingDetails(
                        booking.objectId ? booking.objectId : booking.id
                    )
                "
            >
                {{ texts.view_booking_link }}
            </serviceos-ng-link>
            <!-- Manage plan link -->
            <ng-container *ngFor="let deepLink of booking.actions">
                <ng-container [ngSwitch]="deepLink.type">
                    <ng-container *ngSwitchCase="'edit_regular_plan'">
                        <serviceos-ng-link
                            [type]="'primary'"
                            [size]="'small'"
                            [font_weight]="'semi-bold'"
                            (click)="deepLinkClicked(deepLink)"
                        >
                            <serviceos-ng-system-icon
                                class="sos-icon"
                                [icon]="'edit'"
                            ></serviceos-ng-system-icon>
                            {{ deepLink.title }}
                        </serviceos-ng-link>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <ng-content select="serviceos-ng-booking-card-details-view-summary">
        </ng-content>
    </ng-container>
</div>
