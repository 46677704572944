import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Choice, ChoiceItem, ChoiceItemValueChange } from '../../../../../typings';

@Component({
    selector: 'serviceos-ng-rate-item',
    templateUrl: './rate-item.component.html',
    styleUrls: ['./rate-item.component.scss'],
})
export class RateItemComponent implements OnInit {
    @Output()
    public changeChoiceItemValue: EventEmitter<ChoiceItemValueChange> = new EventEmitter();

    @Input()
    public settings!: ChoiceItem;

    @Input()
    public serviceCustomize;

    @Input()
    public parentInstance!: Choice | ChoiceItem;

    public hover 

    constructor() {}

    public ngOnInit() {
        this.initialize();
    }

    /**
     * Component initializer
     */
    private initialize(): void {

        this.changeChoiceItemValue.emit({
            item: this.settings,
            userInteraction: false,
        });
    }

    /**
     * Check the value of the checkbox
     * and run calculate of the hours
     * @param settings
     */
    public onClicked(value: any): void {
        this.settings.value = value;

        this.changeChoiceItemValue.emit({
            item: this.settings,
            userInteraction: true,
        });
    }

    public onHover(value) {
      this.hover = value;
    }
}
