import { ModulesSelectors } from './../modules/modules.selectors';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    LOCALIZATION_API_PATHS,
    LOCALIZATION_TOKEN,
} from './localization.const';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { SosApiService } from '../../../services/api/api.service';
import { LocalizationActions } from './localization.actions';
import {
    TranslateService,
    MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { forkJoin, of, Observable } from 'rxjs';
import { catchError, map, switchMap, filter } from 'rxjs/operators';
import { ErrorActions } from '../../error-store/error.actions';
import { patch, append, updateItem, iif } from '@ngxs/store/operators';
import {
    ActivatedRoute,
    Router,
    NavigationEnd,
    Event,
    ParamMap,
} from '@angular/router';
import { deepMerge, flatten, pathJoin } from '../../../../helpers';
import { LocationStrategy } from '@angular/common';
import { Environment } from '../../../environment/environment';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';

export class LocalizationStateModel {
    language: string | null = null;
    languages: Localization[] = [];
    shortcodes = {
        brand_name: '',
        referral_bonus_formatted: '',
        'client.first_name': '',
        'client.last_name': '',
        'client.title': '',
        'client.type': '',
        'client.email': '',
        'client.phone': '',
        phone: '',
        
    };
    // [$booking.phone]\">[$booking.phone_formatted]
}

export interface Localization {
    code: string;
    icon_url: string;
    id: number;
    selected: boolean;
    sort: number;
    title: string;
    texts?: any;
}

@State<LocalizationStateModel>({
    name: LOCALIZATION_TOKEN,
    defaults: new LocalizationStateModel(),
})
@Injectable()
export class LocalizationState {
    private currentLangParam: string | null = null;

    constructor(
        private sosApiService: SosApiService,
        private translateService: TranslateService,
        private store: Store,
        private httpClient: HttpClient,
        private router: Router,
        private locationStrategy: LocationStrategy,
        private env: Environment,
        private activatedRoute: ActivatedRoute,
        private _localStorageService: LocalStorageService
    ) {
        // ! Subscribe for language path param.
        this.router.events
            .pipe(
                filter((event: Event) => event instanceof NavigationEnd),
                // Get root of the activeRoute.
                map((): ActivatedRoute => this.activatedRoute.root),
                // Get the first child of the root.
                map((root: any) => {
                    return root.firstChild;
                }),
                // Extract language param from first root.child.
                switchMap(
                    (firstChild: ActivatedRoute): Observable<string | null> => {
                        if (firstChild) {
                            return firstChild.paramMap.pipe(
                                map((paramMap: ParamMap) =>
                                    paramMap.get('lang')
                                )
                            );
                        } else {
                            return of(null); // null is for system.
                        }
                    }
                )
            )
            .subscribe((paramLang) => {
                this.currentLangParam = paramLang;
            });

        this.translateService.missingTranslationHandler.handle = (
            params: MissingTranslationHandlerParams
        ) => {
            if (true || params && params.key !== 'app') {
                this.store.dispatch(
                    new ErrorActions.Localization({
                        code: 100669,
                        message: `[Localization] Fail to find key: ${params.key}`,
                    })
                );
            }

            return '';
        };
    }

    @Action(LocalizationActions.AddSupportedLanguages)
    addSupportedLanguages(
        { patchState, dispatch }: StateContext<LocalizationStateModel>,
        payload: LocalizationActions.AddSupportedLanguages
    ) {
        // Find default language or set on first language.
        const defaultLanguage =
            payload.languages.find((language) => language.selected) ??
            payload.languages[0];
        
        const allLanguages = payload.languages;

        allLanguages.forEach(function (lang) {
            lang.texts = {};
          });

        patchState({
            language: defaultLanguage.code,
            languages: allLanguages
        });
    }

    @Action(LocalizationActions.ChangeLanguage)
    async changeLanguage(
        {
            getState,
            patchState,
            dispatch,
        }: StateContext<LocalizationStateModel>,
        payload: LocalizationActions.ChangeLanguage
    ) {
        const state = getState();
        const selectedLanguage = state.languages.find(
            (localization) => localization.code == payload.language
        );

        this._localStorageService.setItem('app_default_language', payload.language);

        if (selectedLanguage) {
            // Set active language.
            patchState({ language: payload.language });

            // Trigger navigation to change texts for components.
            const url = this.router.parseUrl(this.router.url);
            return this.router.navigateByUrl(url);
        } else {
            return this.store.dispatch(
                new ErrorActions.Localization({
                    code: 100668,
                    message: `[Localization] Selected missing language: ${payload.language}`,
                })
            );
        }
    }

    @Action(LocalizationActions.UpdateTranslation)
    updateTranslation({ getState }: StateContext<LocalizationStateModel>) {
        const state = getState();
        const localization = state.languages.find(
            (localization) => localization.code === state.language
        );

        if (localization) {
            this.translateService.setTranslation(
                localization.code,
                localization.texts,
                false
            );

            this.translateService.setDefaultLang(localization.code);
            this.translateService.use(localization.code);
        }
    }

    @Action(LocalizationActions.LoadPageLocalization)
    loadPageLocalization(
        { getState, setState, dispatch }: StateContext<LocalizationStateModel>,
        payload: LocalizationActions.LoadPageLocalization
    ) {
        const state = getState();
        const routeLocalizationConfig = payload.routeConfig;
        //! Fetch page system texts.
        const loadPageLocalization = [
            this.httpClient
                .get(
                    `${pathJoin([
                        './',
                        this.locationStrategy.getBaseHref(),
                        this.env.deployURL,
                        LOCALIZATION_API_PATHS.localization,
                    ])}${routeLocalizationConfig.path}.i18n.json`,
                    { params: { v: new Date().getTime() } }
                )
                .pipe(
                    // map((res) => ({ [routeLocalizationConfig.key]: res })),
                    catchError((error: HttpErrorResponse) => {
                        //! Handle missing file.
                        dispatch(
                            new ErrorActions.Localization({
                                code: 100667,
                                message: `[Localization] Fail to load default texts for the page: ${routeLocalizationConfig.path}`,
                            })
                        );

                        return of({});
                    })
                ),
        ];

        // Get default language from module config.
        const defaultLanguage =
            state.languages.find((localization) => {
                return localization.selected == true;
            }) ?? state.languages[0];

        routeLocalizationConfig.modules.map((moduleName) => {
            // Customer default language from module config
            loadPageLocalization.push(
                this.store.selectOnce(
                    ModulesSelectors.getModuleConfigText(
                        moduleName,
                        defaultLanguage.code
                    )
                )
            );

            // Get current language from module config.
            loadPageLocalization.push(
                this.store.selectOnce(
                    ModulesSelectors.getModuleConfigText(
                        moduleName,
                        state.language
                    )
                )
            );
        });

        return forkJoin(loadPageLocalization).pipe(
            map((responses: any[]) => {
                const localization = state.languages.find((localization) => {
                    return localization.code == state.language;
                }) ?? { texts: {} };

                const texts: { [key: string]: any } = JSON.parse(
                    JSON.stringify(localization?.texts)
                );

                texts[routeLocalizationConfig.key] = {};

                responses.forEach((response: any) => {
                    if (response) {
                        texts[routeLocalizationConfig.key] = deepMerge(
                            texts[routeLocalizationConfig.key],
                            response
                        );
                    }
                });

                // setState(
                //     patch({
                //         languages: iif<any>(
                //             (languageLocal) => {
                //                 return (
                //                     languageLocal &&
                //                     !!languageLocal.find(
                //                         (el) => el.lang == state.language
                //                     )
                //                 );
                //             },
                //             updateItem<Localization>(
                //                 (localization): boolean => {
                //                     return localization?.code == state.language;
                //                 },
                //                 patch({ texts: texts })
                //             ),
                //             append([localization])
                //         ),
                //     })
                // );

                return dispatch(new LocalizationActions.UpdateTranslation());
            })
        );
    }

    @Action(LocalizationActions.SetShortcodes)
    setVariables(
        {
            patchState,
            getState,
            dispatch,
        }: StateContext<LocalizationStateModel>,
        payload: LocalizationActions.SetShortcodes
    ) {
        const shortcodes = {
            ...getState().shortcodes,
            ...flatten(payload.shortcodes),
        };

        patchState({ shortcodes });
    }

    @Action(LocalizationActions.AddModulesTexts)
    addAppTexts(
        {
            patchState,
            getState,
            dispatch,
            setState,
        }: StateContext<LocalizationStateModel>,
        payload: LocalizationActions.AddModulesTexts
    ) {
        const state = getState();

        const currentLanguage = state.languages.find(
            (language) => language.code == state.language
        );

        if (!currentLanguage) {
            throw new Error(
                'Supported languages should be added before add AppTexts!!'
            );
        }

        const texts = JSON.parse(JSON.stringify(currentLanguage.texts));   

        texts.$app = deepMerge(texts.$app || {}, payload.modules);

        // setState(
        //     patch({
        //         languages: iif<any>(
        //             (languageLocal) => {
        //                 return (
        //                     languageLocal &&
        //                     !!languageLocal.find(
        //                         (el) => el.lang == state.language
        //                     )
        //                 );
        //             },
        //             updateItem<Localization>((localization): boolean => {
        //                 return localization?.code == state.language;
        //             }, patch({ texts: texts }))
        //         ),
        //     })
        // );

        return dispatch(new LocalizationActions.UpdateTranslation());
    }
}
