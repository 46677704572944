import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'serviceos-ng-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @ViewChild('errorMessageElement') errorMessage;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    
  }
}
