export function avatarGenerator(
    text: string,
    textColor: string = 'black',
    backgroundColor: string = 'transparent'
) {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');

    canvas.width = 200;
    canvas.height = 200;

    // Draw background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font =
        "100px Inter, Trebuchet, 'Century Gothic', 'Segoe UI', sans-serif";
    context.fillStyle = textColor;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(text, canvas.width / 2, canvas.height / 2 + 10);

    return canvas.toDataURL('image/png');
}
