import { SocialProvider } from './../authentication-types';
import { ApiRequest, Environment } from '../../../common';
import { SosCryptoService } from '../../../helpers';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

export abstract class BaseSocialAuth {
    abstract authPaths: {
        login: string;
    };

    constructor(
        public cryptoService: SosCryptoService,
        public env: Environment,
        public translate: TranslateService
    ) {}

    createState(): string {
        const redirectUrl = window.location.origin + this.authPaths.login;

        return this.cryptoService.encryptData(
            {
                redirect: redirectUrl,
            },
            this.env.cookieKey
        );
    }

    abstract beginAuth(
        socialProvider: SocialProvider
    ): Promise<ApiRequest<any>>;

    unexpectedErrorWhenAuthorize(socialProvide: SocialProvider) {
        return this.translate
            .get('$app.auth.texts.error_social_authorization_fail', { 'social_provider.name': socialProvide.type })
            .pipe(
                map((errorText) => {

                    const response = {
                        error: [
                            {
                                code: 100603,
                                message: `Unable to authorize with ${socialProvide.type}. Please try again later or use a different method.`,
                            },
                        ],
                    }

                    if(errorText) {
                        response.error[0].message = errorText;
                    }


                    return response;
                })
            )
            .toPromise();
    }
}
