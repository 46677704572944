import { SosApiService } from './../../common/services/api/api.service';
import { Injectable } from '@angular/core';
import { of, concat, Observable, combineLatest } from 'rxjs';
import { map, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeepLinksService {
  constructor(private api: SosApiService) {}

  public getResources(deepLink): Observable<any | null> {
    switch (deepLink.type) {
      // SECTION Call customer support
      case 'call_cs':
        return this.api.get(`client${deepLink.endpoint}_reasons`).pipe(
          map((response) => {
            return { reasons: response.data };
          })
        );
        break;

      // SECTION Call unit
      case 'call_unit':
        return this.api.get(`client${deepLink.endpoint}_reasons`).pipe(
          map((response) => {
            return { reasons: response.data };
          })
        );
        break;

      // SECTION Rate booking/session
      case 'rate':
      case 'complain':
        return this.api.get(`client${deepLink.endpoint}_tags`).pipe(
          map((response) => {
            return { tags: response.data };
          })
        );
        break;

      // SECTION Cancel booking or session
      case 'cancel':
      case 'skip_session':
        const getCancelReasons = this.api
          .get(
            `client/bookings/${deepLink.deep_link_object.fs_element_id}/cancel_reasons`
          )
          .pipe(
            map((response) => {
              return { reasons: response.data };
            })
          );

        const getCancelTerms = this.api
          .get(
            `client/bookings/${deepLink.deep_link_object.fs_element_id}/cancellation_terms`
          )
          .pipe(
            map((response) => {
              return { terms: response.data };
            })
          );

        return combineLatest([getCancelReasons, getCancelTerms]).pipe(
          map(([reasons, therms]) => {
            return { ...reasons, ...therms};
          })
        );
        break;

      // SECTION Edit booking or session
      case 'edit':
        return this.api.get(`client/edit_booking_reasons`).pipe(
          map((response) => {
            return { reasons: response.data };
          })
        );
        break;

      // SECTION Edit regular plan
      case 'edit_regular_plan':
        return this.api.get(`client/edit_regular_plan_reasons`).pipe(
          map((response) => {
            return { reasons: response.data };
          })
        );
        break;

      default:
        return of(null);
        break;
    }
  }

  public sendDeepLinkData(deepLink, data) {
    return this.api.post('client' + deepLink.endpoint, data);
  }
}
