import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'serviceos-ng-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit, AfterViewInit {
    @ViewChild('labelElement') label;

    constructor() {}

    ngOnInit(): void {}

	ngAfterViewInit(): void {
        // select input from form-field component
        const inputIdAttr: string = this.label?.nativeElement?.parentElement?.parentElement?.querySelector('input')?.getAttribute('id');

        this.label?.nativeElement?.setAttribute('for', inputIdAttr);

        // attach class that indicates for label's existance
        this.label?.nativeElement?.parentElement?.parentElement?.classList.add('sos-hasLabel');
	}
}
