import { ExpiringDataStateModel } from './expiring-data.state';
import { createSelector, Selector } from '@ngxs/store';
import { ExpiringDataState } from '..';

export class ExpiringDataSelectors {

    static property(name: string) {
        return createSelector(
            [ExpiringDataState],
            (state: ExpiringDataStateModel) => {
                return (
                    state.application[name]?.value || state.global[name]?.value
                );
            }
        );
    }
}
