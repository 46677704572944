<div class="collapse-view-container">
    <div class="collapse-header">
        <h6>
            {{title}}
        </h6>
        <span class="toggle-btn" (click)="collapse.toggle()"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            {{isCollapsed ? expandToggleTitle : collapseToggleTitle }}
        </span>
    </div>
    <div class="collapse-content" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ng-content></ng-content>
    </div>
</div>