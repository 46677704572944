<div class="basic-holder">
    <div class="wa-shell-loader">
        <div class="centered">
            <div id="loader-circles-holder">
                <div id="wa-inner-loader" class="obf-loading"></div>
                <div class="obf-loader-section section-left"></div>
                <div class="obf-loader-section section-right"></div>
            </div>
            <div class="obf-loading-text-container">
                <!-- <span>Just a sec, we're getting ready...</span> -->
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>