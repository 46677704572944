import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { ApiRequest, Environment } from '../../../common';
import { genGUID, popupCenter, SosCryptoService } from '../../../helpers';
import { SocialProvider } from '../authentication-types';
import { BaseSocialAuth } from './base-social-auth';

@Injectable({
    providedIn: 'root',
})
export class AppleAuthService extends BaseSocialAuth {
    authPaths: { login: string } = {
        login: '/ext/apple-social-info',
    };

    public popupInstance: Window | null = null;

    constructor(
        cryptoService: SosCryptoService,
        env: Environment,
        translate: TranslateService
    ) {
        super(cryptoService, env, translate);
    }

    /**
     * This method is overwrite because Authorization check have _web string!
     * The _web string work like flag for Authorization to do not close the modal.
     * @returns String
     */
    createState() {
        // !! WA USE STATE FOR FLAG TO DON'T CLOSE THE WINDOW.
        return this.cryptoService.encryptData(
            genGUID() + '_web',
            this.env.cookieKey
        );
    }

    /**
     * Run Apple authorization cycle
     * @param requestType trying to login or register
     */
    public beginAuth(socialProvider: SocialProvider) {
        return new Promise<
            ApiRequest<{
                oauth_id: any;
                id_token: any;
                social_provider_id: any;
            }>
        >((resolve: Function, reject: Function) => {
            const appleAuthKey = socialProvider.data.client_id;

            // This need to be change when we have one authorization location for all clients
            const authorizationDomain =
                window.location.origin + this.authPaths.login;

            // !!
            // !! DO NOT CHANGE THIS BEFORE COMMENT WITH AUTHORIZATION SERVER.
            // !!
            const state = this.createState();

            // eslint-disable-next-line max-len
            const appleAuthURL = `https://appleid.apple.com/auth/authorize?client_id=${appleAuthKey}&redirect_uri=${authorizationDomain}&response_type=code%20id_token&state=${encodeURIComponent(
                state
            )}&scope=name%20email&response_mode=form_post`;

            const apiRequest = new ApiRequest({
                type: 'POST',
                url: appleAuthURL,
                data: null,
                options: null,
            });

            let authenticationResponse: any = null;
            const appleAuthPostMessageHandler: any = (event) => {
                if (event && event.data && event.data.c_apple_login_data) {
                    authenticationResponse = event.data.c_apple_login_data;
                }
            };
            // append listener for event
            window.addEventListener('message', appleAuthPostMessageHandler);

            const centerPopup = popupCenter();
            const appleAuthPopup: Window | null = window.open(
                appleAuthURL,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' +
                    centerPopup.left +
                    ',top=' +
                    centerPopup.top +
                    ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no'
            );

            this.popupInstance = appleAuthPopup;

            const subscription = interval(1000).subscribe(async() => {
                if (authenticationResponse == null) {
                    try {
                        authenticationResponse = localStorage.getItem(
                            'c_apple_login_data'
                        );
                        localStorage.removeItem('c_apple_login_data');
                    } catch (e) {}
                }

                if (authenticationResponse) {
                    // * Post message
                    window.removeEventListener(
                        'message',
                        appleAuthPostMessageHandler
                    );
                    subscription.unsubscribe();

                    const socialData: any = this.cryptoService.decryptData(
                        authenticationResponse,
                        this.env.cookieKey
                    );

                    if (socialData && !socialData.error) {
                        apiRequest.setResponse({
                            data: {
                                oauth_id: socialData.code,
                                id_token: socialData.id_token,
                                social_provider_id: socialProvider.id,
                            },
                        });

                        resolve(apiRequest);
                    } else {
                        apiRequest.setResponse(socialData);
                        reject(apiRequest);
                    }
                    return;
                }

                // Wait to close popup.
                if (appleAuthPopup && appleAuthPopup.closed) {
                    window.removeEventListener(
                        'message',
                        appleAuthPostMessageHandler
                    );

                    subscription.unsubscribe();

                    this.popupInstance = null;
                    apiRequest.setResponse(
                       await this.unexpectedErrorWhenAuthorize(socialProvider)
                    );
                    reject(apiRequest);
                    return;
                }
            });
        });
    }
}
