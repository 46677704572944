import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Directive,
    forwardRef,
    Inject,
    InjectionToken,
    Input,
    OnInit,
    Optional,
    QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Radio buttons are made will the looking up to source core for radio button in angular material
// https://github.dev/angular/components/tree/master/src/material/radio

// Increasing integer for generating unique ids for radio components.
let nextUniqueId = 0;

/**
 * Injection token that can be used to inject instances of `RadioGroup`. It serves as
 * alternative token to the actual `RadioGroup` class which could cause unnecessary
 * retention of the class and its component metadata.
 */
export const RADIO_GROUP = new InjectionToken<RadioGroup>('RadioGroup');

@Directive({
    selector: 'serviceos-ng-radio-group',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroup),
            multi: true,
        },
        { provide: RADIO_GROUP, useExisting: RadioGroup },
    ],
})
export class RadioGroup implements ControlValueAccessor {
    @ContentChildren(forwardRef(() => RadioComponent), { descendants: true })
    _radios!: QueryList<RadioComponent>;

    /**
     * The currently selected radio button. If set to a new radio button, the radio group value
     * will be updated to match the new selected button.
     */
    private _selected: RadioComponent | any;
    @Input()
    get selected() {
        return this._selected;
    }
    set selected(selected: RadioComponent | null) {
        this._selected = selected;
        this.value = selected ? selected.value : null;
        this._checkSelectedRadioButton();
    }

    /**
     * Use to flag error for the radio group.
     */
    _error: boolean = false;
    @Input()
    get error() {
        return this._error;
    }
    set error(value: BooleanInput) {
        this._error = coerceBooleanProperty(value);
        this._updateRadioButtonsError();
    }

    /**
     * Contains the value from selected radio button in the group.
     */
    private _value: any = null;
    @Input()
    get value(): any {
        return this._value;
    }
    set value(newValue: any) {
        if (this._value !== newValue) {
            // Set this before proceeding to ensure no circular loop occurs with selection.
            this._value = newValue;

            this._updateSelectedRadioFromValue();
            this._checkSelectedRadioButton();
            this._onChangeFn(this._value);
        }
    }

    private _disabled: boolean = false;
    /** Whether the radio group is disabled */
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._markRadiosForCheck();
    }

    private _name: string = `sos-radio-group-${nextUniqueId++}`;

    @Input()
    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
        this._updateRadioButtonsNames();
    }

    _onChangeFn: any = () => {};
    _onTouchFn: any = () => {};

    constructor(private _changeDetector: ChangeDetectorRef) {}

    writeValue(value: any): void {
        this._value = value;
        this._changeDetector.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchFn = fn;
    }

    /**
     * Update the names of the radio buttons
     */
    _updateRadioButtonsNames() {
        if (this._radios) {
            this._radios.forEach((radio) => {
                radio.name = this.name;
                radio._markForCheck();
            });
        }
    }

    /**
     * Update all radio buttons inside the group with error status.
     */
    _updateRadioButtonsError() {
        if (this._radios) {
            this._radios.forEach((radio) => {
                radio.error = this._error;
                radio._markForCheck();
            });
        }
    }

    /** Updates the `selected` radio button from the internal _value state. */
    private _updateSelectedRadioFromValue(): void {
        // If the value already matches the selected radio, do nothing.
        const isAlreadySelected =
            this._selected !== null && this._selected.value === this._value;

        if (this._radios && !isAlreadySelected) {
            this._selected = null;
            this._radios.forEach((radio) => {
                radio.checked = this.value === radio.value;
                if (radio.checked) {
                    this._selected = radio;
                }
            });
        }
    }

    _checkSelectedRadioButton() {
        if (this._selected && !this._selected.checked) {
            this._selected.checked = true;
        }
    }

    _markRadiosForCheck() {
        if (this._radios) {
            this._radios.forEach((radio) => radio._markForCheck());
        }
    }
}

@Component({
    selector: 'serviceos-ng-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements OnInit {
    private _uniqueId: string = `sos-radio-${++nextUniqueId}`;

    /** The unique ID for the radio button. */
    @Input() id: string = this._uniqueId;

    get inputId(): string {
        return `${this.id || this._uniqueId}-input`;
    }

    /** Analog to HTML 'name' attribute used to group radios for unique selection. */
    @Input() name!: string;

    @Input() error: boolean = false;

    @Input() value: any;

    _checked: boolean = false;
    /** Whether this radio button is checked. */
    @Input()
    get checked(): boolean {
        return this._checked;
    }
    set checked(value: BooleanInput) {
        const newCheckedState = coerceBooleanProperty(value);
        if (this._checked !== newCheckedState) {
            this._checked = newCheckedState;
            
            // Update radio group for radio button change.
            if (
                newCheckedState &&
                this.radioGroup &&
                this.radioGroup.value !== this.value
            ) {
                this.radioGroup.selected = this;
            } else if (
                !newCheckedState &&
                this.radioGroup &&
                this.radioGroup.value === this.value
            ) {
                // When unchecking the selected radio button, update the selected radio
                // property on the group.
                this.radioGroup.selected = null;
            }

            if (newCheckedState) {
                // Notify all radio buttons with the same name to un-check.
                this._radioDispatcher.notify(this.id, this.name);
            }
            this._changeDetector.markForCheck();
        }
    }

    private _disabled: any;

    radioGroup: RadioGroup | null;

    _removeUniqueSelectionListener: any = () => {};

    /** Whether the radio button is disabled. */
    @Input()
    get disabled(): boolean {
        return (
            this._disabled ||
            (this.radioGroup !== null && this.radioGroup.disabled)
        );
    }
    set disabled(value: BooleanInput) {
        value = coerceBooleanProperty(value);
        if (this._disabled !== value) {
            this._disabled = value;
            this._changeDetector.markForCheck();
        }
    }

    constructor(
        @Optional() @Inject(RADIO_GROUP) radioGroup: RadioGroup,
        private _changeDetector: ChangeDetectorRef,
        private _radioDispatcher: UniqueSelectionDispatcher
    ) {
        this.radioGroup = radioGroup;

        /**
         * Listen for notify from other radio buttons.
         */
        this._removeUniqueSelectionListener = _radioDispatcher.listen(
            (id: string, name: string) => {
                if (id !== this.id && name === this.name) {
                    this.checked = false;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this._removeUniqueSelectionListener();
    }

    _markForCheck() {
        this._changeDetector.markForCheck();
    }

    _onInputClick(event: Event) {
        // We have to stop propagation for click events on the visual hidden input element.
        // By default, when a user clicks on a label element, a generated click event will be
        // dispatched on the associated input element. Since we are using a label element as our
        // root container, the click event on the `radio-button` will be executed twice.
        // The real click event will bubble up, and the generated click event also tries to bubble up.
        // This will lead to multiple click events.
        // Preventing bubbling for the second event will solve that issue.
        event.stopPropagation();
    }

    /** Triggered when the radio button receives an interaction from the user. */
    _onInputInteraction(event: Event) {
        event.stopPropagation();
        if (!this.checked && !this.disabled) {
            this.checked = true;

            if (this.radioGroup) {
                this.radioGroup.value = this.value;
            }
        }
    }

    ngOnInit(): void {
        if (this.radioGroup) {
            // If the radio is inside a radio group, determine if it should be checked
            this.checked = this.radioGroup.value === this.value;

            if (this.checked) {
                this.radioGroup.selected = this;
            }

            // Copy name from parent radio group
            this.name = this.radioGroup.name;
        }
    }
}
