import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemIconModule } from '../shared/base/system-icon/system-icon.module';
import { LogoComponent } from './logo.component';

@NgModule({
    declarations: [LogoComponent],
    imports: [CommonModule],
    exports: [LogoComponent],
})
export class LogoModule {}
