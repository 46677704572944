import { interval, Observable } from 'rxjs';
import { popupCenter } from '../../helpers';

export class SyncAuthorization {
    private emailAuth = {
        authSync: '/ext/auth-sync',
    };
    private syncPopup: Window | null = null;

    private sameOrigin;

    constructor(popup?) {
        this.sameOrigin = this.checkSameOrigin();

        if (!this.sameOrigin) {
            const emailAuthUrl =
                window.location.origin + this.emailAuth.authSync;

            if (popup) {
                this.syncPopup = popup;
                return;
            }

            const centerPopup = popupCenter();
            this.syncPopup = window.open(
                emailAuthUrl,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' +
                    centerPopup.left +
                    ',top=' +
                    centerPopup.top +
                    ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no'
            );
        }
    }

    private checkSameOrigin() {
        try {
            var doc = parent.document;
            if (!doc) throw new Error('Unaccessible');
            return true;
        } catch (e) {
            return false;
        }
    }

    public cancelSync() {
        if (this.syncPopup && !this.syncPopup.closed) {
            this.syncPopup.close();
        }
    }

    /**
     * Sync the authorization with accounts when we are in different origin
     * @param csid crypted auth sid.
     * @returns
     */
    public sync(cAuthorizationData: string) {
        return new Observable(({ next, error, complete }) => {
            if (this.sameOrigin) {
                next();
                complete();
                return;
            }

            let errorMessage = 'Sync fails!';
            if (this.syncPopup && !this.syncPopup.closed) {
                let syncAccount = false;
                const syncPostMessageHandler = (event) => {
                    if (event.data?.csid) {
                        syncAccount = true;
                        this.cancelSync();
                    }
                };

                window.addEventListener('message', syncPostMessageHandler);

                this.syncPopup.location.href =
                    window.location.origin +
                    this.emailAuth.authSync +
                    '?c_login_data=' +
                    encodeURIComponent(cAuthorizationData);

                const syncTimeout = setTimeout(() => {
                    errorMessage = 'Sync timeout!';
                    this.syncPopup?.close();
                }, 10000);

                const subscription = interval(1000).subscribe(() => {
                    if (this.syncPopup && !this.syncPopup.closed) {
                        return;
                    }

                    window.clearTimeout(syncTimeout);

                    // Only we care if we sync user account successfully with WA
                    if (!syncAccount) {
                        next();
                        complete();
                    } else {
                        error(errorMessage);
                    }

                    window.removeEventListener(
                        'message',
                        syncPostMessageHandler
                    );
                    subscription.unsubscribe();
                });
            } else {
                error(errorMessage);
            }
        });
    }
}
