export namespace FeaturesActions {
    export class Register {
        static readonly type = '[FEATURES] Register'
        constructor(public token: string) {

        }
    }

    export class InitializeFeatures {
        static readonly type = '[FEATURES] Initialize Features';
        constructor() {}
    }

    export class SuccessfulFetchConfig{
        static readonly type = '[FEATURES] Successful Fetch Config'
        constructor() {}
    }
}
